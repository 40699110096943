import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { RegisterFormComponent } from '@app/shared/register';
import { ForgotFormComponent } from '@app/shared/forgot-password';
import { FourAllPaymentComponent } from '@app/shared/four-all-payment/four-all-payment.component';
import { BackwardGuard, BackStepperGuard, AuthGuard } from '@app/guards';
import { ListPropertyAddComponent } from './pages/list-property/add/list-property-add.component';
import { ListCartComponent } from './pages/list-property/cart/list-cart.component';
import { ListPropertyComponent } from '@app/search-navigation/pages/list-property/list-property.component';
import { ListUserComponent } from '@app/search-navigation/pages/listing-user';
import { ChangePasswordComponent } from '@app/shared/change-password';
import { PrivacyPolicyComponent } from '@app/shared/privacy-policy/privacy-policy.component';
import { TermsComponent } from '@app/shared/terms/terms.component';
import { DownloadComponent } from '@app/shared/download';
import { MoreNotificationsComponent } from '@app/shared/notifications/more-notifications/more-notifications.component';
import { SettingsNotificationComponent } from '@app/shared/settings-notification/settings-notification.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
var ɵ0 = {
    title: extract('Register New User')
}, ɵ1 = {
    title: extract('Register New User')
}, ɵ2 = {
    title: extract('Forgot Password')
}, ɵ3 = {
    title: extract('Forgot Password')
}, ɵ4 = {
    title: extract('Change Password')
}, ɵ5 = {
    title: extract('Change Password')
}, ɵ6 = { title: extract('List Property'), skipParamCheck: true }, ɵ7 = { title: extract('List Property'), skipParamCheck: true }, ɵ8 = { title: extract('List Property Add'), skipParamCheck: true }, ɵ9 = { title: extract('List Property Add'), skipParamCheck: true }, ɵ10 = { title: extract('Listing User'), skipParamCheck: true }, ɵ11 = { title: extract('Listing User'), skipParamCheck: true }, ɵ12 = { title: extract('List Property'), skipParamCheck: true }, ɵ13 = { title: extract('List Property'), skipParamCheck: true }, ɵ14 = { title: extract('Payment'), skipParamCheck: true }, ɵ15 = { title: extract('Payment'), skipParamCheck: true }, ɵ16 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ17 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ18 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ19 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ20 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ21 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ22 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ23 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ24 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ25 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ26 = { title: extract('SiiLA SPOT Search Listings'), shouldReuse: false }, ɵ27 = { title: extract('Privacy Policy') }, ɵ28 = { title: extract('Privacy Policy') }, ɵ29 = { title: extract('Terms Of Use') }, ɵ30 = { title: extract('Terms Of Use') }, ɵ31 = {
    title: extract('Register New User')
}, ɵ32 = {
    title: extract('Register New User')
}, ɵ33 = {
    title: extract('User Notifications')
}, ɵ34 = {
    title: extract('User Notifications')
}, ɵ35 = {
    title: extract('settings')
}, ɵ36 = {
    title: extract('settings')
};
var routes = [
    Shell.childRoutes([
        { path: '', redirectTo: '/', pathMatch: 'full' },
        {
            path: 'register',
            component: RegisterFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ0
        },
        {
            path: 'register/:language',
            component: RegisterFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ1
        },
        {
            path: 'forgot-password',
            component: ForgotFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ2
        },
        {
            path: 'forgot-password/:language',
            component: ForgotFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ3
        },
        {
            path: 'change-password',
            component: ChangePasswordComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ4
        },
        {
            path: 'change-password/:language',
            component: ChangePasswordComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ5
        },
        {
            path: 'list-property',
            component: ListPropertyComponent,
            canActivate: [AuthGuard],
            data: ɵ6
        },
        {
            path: 'list-property/:language',
            component: ListPropertyComponent,
            canActivate: [AuthGuard],
            data: ɵ7
        },
        {
            path: 'list-property/add',
            component: ListPropertyAddComponent,
            canActivate: [AuthGuard],
            data: ɵ8
        },
        {
            path: 'list-property/add/:language',
            component: ListPropertyAddComponent,
            canActivate: [AuthGuard],
            data: ɵ9
        },
        {
            path: 'listing-user/:id',
            component: ListUserComponent,
            data: ɵ10
        },
        {
            path: 'listing-user/:id/:language',
            component: ListUserComponent,
            data: ɵ11
        },
        {
            path: 'cart',
            component: ListCartComponent,
            canActivate: [AuthGuard],
            data: ɵ12
        },
        {
            path: 'cart/:language',
            component: ListCartComponent,
            canActivate: [AuthGuard],
            data: ɵ13
        },
        {
            path: 'payment',
            component: FourAllPaymentComponent,
            canActivate: [AuthGuard],
            data: ɵ14
        },
        {
            path: 'payment/:language',
            component: FourAllPaymentComponent,
            canActivate: [AuthGuard],
            data: ɵ15
        },
        /* LISTING DETAILS COMPONENT */
        /* detail/:baseId */
        {
            path: 'detail/:baseId',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ16
        },
        {
            path: 'detail/:baseId/:language',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ17
        },
        /* detail/:baseId/:listingId */
        {
            path: 'detail/:baseId/:listingId',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ18
        },
        {
            path: 'detail/:baseId/:listingId/:language',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ19
        },
        /* detail/:listingType/:propertyType/:buildingName/:baseId */
        {
            path: 'detail/:listingType/:propertyType/:buildingName/:baseId',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ20
        },
        {
            path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:lang',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ21
        },
        /* detail/:listingType/:propertyType/:buildingName/:baseId/:listingId */
        {
            path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:listingId',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ22
        },
        {
            path: 'detail/:listingType/:propertyType/:buildingName/:baseId/:listingId/:lang',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ23
        },
        /*                              'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId
            The above route is taking by detail/:listingType/:propertyType/:buildingName/:baseId/:listingId
         */
        {
            path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:lang',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ24
        },
        /* detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId */
        {
            path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ25
        },
        {
            path: 'detail/:listingType/:propertyType/:neighborhood/:buildingName/:baseId/:listingId/:lang',
            component: ListingDetailComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ26
        },
        /* SEARCH COMPONENT */
        /* {
          path: 'search',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listing Search'), skipParamCheck: false },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:language',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listing Search'), skipParamCheck: false },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:from',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:from/:language',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType/:language',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType/:state',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType/:state/:language',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType/:state/:city',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'search/:listingType/:propertyType/:state/:city/:language',
          component: ListingSearchComponent,
          canDeactivate: [BackwardGuard],
          data: { title: extract('Listings Search') },
          runGuardsAndResolvers: 'always'
        }, */
        {
            path: AppRouteNames.PRIVACY_POLICY_ROUTE + "/:language",
            component: PrivacyPolicyComponent,
            data: ɵ27
        },
        {
            path: AppRouteNames.PRIVACY_POLICY_ROUTE_CL + "/:language",
            component: PrivacyPolicyComponent,
            data: ɵ28
        },
        {
            path: AppRouteNames.TERMS_ROUTE + "/:language",
            component: TermsComponent,
            data: ɵ29
        },
        {
            path: AppRouteNames.TERMS_ROUTE_CL + "/:language",
            component: TermsComponent,
            data: ɵ30
        },
        {
            path: 'download',
            component: DownloadComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ31
        },
        {
            path: 'download/:language',
            component: DownloadComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ32
        },
        {
            path: 'notifications',
            component: MoreNotificationsComponent,
            canActivate: [AuthGuard],
            data: ɵ33
        },
        {
            path: 'notifications/:language',
            component: MoreNotificationsComponent,
            canActivate: [AuthGuard],
            data: ɵ34
        },
        {
            path: 'settings',
            component: SettingsNotificationComponent,
            canActivate: [AuthGuard],
            data: ɵ35
        },
        {
            path: 'settings/:language',
            component: SettingsNotificationComponent,
            canActivate: [AuthGuard],
            data: ɵ36
        }
    ])
];
//Pass child routes to Shell for reuse
var SearchNavigationRoutingModule = /** @class */ (function () {
    function SearchNavigationRoutingModule() {
    }
    return SearchNavigationRoutingModule;
}());
export { SearchNavigationRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36 };
