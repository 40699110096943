import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ListingModalService } from '../../services/listing-modal.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { ImageService } from '@app/core/image.service';
import { Router } from '@angular/router';
import { ListingService, ListPropertyService } from '@app/core/listings';
import { ListingCarouselService } from '@app/core/listings';
import { UserActivityType } from '@app/models/user-activity.model';
import { PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { AgentNotificationComponent } from '../agent-notification/agent-notification.component';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { MatDialog } from '@angular/material';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { LoginFormDialogService } from '@app/shared/login/login-form-dialog.service';
import { take } from 'rxjs/operators';
import { BuildingRentRollsService } from '@app/search-navigation/services/building-rent-rolls.service';
import { EventCheckerService } from '@app/core/listings';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { DeviceDetectorService } from 'ngx-device-detector';
var ListingModalComponent = /** @class */ (function () {
    function ListingModalComponent(_eventChecker, fb, router, listingModalService, listingDetailService, imgService, listingService, listingPropertyService, listingCarouselService, userActivityService, i18nService, whatsAppService, agentNotPopOverCtrl, auth, loginFormDialog, dialog, buildingRentRollService, nearbyListingSvc, analyticsService, listingSearchService, deviceDetector) {
        this._eventChecker = _eventChecker;
        this.fb = fb;
        this.router = router;
        this.listingModalService = listingModalService;
        this.listingDetailService = listingDetailService;
        this.imgService = imgService;
        this.listingService = listingService;
        this.listingPropertyService = listingPropertyService;
        this.listingCarouselService = listingCarouselService;
        this.userActivityService = userActivityService;
        this.i18nService = i18nService;
        this.whatsAppService = whatsAppService;
        this.agentNotPopOverCtrl = agentNotPopOverCtrl;
        this.auth = auth;
        this.loginFormDialog = loginFormDialog;
        this.dialog = dialog;
        this.buildingRentRollService = buildingRentRollService;
        this.nearbyListingSvc = nearbyListingSvc;
        this.analyticsService = analyticsService;
        this.listingSearchService = listingSearchService;
        this.deviceDetector = deviceDetector;
        this.scrollToView = new EventEmitter();
        this.removedTagsFromNearBy = new EventEmitter();
        this.submitted = false;
        this.contactEmail = false;
        this.contactPhone = false;
        this.sendMessage = false;
        this.brokerEmail = [];
        this.brokerPhone = [];
        this.advertisements = [];
        this.options = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.brokerImage = '';
        this.listingTags = [];
        this.typesTags = [];
        this.whatsappPhone = false;
        this.availableSpaces = [];
        this.availableSpaceCols = [];
        this.nearbyListings = [];
        this.selectedPropertyListings = [];
        this.pricePrivateOption = false;
        this.priceSharedOption = false;
        this.seeRevisionChanges = false;
        this.screenWidth = window.innerWidth;
    }
    ListingModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) {
            _this.isLoggedIn = val;
            _this.getNearestListings(history.state);
            _this.getListingsInSameProperty();
        });
        this.setListing();
        this.createForm();
        // Avoid calling details API twice
        if (this.previewListing && this.listing) {
            this.getListingDetails();
        }
        this.getListingTags(this.listing);
        this.getTypesTags(this.listing);
        this.getAdvertisements();
        this.coworkingType = this.listingSearchService.findCoworkingTypeFromSearch()
            ? this.listingSearchService.findCoworkingTypeFromSearch()
            : 'shared';
        this.coworkingPositions = this.listing.coworkingPositions;
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.isLoggedIn$ = this.auth.$isLoginSubject;
        this.screenHeight = window.outerHeight;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.listingUpdateSubscription = this.subscribeToListingUpdate();
        if (this.isBasic()) {
            if (this.listing.building) {
                this.getRentRolls();
            }
        }
        this.priceSharedOption = this.listing.coworkingDailyPrice > 0 || this.listing.coworkingMonthlyPrice > 0;
        this.pricePrivateOption =
            this.listing.coworkingPrivateDailyPrice > 0 || this.listing.coworkingPrivateMonthlyPrice > 0;
        if (!this.priceSharedOption || !this.pricePrivateOption) {
            this.coworkingType = this.priceSharedOption ? 'shared' : 'private';
            this.calculateCoworkingValues(this.coworkingType);
        }
        this.revisionChanges();
    };
    ListingModalComponent.prototype.revisionChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingDetailService.revisionChanges(this.listing.baseId, this.listing, this.i18nService.getCurrentLanguage())];
                    case 1:
                        _a.revisionListings = _b.sent();
                        if (this.revisionListings.length > 0) {
                            this.seeRevisionChanges = true;
                            this.revisionListings.forEach(function (element) {
                                if (element.fieldName == 'COWORKING_CHANGES') {
                                    element.currentValue = '';
                                    element.newValue = _this.i18nService.get('global.revisionChanges.COWORKING_CHANGES_NEW');
                                }
                                if (element.fieldName == 'PHOTOS_CHANGE') {
                                    element.currentValue = '';
                                    element.newValue = _this.i18nService.get('global.revisionChanges.PHOTOS_CHANGE_NEW');
                                }
                                if (element.currentValue == 'true') {
                                    element.currentValue = _this.i18nService.get('global.revisionChanges.yes');
                                }
                                if (element.newValue == 'true') {
                                    element.newValue = _this.i18nService.get('global.revisionChanges.yes');
                                }
                                if (element.currentValue == 'false') {
                                    element.currentValue = _this.i18nService.get('global.revisionChanges.no');
                                }
                                if (element.newValue == 'false') {
                                    element.newValue = _this.i18nService.get('global.revisionChanges.no');
                                }
                                element.fieldName = "global.revisionChanges." + element.fieldName;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingModalComponent.prototype.ngOnDestroy = function () {
        if (this.listingUpdateSubscription) {
            this.listingUpdateSubscription.unsubscribe();
        }
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    };
    ListingModalComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
        this.screenHeight = event.target.innerHeight;
    };
    ListingModalComponent.prototype.openEditListingMenu = function (event, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        return [4 /*yield*/, this.listingDetailService.getRevisionOrActiveVersionBasedOnUserRole(data)];
                    case 1:
                        data = _a.sent();
                        this.dialogRef = this.dialog.open(MyListingEditComponent, {
                            height: 'auto',
                            width: '550px',
                            data: data ? { data: data, myListingPage: false } : null
                        });
                        this.dialogRef.afterClosed().subscribe(function (result) { });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingModalComponent.prototype.subscribeToListingUpdate = function () {
        var _this = this;
        return this._eventChecker.updateListing$.subscribe(function (listing) {
            if (listing && _this.listingsInSameProperty) {
                _this.listingsInSameProperty = _this.listingsInSameProperty.map(function (listingInSameProp) {
                    if (listing.id === listingInSameProp.id) {
                        return listing;
                    }
                    return listingInSameProp;
                });
            }
        });
    };
    ListingModalComponent.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.error('roles undefined --> ', e);
        }
    };
    ListingModalComponent.prototype.getRentRolls = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.buildingRentRollService.getAll(this.listing.building.id)];
                    case 1:
                        _a.availableSpaces = _b.sent();
                        if (this.availableSpaces) {
                            this.availableSpaces = this.availableSpaces
                                .filter(function (brr) {
                                return brr.tenantName === 'Vago' || (brr.tenantNameOption && brr.tenantNameOption.id === 1);
                            })
                                .map(function (brr) {
                                brr.suiteOrModule = _this.suiteOrModule(brr);
                                brr.floorOrWarehouse = _this.floorOrWarehouse(brr);
                                return brr;
                            })
                                .sort(function (brr1, brr2) {
                                if (_this.listing.building.buildingType.id === 1001) {
                                    if (Number(brr1.warehouse) > Number(brr2.warehouse)) {
                                        return -1;
                                    }
                                    if (Number(brr1.warehouse) < Number(brr2.warehouse)) {
                                        return 1;
                                    }
                                    return 0;
                                }
                                else if (_this.listing.building.buildingType.id === 2001) {
                                    if (Number(brr1.floor) > Number(brr2.floor)) {
                                        return -1;
                                    }
                                    if (Number(brr1.floor) < Number(brr2.floor)) {
                                        return 1;
                                    }
                                    return 0;
                                }
                            });
                            if (this.availableSpaces.length > 0) {
                                if (this.listing.building.buildingType.id === 1001) {
                                    this.availableSpaceCols[0] = 'global.listing-detail.basic.warehouse';
                                    this.availableSpaceCols[1] = 'global.listing-detail.basic.module';
                                    this.availableSpaceCols[2] = 'global.listing-detail.basic.privateArea';
                                }
                                else if (this.listing.building.buildingType.id === 2001) {
                                    this.availableSpaceCols[0] = 'global.listing-detail.basic.floor';
                                    this.availableSpaceCols[1] = 'global.listing-detail.basic.suite';
                                    this.availableSpaceCols[2] = 'global.listing-detail.basic.privateArea';
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingModalComponent.prototype.getListingDetails = function () {
        var _this = this;
        this.listingDetailService
            .getListingDetails(this.listing.id)
            .toPromise()
            .then(function (result) {
            _this.listing = result.listing;
            _this.listing.premiumAgents = result.premiumAgents;
        });
    };
    ListingModalComponent.prototype.getAdvertisements = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var amount, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        amount = 6;
                        if (!(this.listing.listingType.toLowerCase() === 'basic' || this.listing.listingType.toLowerCase() === 'promoted')) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.listingCarouselService.getAdvertisements(amount, this.listing.id).toPromise()];
                    case 1:
                        _a.advertisements = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ListingModalComponent.prototype.getTypesTags = function (listing) {
        var _this = this;
        this.typesTags.push({ name: this.i18nService.getTranslation(this.listing.building.buildingType.name) });
        this.listingDetailService
            .getListingPropertySubtype(this.listing.id)
            .toPromise()
            .then(function (result) {
            if (result) {
                result.forEach(function (element) {
                    _this.typesTags.push({ name: _this.i18nService.getTranslation(element.name) });
                });
            }
        });
    };
    ListingModalComponent.prototype.getListingTags = function (listing) {
        this.listingTags = this.listingPropertyService.getTagTranslations(listing);
    };
    Object.defineProperty(ListingModalComponent.prototype, "form", {
        get: function () {
            return this.angForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ListingModalComponent.prototype.getListingBrokerImage = function (login) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var images;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingDetailService.getProfilePicture(login).toPromise()];
                    case 1:
                        images = _a.sent();
                        return [2 /*return*/, images];
                }
            });
        });
    };
    ListingModalComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    ListingModalComponent.prototype.createForm = function () {
        this.angForm = this.fb.group({
            name: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', Validators.required],
            note: ['', Validators.required]
        });
    };
    ListingModalComponent.prototype.navigateToBroker = function (id) {
        this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL);
        this.router.navigateByUrl("/listing-user/" + id, { state: { listing: this.listing } });
    };
    ListingModalComponent.prototype.showContactEmail = function () {
        this.contactEmail = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    };
    ListingModalComponent.prototype.showContactPhone = function () {
        this.contactPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
    };
    ListingModalComponent.prototype.showBrokerEmail = function (index) {
        this.brokerEmail[index] = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    };
    ListingModalComponent.prototype.showBrokerPhone = function (index) {
        this.brokerPhone[index] = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
    };
    ListingModalComponent.prototype.showBrokerWhatsapp = function () {
        this.whatsappPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    };
    ListingModalComponent.prototype.registerUserActivity = function (userActivityType) {
        if (this.listing.offeredByUser) {
            var userActivity = {
                module: userActivityType,
                brokerId: this.listing.offeredByUser.id,
                listingId: this.listing.id
            };
            this.userActivityService.saveUserActivity(userActivity);
        }
    };
    ListingModalComponent.prototype.dismiss = function () {
        this.listingModalService.dismiss();
    };
    ListingModalComponent.prototype.getListingsInSameProperty = function () {
        var _this = this;
        var listingSearchDto = { buildingId: this.listing.building.id, listingIdsToExclude: [this.listing.id] };
        this.listingService.searchListings(listingSearchDto, 1).then(function (listingsResponse) {
            _this.totalCountListingsInSameProperty = parseInt(listingsResponse.headers.get('x-total-count'));
            _this.showMoreIconListingsInSameProperty = _this.totalCountListingsInSameProperty - _this.currentPage * 20 > 0;
            _this.listingsInSameProperty = listingsResponse.body.listings;
            _this.listingIdsInSameProperty = listingsResponse.body.allListingIds;
        });
    };
    ListingModalComponent.prototype.getMoreListingsInSameProperty = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingSearchDto;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                listingSearchDto = { buildingId: this.listing.building.id, listingIdsToExclude: [this.listing.id] };
                this.listingService.searchListings(listingSearchDto, ++this.currentPage).then(function (response) {
                    var newListings = response.body.listings;
                    _this.showMoreIconListingsInSameProperty = _this.totalCountListingsInSameProperty - _this.currentPage * 20 > 0;
                    if (newListings && newListings.length) {
                        _this.listingsInSameProperty = _this.listingsInSameProperty.concat(newListings);
                    }
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    ListingModalComponent.prototype.clickToChat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullListingDetailsURL, headerKey, headerMsg, msgToUserAgent, message;
            return tslib_1.__generator(this, function (_a) {
                fullListingDetailsURL = encodeURI(this.buildUrl());
                headerKey = 'global.listing-detail.clickToChat.whatsAppMsgHeader';
                headerMsg = this.i18nService.get(headerKey);
                msgToUserAgent = this.i18nService.get('global.listing-detail.clickToChat.msgToUserAgent');
                message = headerMsg + " " + fullListingDetailsURL + "\n\n" + msgToUserAgent;
                this.whatsAppService.sendMessageViaWhataspp(this.listing.offeredByUser.whatsappNumber, message);
                return [2 /*return*/, false];
            });
        });
    };
    ListingModalComponent.prototype.buildUrl = function () {
        return window.location.href.concat(this.i18nService.getLanguagePathURL());
    };
    ListingModalComponent.prototype.clickToEmail = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultMsg, message, popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultMsg = this.i18nService.get('global.listing-detail.clickToEmail.msgToUserAgent');
                        message = "" + defaultMsg;
                        return [4 /*yield*/, this.agentNotPopOverCtrl.create({
                                component: AgentNotificationComponent,
                                event: ev,
                                cssClass: 'popover-agent-email',
                                componentProps: {
                                    defaultMsg: message,
                                    brokerId: this.listing.offeredByUser.id,
                                    listingId: this.listing.id,
                                    popupCtrl: this.agentNotPopOverCtrl,
                                    pageURLFrom: encodeURI(this.buildUrl())
                                }
                            })];
                    case 1:
                        popover = _a.sent();
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingModalComponent.prototype.openEmail = function () {
        window.location.href = "mailto:" + this.listing.offeredByUser.email;
        return false;
    };
    ListingModalComponent.prototype.coworkingTypeChanged = function (event) {
        this.coworkingType = event.detail.value;
        this.calculateCoworkingValues(event.detail.value);
    };
    ListingModalComponent.prototype.coworkingPositionValueChanged = function (event) {
        this.calculateCoworkingValues(this.coworkingType);
    };
    ListingModalComponent.prototype.calculateCoworkingValues = function (coworkingType) {
        var pos = this.coworkingPositions && this.coworkingPositions > 0 ? this.coworkingPositions : 1;
        var found = false;
        var dailyPrice = 0;
        var monthlyPrice = 0;
        this.listing.coworkingEntries.forEach(function (item) {
            if (item.positionIni <= pos) {
                // Calculate using this item
                dailyPrice = coworkingType != 'private' ? item.sharedDailyByPosition : item.nonSharedDailyByPosition;
                monthlyPrice = coworkingType != 'private' ? item.sharedMonthlyByPosition : item.nonSharedMonthlyByPosition;
            }
        });
        if (this.coworkingPositions > this.listing.coworkingMaxPositions) {
            this.coworkingDailyPrice = null;
            this.coworkingMonthlyPrice = null;
        }
        else {
            if (this.coworkingPositions > 0) {
                this.listing.coworkingPositions = this.coworkingPositions;
                if (coworkingType == 'private') {
                    this.listing.sharedPrivatePositionsText =
                        this.coworkingPositions == 1
                            ? this.i18nService.get('global.privatePosition')
                            : this.i18nService.get('global.privatePositions');
                }
                else if (coworkingType == 'shared') {
                    this.listing.sharedPrivatePositionsText =
                        this.coworkingPositions == 1
                            ? this.i18nService.get('global.sharedPosition')
                            : this.i18nService.get('global.sharedPositions');
                }
            }
            this.coworkingDailyPrice = dailyPrice ? pos * dailyPrice : null;
            this.coworkingMonthlyPrice = monthlyPrice ? pos * monthlyPrice : null;
            this.listing.coworkingDP = this.coworkingDailyPrice;
            this.listing.coworkingMP = this.coworkingMonthlyPrice;
        }
    };
    ListingModalComponent.prototype.openListYourSpotDialogue = function () {
        var _this = this;
        this.isLoggedIn$.pipe(take(1)).subscribe(function (val) {
            if (val) {
                _this.openListingMenu();
            }
            else {
                _this.loginFormDialog.openDialog('my-listing');
            }
        });
    };
    ListingModalComponent.prototype.openListingMenu = function () {
        this.dialogRef = this.dialog.open(MyListingMenuComponent, {
            height: 'auto',
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    ListingModalComponent.prototype.isBasic = function () {
        return this.listing.listingType.toLowerCase() === 'basic';
    };
    ListingModalComponent.prototype.floorOrWarehouse = function (buildingRentRoll) {
        if (this.listing.building.buildingType.id === 1001) {
            return buildingRentRoll.warehouse;
        }
        else if (this.listing.building.buildingType.id === 2001) {
            return buildingRentRoll.floor;
        }
    };
    ListingModalComponent.prototype.suiteOrModule = function (buildingRentRoll) {
        if (this.listing.building.buildingType.id === 1001) {
            return buildingRentRoll.module;
        }
        else if (this.listing.building.buildingType.id === 2001) {
            return buildingRentRoll.suiteNumber;
        }
    };
    ListingModalComponent.prototype.setListing = function () {
        this.marker = { lat: this.listing.building.latitude, lng: this.listing.building.longitude };
    };
    ListingModalComponent.prototype.getNearestListings = function (state) {
        var _this = this;
        var pageNumber = 1;
        var searchDTO = this.nearbyListingSvc.getSearchCriteriaState(state, 'searchCriteria');
        var listingNearbySearchCriteria = this.nearbyListingSvc.setNearbyListingSearchCriteria(searchDTO, this.listing.building);
        this.nearbyListings = [];
        this.nearbySearchCriteria = { searchDTO: searchDTO, checkBoxValues: state ? state.checkBoxValues : null };
        this.searchTags = state ? state.searchTags : null;
        this.listingService.searchNearByListing(listingNearbySearchCriteria, pageNumber).then(function (result) {
            _this.nearbyListings = result.body;
        });
    };
    ListingModalComponent.prototype.getSelectPropertyListing = function (result) {
        this.selectedBuildingName = result.selectedBuilding;
        this.selectedPropertyListings = result.listings;
        if (result.listings.length) {
            var SCREEN_HEIGHT = 850;
            var elem = this.screenHeight > SCREEN_HEIGHT ? this.listingMapView.el : 'LISTING-CARD';
            if (elem === 'LISTING-CARD') {
                this.scrollToView.emit(elem);
            }
            else {
                this.nearbyListingSvc.scrollIntoViewElem(elem);
            }
        }
    };
    ListingModalComponent.prototype.refetchListing = function (searchFilter) {
        var _this = this;
        var pageNumber = 1;
        this.listingService.searchNearByListing(searchFilter.searchDTO, pageNumber).then(function (result) {
            _this.nearbySearchCriteria = { searchDTO: searchFilter.searchDTO, checkBoxValues: searchFilter.checkBoxValues };
            _this.selectedPropertyListings = [];
            _this.nearbyListings = result.body;
        });
        this.searchTags = searchFilter.searchTags;
        this.removedTagsFromNearBy.emit({ searchTags: this.searchTags, nearbySearchCriteria: this.nearbySearchCriteria });
    };
    ListingModalComponent.prototype.showPricingOptionsSection = function () {
        this.priceSharedOption = this.listing.coworkingDailyPrice > 0 || this.listing.coworkingMonthlyPrice > 0;
        this.pricePrivateOption =
            this.listing.coworkingPrivateDailyPrice > 0 || this.listing.coworkingPrivateMonthlyPrice > 0;
        //this.coworkingType = this.priceSharedOption ? 'shared' : 'private';
        return this.listing.coworkingMaxPositions && (this.priceSharedOption || this.pricePrivateOption);
    };
    return ListingModalComponent;
}());
export { ListingModalComponent };
