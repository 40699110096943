import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import { BaseService } from '../../spot-buildings/services/base-service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppRouteNamesService } from './app-route-names.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '@app/company/services/company.service';
import { BrokersService } from '@app/brokers/services/brokers.service';
var RoutingHelperService = /** @class */ (function (_super) {
    tslib_1.__extends(RoutingHelperService, _super);
    function RoutingHelperService(router, i18nService, breakpointObserver, appRouteNamesSrv, translateService, companyService, brokerService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.breakpointObserver = breakpointObserver;
        _this.appRouteNamesSrv = appRouteNamesSrv;
        _this.translateService = translateService;
        _this.companyService = companyService;
        _this.brokerService = brokerService;
        _this.isMobile = false;
        _this.getSpotSearchURL = function () { return "/" + _this.appRouteNamesSrv.getSearchRouteName(); };
        _this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        _this.translateService.onLangChange.subscribe(function (event) {
            //console.log('Lang changed Routing helpser ', event, this.getSpotSearchURL());
        });
        return _this;
    }
    RoutingHelperService.prototype.concatLanguageToURL = function (url) {
        return this.i18nService.concatLangParamBasedOnURL(url);
    };
    RoutingHelperService.prototype.navigateTo = function (url, state, samePage) {
        url = this.concatLanguageToURL(url);
        if (this.isMobile || samePage) {
            this.router.navigateByUrl(url, { state: state });
        }
        else {
            window.open(url, '_blank');
        }
    };
    RoutingHelperService.prototype.navigateToBrokerDetailsPage = function (brokerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.brokerService.getBrokerURL(brokerId)];
                    case 1:
                        url = _a.sent();
                        this.navigateTo(url.detailsURL);
                        return [2 /*return*/];
                }
            });
        });
    };
    RoutingHelperService.prototype.navigateToCompanyDetailsPage = function (companyId, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var companyURLFormat, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companyService.getCompanyDetailURL(companyId)];
                    case 1:
                        companyURLFormat = _a.sent();
                        detailsURL = companyURLFormat.detailsURL;
                        this.navigateTo(detailsURL);
                        return [2 /*return*/];
                }
            });
        });
    };
    RoutingHelperService.prototype.navigateToSearchPage = function (avoidTargetBlank) {
        if (avoidTargetBlank) {
            this.router.navigateByUrl(this.getSpotSearchURL());
        }
        else {
            this.navigateTo(this.getSpotSearchURL());
        }
    };
    RoutingHelperService.prototype.navigateToDefaultListingPropertySearchURL = function () {
        this.router.navigateByUrl("" + this.getSpotSearchURL() + this.appRouteNamesSrv.getDefaultInitSearchRouteName());
    };
    RoutingHelperService.prototype.navigateFavoritesPage = function () {
        var favoritesPath = "/" + this.appRouteNamesSrv.getMySpotsRouteName();
        this.navigateTo(favoritesPath, null, true);
    };
    RoutingHelperService.prototype.navigateToSpotHomePage = function () {
        this.navigateTo('/', null, true);
    };
    RoutingHelperService.prototype.navigateToSavedSearchPage = function (savedSearch) {
        var url = this.getSpotSearchURL() + "/" + this.appRouteNamesSrv.getSavedSearchRouteName() + "/" + savedSearch.user.id + "/" + savedSearch.id;
        if (this.isMobile) {
            this.router.navigateByUrl(url);
        }
        else {
            window.open(url, '_blank');
        }
    };
    RoutingHelperService.prototype.navigateToSpotSetSearchPage = function (item) {
        var newUrl = item.spotSearchURL + "/" + this.concatLanguageToURL(this.appRouteNamesSrv.getFilterRouteName()) + "?" + this.appRouteNamesSrv.getSpotSetRouteName() + "=" + item.name;
        this.router.navigateByUrl("" + this.getSpotSearchURL() + newUrl);
    };
    RoutingHelperService.prototype.navigateToTermsPage = function () {
        this.navigateTo(this.appRouteNamesSrv.getTermsRouteName(), null, true);
    };
    RoutingHelperService.prototype.navigateToPrivacyPolicyPage = function () {
        this.navigateTo(this.appRouteNamesSrv.getPrivacyPolyRouteName(), null, true);
    };
    return RoutingHelperService;
}(BaseService));
export { RoutingHelperService };
