import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NewsService } from '../services/news.service';
import { IonSlides } from '@ionic/angular';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';
var NewsComponent = /** @class */ (function () {
    function NewsComponent(newsService, breakpointObserver, i18nService) {
        var _this = this;
        this.newsService = newsService;
        this.breakpointObserver = breakpointObserver;
        this.i18nService = i18nService;
        this.companyId = null;
        this.currenPage = 0;
        this.isMobile = false;
        this.isFirstEnd = false;
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
            if (_this.isMobile) {
                _this.slideOpts = {
                    initialSlide: 0,
                    slidesPerView: 1,
                    isBeginningSlide: true
                };
            }
            else {
                if (window.innerWidth > 1000 && window.innerWidth < 1200) {
                    _this.slideOpts = {
                        initialSlide: 0,
                        slidesPerView: 2,
                        isBeginningSlide: true
                    };
                }
                else {
                    _this.slideOpts = {
                        initialSlide: 0,
                        slidesPerView: 3,
                        isBeginningSlide: true
                    };
                }
            }
        });
        this.lang = this.i18nService.getCurrentLanguage();
    }
    NewsComponent.prototype.ngOnInit = function () {
        this.getInitNews();
    };
    NewsComponent.prototype.getInitNews = function () {
        var _this = this;
        this.newsService
            .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 2 : 6, null, 63, this.companyId)
            .subscribe(function (resp) {
            _this.articles = resp;
        });
    };
    NewsComponent.prototype.openHomeNews = function () {
        window.open(environment.serverUrl + '/resource/', this.isMobile ? '_self' : '_blank');
    };
    NewsComponent.prototype.nextSlide = function () {
        this.checkisEnd();
    };
    NewsComponent.prototype.prevSlide = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.checkisBeginning(1);
                return [2 /*return*/];
            });
        });
    };
    NewsComponent.prototype.checkisBeginning = function (prev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isBeginning, isBeginning_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.slider.isBeginning()];
                    case 1:
                        isBeginning = _a.sent();
                        this.slideOpts.isBeginningSlide = isBeginning;
                        if (!(!isBeginning && prev == 1)) return [3 /*break*/, 3];
                        this.slider.slidePrev();
                        return [4 /*yield*/, this.slider.isBeginning()];
                    case 2:
                        isBeginning_1 = _a.sent();
                        this.slideOpts.isBeginningSlide = isBeginning_1;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    NewsComponent.prototype.checkisEnd = function (value) {
        if (value === void 0) { value = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isEnd;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.checkisBeginning(2);
                        return [4 /*yield*/, this.slider.isEnd()];
                    case 1:
                        isEnd = _a.sent();
                        if (isEnd && !this.articles.last) {
                            this.currenPage += 1;
                            this.newsService
                                .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 1 : 3, null, 63, this.companyId)
                                .subscribe(function (resp) {
                                var news = resp;
                                news.content.forEach(function (n) { return _this.articles.content.push(n); });
                                setTimeout(function () {
                                    if (value == null) {
                                        _this.slider.slideNext();
                                    }
                                }, 500);
                            });
                        }
                        else {
                            this.slider.slideNext();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    NewsComponent.prototype.slideReachEnd = function () {
        this.checkisEnd(1);
    };
    return NewsComponent;
}());
export { NewsComponent };
