import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { AuthGuard, BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { CreateSpotBuildingsComponent } from './components/create/create-spot-buildings.component';
import { BuildingsDetailPromotedComponent } from './components/detail-promoted/buildings-detail-promoted.component';
import { SpotBuildingsSearchComponent } from './components/search/spot-buildings-search.component';
import { TurnoffNotificationsComponent } from './components/turnoff-notifications/turnoff-notifications.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
import { SpotDetailContainerComponent } from './components/spot-detail-container/spot-detail-container.component';
var ɵ0 = { title: extract('Turn Off Notifications') }, ɵ1 = { title: extract('Spot Buildings Search') }, ɵ2 = { title: extract('Spot Buildings Search') }, ɵ3 = { title: extract('Listings Search') }, ɵ4 = { title: extract('Listings Search') }, ɵ5 = { title: extract('Listings Search') }, ɵ6 = { title: extract('Listings Search') }, ɵ7 = { title: extract('Saved Search') }, ɵ8 = {
    title: extract('Spot Buildings Search')
}, ɵ9 = { title: extract('Spot Buildings Search') }, ɵ10 = { title: extract('Spot Buildings Search') }, ɵ11 = { title: extract('Listings Search') }, ɵ12 = { title: extract('Listings Search') }, ɵ13 = { title: extract('Listings Search') }, ɵ14 = { title: extract('Listings Search') }, ɵ15 = { title: extract('Saved Search') }, ɵ16 = {
    title: extract('Spot Buildings Search')
}, ɵ17 = {
    title: extract('Spot Buildings create')
}, ɵ18 = {
    title: extract('Spot Buildings create')
}, ɵ19 = {
    title: extract('Spot Buildings create')
}, ɵ20 = {
    title: extract('Spot Buildings create')
}, ɵ21 = { title: extract('Spot Buildings Detail promoted'), shouldReuse: false }, ɵ22 = { title: extract('Spot Buildings Detail promoted'), shouldReuse: false }, ɵ23 = { title: extract('Spot Buildings Detail promoted'), shouldReuse: false }, ɵ24 = { title: extract('Spot Buildings Detail'), shouldReuse: false }, ɵ25 = { title: extract('Spot Buildings Detail'), shouldReuse: false }, ɵ26 = { title: extract('Spot Buildings Detail'), shouldReuse: false }, ɵ27 = { title: extract('Spot Buildings Detail'), shouldReuse: false }, ɵ28 = { title: extract('Spot Buildings Detail'), shouldReuse: false };
var routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    Shell.childRoutesRunningGuardsResolversAlways([
        {
            path: 'user/turnoff/notifications/:login/:notificationType/:language',
            component: TurnoffNotificationsComponent,
            data: ɵ0
        },
        {
            path: "" + AppRouteNames.SEARCH,
            component: SpotBuildingsSearchComponent,
            data: ɵ1
        },
        {
            path: AppRouteNames.SEARCH + "/:language",
            component: SpotBuildingsSearchComponent,
            data: ɵ2
        },
        {
            path: AppRouteNames.SEARCH + "/:listingType/:propertyType/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ3,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH + "/:listingType/:propertyType/" + AppRouteNames.FILTER_URL_PARAM + "/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ4,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH + "/:listingType/:propertyType/:state/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ5,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH + "/:listingType/:propertyType/:state/:city/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ6,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH + "/" + AppRouteNames.SAVED_SEARCH_URL_PARAM + "/:userId/:savedSearchId",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ7,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH + "/search/:language",
            component: SpotBuildingsSearchComponent,
            data: ɵ8
        },
        {
            path: "" + AppRouteNames.SEARCH_COUNTRYLANG,
            component: SpotBuildingsSearchComponent,
            data: ɵ9
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/:language",
            component: SpotBuildingsSearchComponent,
            data: ɵ10
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/:listingType/:propertyType/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ11,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/:listingType/:propertyType/" + AppRouteNames.FILTER_URL_PARAM_CL + "/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ12,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/:listingType/:propertyType/:state/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ13,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/:listingType/:propertyType/:state/:city/:language",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ14,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/" + AppRouteNames.SAVED_SEARCH_URL_PARAM_CL + "/:userId/:savedSearchId",
            component: SpotBuildingsSearchComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ15,
            runGuardsAndResolvers: 'always'
        },
        {
            path: AppRouteNames.SEARCH_COUNTRYLANG + "/search/:language",
            component: SpotBuildingsSearchComponent,
            data: ɵ16
        },
        {
            path: 'spot-buildings/create',
            component: CreateSpotBuildingsComponent,
            canActivate: [AuthGuard],
            data: ɵ17
        },
        {
            path: 'spot-buildings/create/:language',
            component: CreateSpotBuildingsComponent,
            canActivate: [AuthGuard],
            data: ɵ18
        },
        {
            path: 'spot-buildings/create/:buildingType/:buildingId',
            component: CreateSpotBuildingsComponent,
            canActivate: [AuthGuard],
            data: ɵ19
        },
        {
            path: 'spot-buildings/create/:buildingType/:buildingId/:language',
            component: CreateSpotBuildingsComponent,
            canActivate: [AuthGuard],
            data: ɵ20
        },
        {
            path: 'spot-property/detail/promoted/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId/:lang',
            component: BuildingsDetailPromotedComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ21
        },
        {
            path: 'spot-property/detail/promoted/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId',
            component: BuildingsDetailPromotedComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ22
        },
        {
            path: 'spot-property/detail/promoted/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId/lang/:lang',
            component: BuildingsDetailPromotedComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ23
        },
        {
            path: ':buildingType/:propertyType/:stateName/:cityName/:buildingName/:buildingId',
            component: SpotDetailContainerComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ24
        },
        {
            path: ':buildingType/:propertyType/:stateName/:cityName/:buildingName/:buildingId/:lang',
            component: SpotDetailContainerComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ25
        },
        {
            path: 'spot-property/detail/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId/lang/:lang',
            component: SpotDetailContainerComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ26
        },
        {
            path: 'spot-property/detail/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId',
            component: SpotDetailContainerComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ27
        },
        {
            path: 'spot-property/detail/:buildingType/:propertyType/:neighborhood/:buildingName/:buildingId/lang/:lang',
            component: SpotDetailContainerComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ28
        }
    ])
];
var SpotBuildingsRoutingModule = /** @class */ (function () {
    function SpotBuildingsRoutingModule() {
    }
    return SpotBuildingsRoutingModule;
}());
export { SpotBuildingsRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28 };
