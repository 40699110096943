import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { SpotBuildingsSearchService } from '../../../services/spot-buildings-search.service';
import { I18nService } from '@app/core';
import * as Fuse from 'fuse.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
var MoreFiltersSpotbuildingsComponent = /** @class */ (function () {
    function MoreFiltersSpotbuildingsComponent(navParams, popoverCtrl, spotBuildingsSearchService, i18NService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.spotBuildingsSearchService = spotBuildingsSearchService;
        this.i18NService = i18NService;
        this.btnFilter = false;
        this.loading = true;
        this.states = [];
        this.selectProperty = true;
        this.clear = 0;
        this.isMobile = false;
        this.inputBuilding = '';
        this.selectedProperty = [];
        this.stopFuzzy = false;
        this.stateName = '';
        this.cityName = '';
        this.filter = 0;
        this.faTimes = faTimes;
    }
    MoreFiltersSpotbuildingsComponent.prototype.ngOnInit = function () {
        this.getPopData();
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        if (this.searchDto.coworkingType) {
            this.coworkingFilter(0, this.searchDto.coworkingType);
        } /* else {
          this.coworkingFilter(1, CoworkingSpaceType.PRIVATE_OFFICE);
        } */
        if (!this.searchDto.buildingTypes) {
            this.searchDto.buildingTypes = [];
            this.searchDto.buildingTypes[0] = '2001';
        }
        if (!this.searchDto.listingLeaseOrSale) {
            this.searchDto.listingLeaseOrSale = [];
            this.searchDto.listingLeaseOrSale[0] = 'L';
        }
        if (!this.searchDto.propertySubTypes) {
            this.searchDto.propertySubTypes = [];
        }
        if (this.searchDto.spotTitlesOrAddresses) {
            this.selectedProperty = this.searchDto.spotTitlesOrAddresses;
        }
        if (!this.searchDto.spotStatus) {
            this.searchDto.spotStatus = 'ACTIVE';
        }
        this.isAdmin = this.checkSpotAdmin();
        this.setMinOptions(this.searchDto);
        this.getStatesByCountry();
        this.getPropertySearch();
        this.getcitiesByState(null);
    };
    MoreFiltersSpotbuildingsComponent.prototype.getPropertySearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.spotBuildingsSearchService.getPropertySearch(this.searchDto.buildingTypes[0], this.searchDto.listingLeaseOrSale[0], this.searchDto.propertySubTypes.length > 0 ? this.searchDto.propertySubTypes[0].id : null)];
                    case 1:
                        _a.propertySearch = _b.sent();
                        this.initBuildingSearch();
                        return [2 /*return*/];
                }
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.changeFuzzySearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.propertySearch = this.suggest_property(this.inputBuilding);
                return [2 /*return*/];
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.initBuildingSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            return tslib_1.__generator(this, function (_a) {
                options = {
                    keys: ['name'],
                    shouldSort: true,
                    caseSensitive: false,
                    threshold: 0.5,
                    findAllMatches: true
                };
                this.fuse = new Fuse(this.propertySearch, options);
                this.changeFuzzySearch();
                return [2 /*return*/];
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.setProperty = function (building) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.inputBuilding = '';
                this.searchDto.stateId = null;
                this.searchDto.cityId = null;
                this.stateName = '';
                this.cityName = '';
                this.selectedProperty.push(building);
                return [2 /*return*/];
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.suggest_property = function (term) {
        if (term) {
            var q = term.toLowerCase().trim();
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map(function (element) {
                return element;
            });
        }
    };
    MoreFiltersSpotbuildingsComponent.prototype.checkSpotAdmin = function () {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
            return true;
        }
        else {
            return false;
        }
    };
    MoreFiltersSpotbuildingsComponent.prototype.removeProperty = function (index) {
        this.selectedProperty.splice(index, 1);
    };
    MoreFiltersSpotbuildingsComponent.prototype.clearProperties = function () {
        this.selectedProperty = [];
    };
    MoreFiltersSpotbuildingsComponent.prototype.setBuildinType = function (event) {
        this.selectProperty = false;
        this.searchDto.buildingTypes[0] = event;
        if (this.searchDto.listingLeaseOrSale[0] == 'C') {
            this.setListingLeaseOrSale('L');
        }
        this.clearSearch();
        this.getStatesByCountry();
        this.getPropertySearch();
        this.setMinOptions(this.searchDto);
    };
    MoreFiltersSpotbuildingsComponent.prototype.setDefaultBuildinTypeAndListingType = function () {
        this.selectProperty = false;
        this.searchDto.buildingTypes[0] = '2001';
        this.setListingLeaseOrSale('L');
        this.clearSearch();
        this.getStatesByCountry();
        this.getPropertySearch();
    };
    MoreFiltersSpotbuildingsComponent.prototype.setPropertySubTypes = function (event) {
        var subtype;
        if (event == 1001) {
            subtype = {
                id: event,
                name: 'Industrial Complex',
                isChecked: true,
                translationNames: ''
            };
            this.searchDto.propertySubTypes[0] = subtype;
        }
        else {
            if (event == 1002) {
                subtype = {
                    id: event,
                    name: 'Stand Alone',
                    isChecked: true,
                    translationNames: ''
                };
                this.searchDto.propertySubTypes[0] = subtype;
            }
            else {
                this.searchDto.propertySubTypes = [];
            }
        }
        this.getPropertySearch();
        this.getStatesByCountry();
    };
    MoreFiltersSpotbuildingsComponent.prototype.setListingLeaseOrSale = function (event) {
        this.searchDto.listingLeaseOrSale[0] = event;
        if (event == 'C') {
            this.searchDto.buildingTypes[0] = '2001';
        }
        this.clearSearch();
        this.getPropertySearch();
        this.getStatesByCountry();
        this.setMinOptions(this.searchDto);
    };
    MoreFiltersSpotbuildingsComponent.prototype.setActiveFilter = function (active) {
        if (active) {
            this.searchDto.spotStatus = 'ACTIVE';
        }
        else {
            this.searchDto.spotStatus = 'INACTIVE';
        }
    };
    MoreFiltersSpotbuildingsComponent.prototype.getBuildingSubTypeId = function () {
        if (this.searchDto.propertySubTypes && this.searchDto.propertySubTypes.length > 0) {
            return this.searchDto.propertySubTypes[0].id;
        }
        return null;
    };
    MoreFiltersSpotbuildingsComponent.prototype.getStatesByCountry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, state;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.searchDto.stateId) {
                            this.searchDto.stateId = null;
                            this.searchDto.cityId = null;
                            this.states = [];
                            this.cities = [];
                        }
                        _a = this;
                        return [4 /*yield*/, this.spotBuildingsSearchService.getStatesByCountry(this.searchDto.buildingTypes[0], this.searchDto.listingLeaseOrSale[0], this.getBuildingSubTypeId())];
                    case 1:
                        _a.states = _b.sent();
                        if (this.searchDto.stateId && this.states) {
                            state = this.states.filter(function (state) { return state.id == _this.searchDto.stateId; });
                            this.stateName = state && state.length > 0 ? this.i18NService.getTranslation(state[0].name) : null;
                        }
                        this.states = this.states.sort(this.SortArray);
                        this.putRioAndSaoPauloOnTop(this.states);
                        if (this.searchDto.cityId) {
                            this.getcitiesByState(null);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.SortArray = function (x, y) {
        if (x.name < y.name) {
            return -1;
        }
        if (x.name > y.name) {
            return 1;
        }
        return 0;
    };
    MoreFiltersSpotbuildingsComponent.prototype.putRioAndSaoPauloOnTop = function (states) {
        if (states && states.length > 1) {
            for (var i = 0; i < states.length; ++i) {
                var state = states[i];
                if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
                    states.splice(i, 1);
                    if (states[0].name.includes('São Paulo')) {
                        states.splice(1, 0, state);
                    }
                    else {
                        states.splice(0, 0, state);
                    }
                }
            }
        }
        return states;
    };
    MoreFiltersSpotbuildingsComponent.prototype.getcitiesByState = function (state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, city;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (state != null) {
                            this.stateName = this.i18NService.getTranslation(state.name);
                            this.searchDto.stateId = state.id;
                        }
                        _a = this;
                        return [4 /*yield*/, this.spotBuildingsSearchService.getcitiesByState(this.searchDto.buildingTypes[0], this.searchDto.stateId, this.searchDto.listingLeaseOrSale[0], this.getBuildingSubTypeId())];
                    case 1:
                        _a.cities = _b.sent();
                        if (state == null) {
                            city = this.cities.filter(function (city) { return city.id == _this.searchDto.cityId; });
                            this.cityName = this.i18NService.getTranslation(city[0].name);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.setCity = function (city) {
        this.cityName = this.i18NService.getTranslation(city.name);
        this.searchDto.cityId = city.id;
    };
    MoreFiltersSpotbuildingsComponent.prototype.getPopData = function () {
        this.checkboxValues = this.navParams.data.checkboxValues;
        if (this.navParams.data.propType === '') {
            this.propertyType = null;
        }
        else {
            this.propertyType = this.navParams.data.propType;
            this.searchDto = this.navParams.data.searchDto;
        }
    };
    MoreFiltersSpotbuildingsComponent.prototype.setMinOptions = function (searchDto) {
        var _this = this;
        this.spotBuildingsSearchService
            .getMinMaxOptions(searchDto)
            .toPromise()
            .then(function (result) {
            localStorage.setItem('minMax', JSON.stringify(result));
            _this.staticMinMax = _this.spotBuildingsSearchService.setStaticMinMaxValues(result);
            _this.rangeModelValues = _this.spotBuildingsSearchService.mapMinMaxOptions(_this.searchDto, _this.staticMinMax);
            _this.loading = false;
        });
    };
    MoreFiltersSpotbuildingsComponent.prototype.dismiss = function () {
        this.rangeFilters = this.spotBuildingsSearchService.setSearchRangeFilters(this.rangeModelValues, this.staticMinMax);
        this.searchDto.spotTitlesOrAddresses = this.selectedProperty;
        var searchCopyDto = tslib_1.__assign({}, this.searchDto, this.rangeFilters);
        if (this.filter == 4) {
            searchCopyDto.positions = null;
        }
        if (this.filter == 0) {
            searchCopyDto.coworkingType = null;
        }
        this.popoverCtrl.dismiss(searchCopyDto);
    };
    MoreFiltersSpotbuildingsComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    MoreFiltersSpotbuildingsComponent.prototype.clearSearch = function () {
        this.clear = this.clear + 1;
        this.searchDto = this.spotBuildingsSearchService.clearFilters(this.searchDto, this.checkboxValues);
        var result = JSON.parse(localStorage.getItem('minMax'));
        this.staticMinMax = this.spotBuildingsSearchService.setStaticMinMaxValues(result);
        this.rangeModelValues = this.spotBuildingsSearchService.mapMinMaxOptions(null, this.staticMinMax);
        //this.searchDto.buildingTypes = ['2001'];
        //this.searchDto.listingLeaseOrSale = ['L'];
        this.searchDto.stateId = null;
        this.searchDto.cityId = null;
        this.searchDto.location = null;
        this.selectProperty = true;
        this.searchDto.yearBuiltIni = null;
        this.searchDto.yearBuiltEnd = null;
        this.searchDto.yearRenovatedIni = null;
        this.searchDto.yearRenovatedEnd = null;
        this.searchDto.spotTitlesOrAddresses = null;
        this.selectedProperty = [];
        this.stateName = '';
        this.cityName = '';
        this.searchDto.propertySubTypes = [];
        this.searchDto.availabilityDate = null;
        this.searchDto.coworkingType = null;
        this.searchDto.positions = null;
        this.filter = 0;
    };
    MoreFiltersSpotbuildingsComponent.prototype.clearSearchExternal = function () {
        this.setDefaultBuildinTypeAndListingType();
    };
    MoreFiltersSpotbuildingsComponent.prototype.scrollToTop = function () {
        this.modalContent.nativeElement.scrollTop = 0;
    };
    MoreFiltersSpotbuildingsComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    };
    MoreFiltersSpotbuildingsComponent.prototype.coworkingFilter = function (coworkingType, CoworkingSpace) {
        if (coworkingType == 1 || CoworkingSpace == CoworkingSpaceType.PRIVATE_OFFICE) {
            this.searchDto.coworkingType = CoworkingSpaceType.PRIVATE_OFFICE;
            this.filter = this.filter == 1 ? 0 : 1;
        }
        if (coworkingType == 2 || CoworkingSpace == CoworkingSpaceType.DEDICATED_WORKSPACE) {
            this.searchDto.coworkingType = CoworkingSpaceType.DEDICATED_WORKSPACE;
            this.filter = this.filter == 2 ? 0 : 2;
        }
        if (coworkingType == 3 || CoworkingSpace == CoworkingSpaceType.OPEN_WORKSPACE) {
            this.searchDto.coworkingType = CoworkingSpaceType.OPEN_WORKSPACE;
            this.filter = this.filter == 3 ? 0 : 3;
        }
        if (coworkingType == 4 || CoworkingSpace == CoworkingSpaceType.FULL_FLOOR_OFFICES) {
            this.searchDto.coworkingType = CoworkingSpaceType.FULL_FLOOR_OFFICES;
            this.filter = this.filter == 4 ? 0 : 4;
        }
    };
    return MoreFiltersSpotbuildingsComponent;
}());
export { MoreFiltersSpotbuildingsComponent };
