import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MultiLanguageService } from '@app/core/multi-language.service';
import { CommonGenericService } from '@app/core/common.service';
import { I18nService } from '@app/core';
import { EventCheckerService } from '@app/core/listings';
var MultiLanguageComponent = /** @class */ (function () {
    function MultiLanguageComponent(commonService, fb, multiLanguageService, i18nService, eventCheckerSrv) {
        this.commonService = commonService;
        this.fb = fb;
        this.multiLanguageService = multiLanguageService;
        this.i18nService = i18nService;
        this.eventCheckerSrv = eventCheckerSrv;
        this.selectedLanguage = [];
        this.tempFormData = {};
        this.editorStyle = {
            height: '200px'
        };
        this.height = null;
        this.onValueChange = new EventEmitter();
    }
    MultiLanguageComponent.prototype.ngOnInit = function () {
        if (this.height) {
            this.editorStyle.height = this.height;
        }
        var currentLanguage = this.multiLanguageService.getCurrentLanguage(this.i18nService.language);
        var browserLanguage = this.multiLanguageService.getBrowserLanguage(currentLanguage);
        this.options = this.options ? this.options : {};
        this.multiLangForm = this.multiLanguageService.createMultiLanguageForm(this.options.formArrayName);
        this.languageOption = this.multiLanguageService.getLanguageOption();
        this.languageModel = this.multiLanguageService.getLanguageModel();
        this.isBrazilRegion = this.commonService.checkApplicationEnvironment('br');
        this.defaultLanguage = browserLanguage;
        this.richTextEditorOption = this.multiLanguageService.ngQuillEditorToolbarOption();
        this.insertTextHere = this.i18nService.get('global.insertTxtHere');
        if (this.options.formArrayName === 'address') {
            this.detectBuildingLocationChanges();
        }
    };
    MultiLanguageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.populateFormArrayValue();
        }, 0);
    };
    MultiLanguageComponent.prototype.ngOnChanges = function (changes) {
        //FIXME  SIIL-3660
        try {
            if (JSON.parse(changes.formArrayValue.currentValue)) {
                this.formArrayValue = JSON.parse(changes.formArrayValue.currentValue);
                this.populateFormArrayValue(1);
            }
        }
        catch (error) { }
    };
    MultiLanguageComponent.prototype.getSelectedLanguageIndex = function (language) {
        return this.selectedLanguage.findIndex(function (item) { return item === language; });
    };
    MultiLanguageComponent.prototype.assignTemporaryFormData = function (key, data) {
        var _a;
        Object.assign(this.tempFormData, (_a = {}, _a[key] = data, _a));
    };
    MultiLanguageComponent.prototype.populateFormArrayValue = function (value) {
        var _this = this;
        if (value === void 0) { value = 0; }
        var isEmpty = this.commonService.isObjectEmpty(this.formArrayValue);
        this.languageModel = this.multiLanguageService.getLanguageModel();
        if (!isEmpty) {
            var formArray_1 = this.multiLangForm.get(this.options.formArrayName);
            if (value == 1) {
                formArray_1.clear();
                this.selectedLanguage = [];
            }
            Object.keys(this.formArrayValue).forEach(function (key) {
                var formGroup = _this.multiLanguageService.createLanguageFormGroup(_this.fb, key, null, _this.formArrayValue[key]);
                _this.languageModel[key] = true;
                _this.selectedLanguage = tslib_1.__spread(_this.selectedLanguage, [key]);
                _this.assignTemporaryFormData(key, _this.formArrayValue[key]);
                formArray_1.push(formGroup);
            });
            this.onValueChange.emit(this.multiLangForm.value);
        }
        else {
            this.addDefaultLanguageElement();
        }
    };
    MultiLanguageComponent.prototype.addDefaultLanguageElement = function () {
        var formArray = this.multiLangForm.get(this.options.formArrayName);
        var formGroup = this.multiLanguageService.createLanguageFormGroup(this.fb, this.defaultLanguage);
        this.selectedLanguage.push(this.defaultLanguage);
        this.languageModel[this.defaultLanguage] = true;
        formArray.push(formGroup);
    };
    MultiLanguageComponent.prototype.addLanguageControl = function (elemValue) {
        var isObjectEmpty = this.commonService.isObjectEmpty(this.tempFormData);
        var tempFormData = isObjectEmpty ? null : this.tempFormData[elemValue];
        var multiLangFormArray = this.multiLangForm.get(this.options.formArrayName);
        var formGroup = this.multiLanguageService.createLanguageFormGroup(this.fb, elemValue, null, tempFormData);
        this.selectedLanguage.push(elemValue);
        multiLangFormArray.push(formGroup);
        this.onValueChange.emit(this.multiLangForm.value);
    };
    MultiLanguageComponent.prototype.removeLanguageElem = function (language) {
        var isRemoveElement = true;
        var index = this.getSelectedLanguageIndex(language);
        var multiLangFormArray = this.multiLangForm.get(this.options.formArrayName);
        multiLangFormArray.removeAt(index);
        this.selectedLanguage.splice(index, 1);
        this.onElemChange(isRemoveElement);
    };
    Object.defineProperty(MultiLanguageComponent.prototype, "languageElemList", {
        get: function () {
            return this.multiLangForm.get(this.options.formArrayName);
        },
        enumerable: true,
        configurable: true
    });
    MultiLanguageComponent.prototype.getSelectLanguage = function (index, isKey) {
        var language = this.selectedLanguage[index];
        return isKey ? language : this.languageOption[language];
    };
    MultiLanguageComponent.prototype.onElemChange = function (isRemoveElement) {
        if (!isRemoveElement) {
            this.tempFormData = this.multiLanguageService.santizeFormValue(this.multiLangForm.value, this.options.formArrayName);
        }
        this.onValueChange.emit(this.multiLangForm.value);
    };
    MultiLanguageComponent.prototype.reset = function () {
        this.multiLangForm.reset();
        this.onElemChange();
    };
    MultiLanguageComponent.prototype.elemOrder = function (elem) {
        return elem.value === this.defaultLanguage ? 'first-order' : 'second-order';
    };
    MultiLanguageComponent.prototype.orderControlElem = function (index) {
        var language = this.selectedLanguage[index];
        if (this.selectedLanguage.length === 2) {
            return language === this.defaultLanguage ? 'first-order' : 'second-control';
        }
        return 'first-order';
    };
    MultiLanguageComponent.prototype.selectLanguageElement = function (selection, checkboxElem) {
        this.languageModel[selection] = !this.languageModel[selection];
        if (this.languageModel[selection]) {
            this.addLanguageControl(checkboxElem.value);
        }
        else {
            this.removeLanguageElem(checkboxElem.value);
        }
    };
    MultiLanguageComponent.prototype.detectBuildingLocationChanges = function () {
        var _this = this;
        this.eventCheckerSrv.updateBuildingLocation$.subscribe(function (buildingLocation) {
            if (_this.selectedLanguage && _this.selectedLanguage.length > 0 && buildingLocation.address) {
                var formArrayValue_1 = [];
                _this.selectedLanguage.forEach(function (lang) {
                    var addressObj = {};
                    addressObj[lang] = buildingLocation.address;
                    formArrayValue_1.push(addressObj);
                });
                if (_this.multiLangForm.get('address')) {
                    _this.multiLangForm.get('address').setValue(formArrayValue_1);
                    _this.onValueChange.emit(_this.multiLangForm.value);
                }
            }
        });
    };
    return MultiLanguageComponent;
}());
export { MultiLanguageComponent };
