import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { CommonGenericService } from '@app/core/common.service';
import { MultipleFilesProgressBarComponent } from '@app/shared/multiple-files-progress-bar/multiple-files-progress-bar.component';
import { EditCropImageModalComponent } from '@app/shared/edit-crop-image-modal/edit-crop-image-modal.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { ImageService } from '@app/core';
import { EditCropImageSaveModalComponent } from '@app/shared/edit-crop-image-save-modal/edit-crop-image-save-modal.component';
var imageUploadComponent = /** @class */ (function () {
    function imageUploadComponent(commonService, imageUploadService, imageService) {
        this.commonService = commonService;
        this.imageUploadService = imageUploadService;
        this.imageService = imageService;
    }
    imageUploadComponent.prototype.ngOnInit = function () { };
    imageUploadComponent.prototype.fileChangeEvent = function (event, remove) {
        var _this = this;
        var files = event.currentTarget.files;
        if (files.length > 20) {
            var infoModalParams = {
                headerTitle: 'global.uploadImage',
                bodyMessage: 'global.limitImagesSize'
            };
            this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
        }
        else {
            this.imageFiles = tslib_1.__spread(files);
            this.openMultipleFilesModal().then(function (r) {
                _this.saveAllFilesToImages();
            });
        }
    };
    imageUploadComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) { });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    imageUploadComponent.prototype.openMultipleFilesModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, editImageModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            allImgFiles: this.imageFiles,
                            imgList: this.imgList
                        };
                        cssClass = 'multiple-images-modal';
                        modalProps = this.getModalProp(MultipleFilesProgressBarComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        editImageModal.onDidDismiss().then(function (popoverData) {
                            _this.imageFiles = [];
                            _this.clearFileInput(document.getElementById('inputFile'));
                            if (popoverData.data) {
                            }
                            _this.openEditSaveImage();
                        });
                        return [2 /*return*/, editImageModal.present()];
                }
            });
        });
    };
    imageUploadComponent.prototype.openEditSaveImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, editImageModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            imgList: this.imgList,
                            imgCount: this.imgCount
                        };
                        cssClass = 'edit-crop-image-modal';
                        modalProps = this.getModalProp(EditCropImageSaveModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        editImageModal.onDidDismiss().then(function (resp) {
                            Object.keys(_this.imgList).forEach(function (i) {
                                if (!_this.imgList[i].croppedImage.id) {
                                    _this.addCroppedImgtoListingPhoto(_this.imgList[i]).then(function (savedBuildingListingPhoto) {
                                        _this.uploadingImages = false;
                                        if (savedBuildingListingPhoto) {
                                            _this.imgList[i] = savedBuildingListingPhoto;
                                        }
                                    });
                                }
                            });
                        });
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    imageUploadComponent.prototype.getModalProp = function (component, componentProps, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            cssClass: cssClass,
            component: component,
            componentProps: componentProps
        };
    };
    imageUploadComponent.prototype.clearFileInput = function (ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    };
    imageUploadComponent.prototype.saveAllFilesToImages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var previousSizeImgList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                previousSizeImgList = this.imgList.length;
                this.imgCount = 0;
                Object.keys(this.imageFiles).forEach(function (i) {
                    var file = _this.imageFiles[i];
                    var mimeType = file.type;
                    if (mimeType.match(/image\/*/) === null) {
                        var message = 'Only images are supported.';
                        _this.imageFiles[i].errorMsg = message;
                        _this.imageFiles[i].uploaded = true;
                    }
                    else {
                        var reader_1 = new FileReader();
                        reader_1.readAsDataURL(file);
                        reader_1.onload = function (_event) {
                            var uploadedImg = {
                                img: reader_1.result,
                                content: file.name,
                                caption: '',
                                photoOrder: parseInt(i) + previousSizeImgList + 1
                            };
                            _this.saveImgToDatabase(uploadedImg).then(function (buildinglistingPhoto) {
                                _this.imgList.push(buildinglistingPhoto);
                                _this.imageFiles[i].uploaded = true;
                                _this.imgCount++;
                                // this.imgList = this.sortListingPhotosByOrderId(this.imgList);
                            });
                        };
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    imageUploadComponent.prototype.sortListingPhotosByOrderId = function (buildinglistingPhotos) {
        if (buildinglistingPhotos == null) {
            return null;
        }
        return buildinglistingPhotos.sort(function (photo1, photo2) { return (photo1.orderId > photo2.orderId ? 1 : -1); });
    };
    imageUploadComponent.prototype.saveImgToDatabase = function (imgUploaded, buildinglistingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buildinglistingPhoto;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uploadPhotoWithoutListing(imgUploaded, buildinglistingPhotoId)];
                    case 1:
                        buildinglistingPhoto = _a.sent();
                        return [2 /*return*/, buildinglistingPhoto];
                }
            });
        });
    };
    imageUploadComponent.prototype.uploadPhotoWithoutListing = function (imgToUpload, buildinglistingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, imgToFile, croppedFile;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (!imgToUpload.caption) {
                            imgToUpload.caption = '';
                        }
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgToUpload.img, imgToUpload.content)];
                    case 1:
                        imgToFile = _a.sent();
                        croppedFile = null;
                        if (!imgToUpload.croppedImage) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgToUpload.croppedImage, imgToUpload.content)];
                    case 2:
                        croppedFile = _a.sent();
                        _a.label = 3;
                    case 3:
                        formData.append('caption', imgToUpload.caption);
                        formData.append('photoOrder', imgToUpload.photoOrder);
                        formData.append('file', imgToFile);
                        formData.append('content', imgToUpload.content);
                        formData.append('croppedFile', croppedFile);
                        if (buildinglistingPhotoId) {
                            formData.append('buildinglistingPhotoId', buildinglistingPhotoId);
                        }
                        if (imgToUpload.originalImageId && imgToUpload.originalImageId != null) {
                            formData.append('originalImageId', imgToUpload.originalImageId);
                        }
                        return [2 /*return*/, this.imageUploadService.createImage(formData)];
                }
            });
        });
    };
    imageUploadComponent.prototype.isNewImage = function (imgToEdit) {
        return imgToEdit.img && !imgToEdit.image;
    };
    imageUploadComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    imageUploadComponent.prototype.getImage = function (img) {
        if (this.isNewImage(img)) {
            if (img.croppedImage) {
                return img.croppedImage;
            }
            else {
                return img.img;
            }
        }
        else {
            if (img.croppedImage) {
                if (img.croppedImage.id) {
                    return this.getMediumImg(img.croppedImage.id);
                }
                else {
                    return img.croppedImage;
                }
            }
            return this.getMediumImg(img.image.id);
        }
    };
    imageUploadComponent.prototype.getImageContent = function (img) {
        if (!img) {
            return '';
        }
        return this.isNewImage(img) ? img.content : img.image.content;
    };
    imageUploadComponent.prototype.doReorder = function (ev) {
        var _this = this;
        if (this.imgList.length === 1) {
            ev.detail.complete();
            return;
        }
        if (this.imgList.length <= ev.detail.to) {
            var itemMove_1 = this.imgList.splice(ev.detail.from, 1)[0];
            this.imgList.splice(ev.detail.to, 0, itemMove_1);
            ev.detail.complete();
            return;
        }
        Object.keys(this.imgList).forEach(function (key) {
            if (typeof _this.imgList[key] === 'undefined') {
                delete _this.imgList[key];
            }
        });
        var itemMove = this.imgList.splice(ev.detail.from, 1)[0];
        this.imgList.splice(ev.detail.to, 0, itemMove);
        this.imgList.forEach(function (listingPhoto, index) {
            var newOrderId = index + 1;
            if (listingPhoto.orderId != newOrderId) {
                _this.updateOrderOrCaptionFrom(listingPhoto.id, undefined, newOrderId);
                _this.imgList[index].orderId = newOrderId;
            }
        });
        ev.detail.complete();
    };
    imageUploadComponent.prototype.updateOrderOrCaptionFrom = function (buildingListingPhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.imageUploadService.updateOrderOrCaptionFrom(buildingListingPhotoId, caption, orderId)];
            });
        });
    };
    imageUploadComponent.prototype.removeImage = function (ev, idx) {
        if (this.saveImgImmediately) {
            this.imageUploadService.deleteBuildingListingPhoto(this.imgList[idx]);
        }
        this.imgList.splice(idx, 1);
    };
    imageUploadComponent.prototype.openEditImageModal = function (imgIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imgToEdit, newImageBased64, originalImageId, imageUrlToEdit, fileName, isNewImage, imgId, croppedImgId, componentProps, cssClass, modalProps, editImageModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.uploadingImages = true;
                        imgToEdit = this.imgList[imgIndex];
                        newImageBased64 = null;
                        originalImageId = null;
                        imageUrlToEdit = null;
                        fileName = null;
                        isNewImage = this.isNewImage(imgToEdit);
                        if (!isNewImage) {
                            imgId = imgToEdit.image.id;
                            if (imgToEdit.originalImage) {
                                originalImageId = imgToEdit.originalImage.id;
                            }
                            fileName = imgToEdit.image.content;
                            imageUrlToEdit = "/images/" + imgId + "/thumbnail/ml";
                            if (imgToEdit.croppedImage) {
                                imageUrlToEdit = null;
                                if (imgToEdit.croppedImage.id) {
                                    croppedImgId = imgToEdit.croppedImage.id;
                                    imageUrlToEdit = "/images/" + croppedImgId + "/thumbnail/ml";
                                }
                                else {
                                    newImageBased64 = imgToEdit.croppedImage;
                                }
                            }
                        }
                        else {
                            newImageBased64 = imgToEdit.croppedImage ? imgToEdit.croppedImage : imgToEdit.img;
                            fileName = imgToEdit.content;
                            if (imgToEdit.originalImageId) {
                                originalImageId = imgToEdit.originalImageId;
                            }
                        }
                        componentProps = {
                            newImageBased64: newImageBased64,
                            originalImageId: originalImageId,
                            imageUrlToEdit: imageUrlToEdit,
                            showCroppingToggle: true,
                            fileName: fileName,
                            imgList: this.imgList
                        };
                        cssClass = 'edit-crop-image-modal';
                        modalProps = this.getModalProp(EditCropImageModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        editImageModal.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                var wasImageCropped = popoverData.data.wasImageCropped;
                                var newCroppedImage = popoverData.data.croppedImage;
                                var newOriginalImage = popoverData.data.originalImage;
                                var wasOriginalImgReplaced = popoverData.data.wasOriginalImgReplaced;
                                var wasRestoreToOriginalImage = popoverData.data.wasRestoreToOriginalImage;
                                var originalImageId_1 = popoverData.data.originalImageId;
                                if (newOriginalImage) {
                                    var content = newOriginalImage.file.name && newOriginalImage.file.name != 'null'
                                        ? newOriginalImage.file.name
                                        : 'New Image';
                                    if (isNewImage) {
                                        _this.imgList[imgIndex].img = newOriginalImage.imgBase64;
                                        _this.imgList[imgIndex].content = content;
                                        _this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                                        _this.imgList[imgIndex].originalImageId = originalImageId_1;
                                    }
                                    else {
                                        var caption = imgToEdit.image.caption;
                                        var originalImgId = imgToEdit.originalImage ? imgToEdit.originalImage.id : null;
                                        if (wasOriginalImgReplaced) {
                                            var imgToUpload = {
                                                img: newOriginalImage.imgBase64,
                                                content: content,
                                                caption: caption,
                                                croppedImage: newCroppedImage.imgBase64,
                                                originalImageId: originalImgId,
                                                photoOrder: _this.imgList[imgIndex].orderId
                                            };
                                            _this.saveImgToDatabase(imgToUpload, _this.imgList[imgIndex].id).then(function (BuildingListingPhoto) {
                                                _this.uploadingImages = false;
                                                _this.imgList[imgIndex] = BuildingListingPhoto;
                                            });
                                            //this.imgList[imgIndex]
                                        }
                                        else {
                                            // Save Cropped Image
                                            _this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                                            _this.addCroppedImgtoListingPhoto(_this.imgList[imgIndex]).then(function (savedBuildingListingPhoto) {
                                                _this.uploadingImages = false;
                                                if (savedBuildingListingPhoto) {
                                                    _this.imgList[imgIndex] = savedBuildingListingPhoto;
                                                }
                                            });
                                            if (wasRestoreToOriginalImage && _this.imgList[imgIndex].originalImage) {
                                                _this.imgList[imgIndex].image = _this.imgList[imgIndex].originalImage;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    imageUploadComponent.prototype.captionChangeListener = function (imgIndex, event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newCaption;
            return tslib_1.__generator(this, function (_a) {
                event.stopImmediatePropagation();
                newCaption = event.target.value;
                if (newCaption && this.imgList[imgIndex].caption != newCaption) {
                    this.imgList[imgIndex].caption = newCaption;
                    this.updateOrderOrCaptionFrom(this.imgList[imgIndex].id, newCaption);
                }
                return [2 /*return*/];
            });
        });
    };
    imageUploadComponent.prototype.addCroppedImgtoListingPhoto = function (buildingListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var croppedImage, croppedFile, caption;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        croppedImage = buildingListingPhoto.croppedImage;
                        if (croppedImage == null) {
                            return [2 /*return*/, null];
                        }
                        if (!!croppedImage.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(croppedImage, buildingListingPhoto.image.content)];
                    case 1:
                        croppedFile = _a.sent();
                        caption = buildingListingPhoto.image.caption && buildingListingPhoto.image.caption.length > 0
                            ? buildingListingPhoto.image.caption
                            : buildingListingPhoto.image.content;
                        return [4 /*yield*/, this.imageUploadService
                                .uploadCroppedImage(croppedFile, caption + "_cropped", buildingListingPhoto.id)
                                .then(function (createdImages) {
                                return createdImages;
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, croppedImage];
                }
            });
        });
    };
    return imageUploadComponent;
}());
export { imageUploadComponent };
