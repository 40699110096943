import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import { I18nService } from '@app/core';
import { ListingOfferType } from '../../../models/transaction-type.model';
var PropertyTypePopoverComponent = /** @class */ (function () {
    function PropertyTypePopoverComponent(navParams, popoverCtrl, listingService, i18nService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this.i18nService = i18nService;
        this.btnFilter = false;
        this.loading = true;
        this.propertySubTypes = [];
        this.popDataCalled = false;
    }
    PropertyTypePopoverComponent.prototype.ngOnInit = function () {
        this.getPopData();
        //this.setMinOptions(this.rangeFilters, this.searchDto);
    };
    PropertyTypePopoverComponent.prototype.propertyTypeChanged = function (event) {
        var _this = this;
        if (this.popDataCalled) {
            this.popDataCalled = false;
            return;
        }
        this.buildingType = event.detail.value;
        this.propertySubTypes = [];
        if (this.buildingType == '1001' || this.buildingType == '2001') {
            this.listingService
                .getPropertySubtypes(this.buildingType)
                .toPromise()
                .then(function (result) {
                if (result) {
                    result.forEach(function (element) {
                        _this.propertySubTypes.push({
                            name: _this.i18nService.getTranslation(element.name),
                            id: element.id,
                            translationNames: element.name
                        });
                    });
                }
            });
        }
    };
    PropertyTypePopoverComponent.prototype.getPopData = function () {
        var _this = this;
        this.popDataCalled = true;
        if (this.navParams.data.propType == undefined || this.navParams.data.propType === '') {
            this.buildingType = null;
            this.selectedPropertyType = 'null';
        }
        else {
            this.buildingType = this.navParams.data.propType;
            this.selectedPropertyType = '' + this.buildingType;
        }
        if (this.buildingType && this.buildingType !== ListingOfferType.Coworking) {
            this.listingService
                .getPropertySubtypes(this.buildingType)
                .toPromise()
                .then(function (result) {
                if (result) {
                    result.forEach(function (element) {
                        _this.propertySubTypes.push({
                            name: _this.i18nService.getTranslation(element.name),
                            id: element.id,
                            translationNames: element.name
                        });
                    });
                    if (_this.navParams.data.searchDto &&
                        _this.navParams.data.searchDto.propertySubTypes &&
                        _this.navParams.data.searchDto.propertySubTypes.length > 0) {
                        for (var i = 0; i < _this.navParams.data.searchDto.propertySubTypes.length; i++) {
                            for (var j = 0; j < _this.propertySubTypes.length; j++) {
                                if (_this.propertySubTypes[j].id == _this.navParams.data.searchDto.propertySubTypes[i].id) {
                                    _this.propertySubTypes[j].isChecked = true;
                                }
                            }
                        }
                    }
                }
            });
        }
        else {
            this.popDataCalled = false;
        }
        this.searchDto = this.navParams.data.searchDto;
        this.loading = false;
    };
    PropertyTypePopoverComponent.prototype.setMinOptions = function (filter, searchDto) {
        var _this = this;
        this.listingService
            .getMinMaxOptions(searchDto)
            .toPromise()
            .then(function (result) {
            localStorage.setItem('minMax', JSON.stringify(result));
            _this.staticMinMax = _this.listingService.setStaticMinMaxValues(result);
            _this.rangeModelValues = _this.listingService.mapMinMaxOptions(_this.searchDto, _this.staticMinMax);
            // if (Object.entries(this.searchDto).length === 0 && this.searchDto.constructor === Object!) {
            // } else {
            //   if (this.listingService.isRangeFilters(this.searchDto)) {
            //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
            //   } else {
            //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.rangeFilters, this.staticMinMax);
            //   }
            // }
            _this.loading = false;
        });
    };
    PropertyTypePopoverComponent.prototype.update = function () {
        var searchCopyDto = tslib_1.__assign({}, this.searchDto);
        if (this.buildingType == '1001' ||
            this.buildingType == '2001' ||
            this.buildingType === ListingOfferType.Coworking) {
            searchCopyDto.buildingTypes = [this.buildingType];
        }
        else {
            searchCopyDto.buildingTypes = null;
        }
        if (this.buildingType === ListingOfferType.Coworking) {
            searchCopyDto.listingType = ListingOfferType.Coworking;
            searchCopyDto.propertyType = null;
        }
        else {
            searchCopyDto.listingType = null;
            searchCopyDto.coworkingPositions = null;
            searchCopyDto.coworkingType = null;
        }
        var selectedPropertySubtypes = this.propertySubTypes.filter(function (e) { return e.isChecked; });
        searchCopyDto.propertySubTypes = selectedPropertySubtypes;
        if (searchCopyDto.propertySubTypes) {
            searchCopyDto.propertySubtypeIds = searchCopyDto.propertySubTypes.map(function (t) { return t.id; });
        }
        else {
            searchCopyDto.propertySubtypeIds = null;
        }
        this.popoverCtrl.dismiss(searchCopyDto);
    };
    PropertyTypePopoverComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    PropertyTypePopoverComponent.prototype.clearSearch = function () {
        this.searchDto = this.listingService.clearFilters(this.searchDto, this.checkboxValues);
        this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
    };
    PropertyTypePopoverComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    };
    return PropertyTypePopoverComponent;
}());
export { PropertyTypePopoverComponent };
