import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingOfferType } from '../../../../../models/transaction-type.model';
var ListTypeComponent = /** @class */ (function () {
    function ListTypeComponent(registerService, purchaseService, _ts, commonService, _eventChecker, i18NService, breakpointObserver) {
        var _this = this;
        this.registerService = registerService;
        this.purchaseService = purchaseService;
        this._ts = _ts;
        this.commonService = commonService;
        this._eventChecker = _eventChecker;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.onFilter = new EventEmitter();
        this.stepOne = false;
        this.languages = this.commonService.getLanguageOption();
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    ListTypeComponent.prototype.ngOnInit = function () {
        this.getBuildingT();
        if (!this.listing) {
            this.unsetSaleValidators();
        }
        else {
            this.listing.isForLeaseSale === 'SALE' || this.listing.isForLeaseSale === 'S'
                ? this.unsetLeaseValidators()
                : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
                    ? this.unsetSaleValidators()
                    : '';
            if (this.listing.isForLeaseSale === ListingOfferType.Coworking) {
                this.unsetLeaseValidators();
                this.unsetSaleValidators();
            }
        }
    };
    ListTypeComponent.prototype.disableForm = function () { };
    ListTypeComponent.prototype.changeSaleType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.listingTypeForm.controls.listingType.value === 'forSale') {
                    // unsetting validators
                    this.unsetLeaseValidators();
                }
                else if (this.listingTypeForm.controls.listingType.value === 'forRent') {
                    // listing for setting validations
                    this.unsetSaleValidators();
                }
                else if (this.listingTypeForm.controls.listingType.value === 'forCoworking') {
                    this.unsetSaleValidators();
                    this.unsetLeaseValidators();
                }
                return [2 /*return*/];
            });
        });
    };
    ListTypeComponent.prototype.unsetLeaseValidators = function () {
        this.listingPriceForm.controls['monthlyRent'].setValidators([]);
        this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
        this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
        this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
        this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
        this.listingPriceForm.controls['leaseableArea'].setValidators([]);
        this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
        this.listingPriceForm.controls['rentableArea'].setValidators([Validators.required]);
        this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
        if (!this.listingPriceForm.value.pricePer) {
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        }
    };
    ListTypeComponent.prototype.unsetSaleValidators = function () {
        this.listingPriceForm.controls['rentableArea'].setValidators([]);
        this.listingPriceForm.controls['rentableArea'].updateValueAndValidity();
        this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
        this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
        this.listingPriceForm.controls['leaseableArea'].setValidators([Validators.required]);
        this.listingPriceForm.controls['leaseableArea'].updateValueAndValidity();
        if (this.listingPriceForm.value.negRentableArea) {
            this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
        }
    };
    ListTypeComponent.prototype.setSubTypes = function (index) {
        var _this = this;
        if (this.subTypes[index].selected) {
            this.subTypes[index].selected = false;
            Object.keys(this.subTypes).forEach(function (type) {
                _this.subTypes[type].disabled = false;
            });
        }
        else {
            this.subTypes[index].selected = true;
            Object.keys(this.subTypes).forEach(function (type) {
                _this.subTypes[type].disabled = false;
            });
        }
        this.listingTypeForm.controls['subType'].setValue(this.subTypes);
    };
    ListTypeComponent.prototype.checkBuildingType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.clearInput();
                        this.fuse = null;
                        _a = this;
                        return [4 /*yield*/, this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value)];
                    case 1:
                        _a.subTypes = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getSpaceTranslations(this.subTypes)];
                    case 2:
                        _b.subTypes = _c.sent();
                        if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                            this.buildingNames = this.industrialBuildings;
                            this.buildings = this.industrialBuildings.values;
                            this._eventChecker.typeBuildings$.next('changed');
                        }
                        else {
                            this.buildingNames = this.officeBuildings;
                            this.buildings = this.officeBuildings.values;
                            this._eventChecker.typeBuildings$.next('changed');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListTypeComponent.prototype.clearInput = function () {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls.actualProperty.setValue(null);
        this.detailsForm.controls.propertyName.setValue('');
        this.detailsForm.controls.propertyName.enable();
    };
    ListTypeComponent.prototype.getBuildingT = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value)];
                    case 1:
                        _a.subTypes = _f.sent();
                        _b = this;
                        return [4 /*yield*/, this.getSpaceTranslations(this.subTypes)];
                    case 2:
                        _b.subTypes = _f.sent();
                        if (!(this.listing && this.listing.propertySubTypes)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.initSubTypes(this.listing.propertySubTypes, this.subTypes)];
                    case 3:
                        _c = _f.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        _c = null;
                        _f.label = 5;
                    case 5:
                        _c;
                        this.setDisabledFields(this.subTypes);
                        _d = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(1001)];
                    case 6:
                        _d.industrialBuildings = _f.sent();
                        _e = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(2001)];
                    case 7:
                        _e.officeBuildings = _f.sent();
                        if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                            this.buildingNames = this.industrialBuildings;
                            this.buildings = this.industrialBuildings.values;
                        }
                        else {
                            this.buildingNames = this.officeBuildings;
                            this.buildings = this.officeBuildings.values;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListTypeComponent.prototype.initSubTypes = function (subType, subTypes) {
        Object.keys(subType).forEach(function (key) {
            var result = subTypes.find(function (filter) { return filter.id === subType[key].id; });
            if (result) {
                result.selected = true;
            }
        });
    };
    ListTypeComponent.prototype.setDisabledFields = function (subTypes) { };
    ListTypeComponent.prototype.getSpaceTypes = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getBuildingSubTypes(id).toPromise()];
            });
        });
    };
    ListTypeComponent.prototype.getSpaceTranslations = function (subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(subTypes).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        subTypes[key].name = this.getI18nValue(subTypes[key].name);
                        subTypes[key].selected = false;
                        subTypes[key].disabled = false;
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, subTypes];
            });
        });
    };
    ListTypeComponent.prototype.getI18nValue = function (textValue) {
        return this.i18NService.getTranslation(textValue);
    };
    return ListTypeComponent;
}());
export { ListTypeComponent };
