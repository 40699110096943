import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { CommonGenericService } from '@app/core/common.service';
import { ListingFilterModalComponent } from '../my-listing-filter/listing-filter-modal/listing-filter-modal.component';
import * as MyListingModel from '@app/models/my-listing.model';
import { PopoverController } from '@ionic/angular';
var MyListingFilterComponent = /** @class */ (function () {
    function MyListingFilterComponent(commonSvc, popoverCtrl) {
        this.commonSvc = commonSvc;
        this.popoverCtrl = popoverCtrl;
        this.updateFilter = new EventEmitter();
    }
    MyListingFilterComponent.prototype.setButtonColor = function (width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    };
    MyListingFilterComponent.prototype.overrideColor = function (width) {
        return this.screenWidth >= width ? 'filter-btn-color' : 'filter-btn-white';
    };
    MyListingFilterComponent.prototype.onModalDismiss = function (modal) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                modal.onWillDismiss().then(function (result) {
                    _this.updateFilter.emit(result.data);
                });
                return [2 /*return*/];
            });
        });
    };
    MyListingFilterComponent.prototype.openMyListingFilterModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cssClass, componentProps, modalProps, popover;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cssClass = 'popover-type-search';
                        componentProps = {
                            agents: this.agents,
                            commonSvc: this.commonSvc,
                            isListingAdmin: this.isListingAdmin,
                            isSpotAdmin: this.isSpotAdmin,
                            listingCompanies: this.listingCompanies,
                            screenWidth: this.screenWidth,
                            selectCompany: this.selectCompany,
                            selectAgent: this.selectAgent
                        };
                        modalProps = this.commonSvc.modalProps(ListingFilterModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.popoverCtrl.create(modalProps)];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            _this.updateFilter.emit(popoverData.data);
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: 
                    //this.onModalDismiss(listingFilterModal);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return MyListingFilterComponent;
}());
export { MyListingFilterComponent };
