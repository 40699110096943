import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ListPropertyService } from '../../core/listings/list-property.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';
import { Store } from '@ngrx/store';
import * as CartActions from '@app/store/actions';
var FourAllPaymentComponent = /** @class */ (function () {
    function FourAllPaymentComponent(router, listPropertyService, alertCtrl, i18nService, store) {
        this.router = router;
        this.listPropertyService = listPropertyService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.store = store;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
    }
    FourAllPaymentComponent.prototype.getSelection = function () {
        var _this = this;
        this.cartTotalPrice = this.listPropertyService.getCartTotalPrice() + '00';
        this.cartElements = this.listPropertyService.getCartItems();
        this.spotAdminSelection = this.listPropertyService.getPaymentSpotAdminSelection();
        this.store.select(CartActions.GetItem).subscribe(function (store) {
            _this.cart = store.cart.items;
        });
    };
    FourAllPaymentComponent.prototype.showPaymentMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this.i18nService.get('global.purchase-options.paymentProcessed'),
                            subHeader: message,
                            buttons: [this.i18nService.get('global.purchase-options.paymentProcessedOK')]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            _this.router.navigateByUrl('/user-home/my-payments');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FourAllPaymentComponent.prototype.ngOnInit = function () {
        this.getSelection();
        FourAllTransparentCheckout({
            //publicApiKey: 'BeWT3k/vypkk+pbCv0DR43TM7Lb33GAY5hB2z/yRoa4=',
            // T0nBElMzOyT24f1HchAnRCHBOiKPRFXuvJ49raqTnkU=
            publicApiKey: environment.publicApiKeyPaylee,
            //publicApiKey: 'T0nBElMzOyT24f1HchAnRCHBOiKPRFXuvJ49raqTnkU=',
            backendAPI: this.listPropertyService,
            cartElements: this.cartElements,
            cart: this.cart,
            store: this.store,
            spotAdminSelection: this.spotAdminSelection,
            element: document.getElementById('payment-area'),
            form: document.getElementById('form'),
            paymentMessage: this.showPaymentMessage,
            i18nService: this.i18nService,
            alertCtrl: this.alertCtrl,
            router: this.router,
            primaryColor: '#043650',
            primaryFont: 'Arial, sans-serif',
            submitBorder: '15px',
            titleBorder: '15px',
            requireAddress: false,
            /*customer: {
              fullName: 'John Doe',
              emailAddress: 'john@doe.com.br',
              cpf: '03003003003',
              phoneNumber: '5551999995555',
              birthday: '1990-10-10'
            },*/
            payment: {
                amount: Number(this.cartTotalPrice),
                subtotal: Number(this.cartTotalPrice),
                shipping: 0,
                installments: 3
            },
            onLoad: function () {
                // Callback opcional
            },
            onError: function () {
                // Callback opcional
            },
            performPayment: function (event) {
                var _this = this;
                JSON.stringify($('form').serializeArray());
                var loginForm = $('form').serializeArray();
                var loginFormObject = {};
                loginFormObject.customer = {}; // this.customer;
                loginFormObject.payment = this.payment;
                $.each(loginForm, function (t, v) {
                    loginFormObject[v.name] = v.value;
                });
                loginFormObject.customer.email = loginFormObject.email;
                loginFormObject.customer.birthday = loginFormObject.birthday;
                loginFormObject.customer.cpf = loginFormObject.cpf;
                loginFormObject.customer.fullName = loginFormObject.fullName;
                loginFormObject.customer.phoneNumber = loginFormObject.phoneNumber;
                loginFormObject.purchaseOptions = this.cartElements;
                if (this.spotAdminSelection) {
                    loginFormObject.userId = this.spotAdminSelection.userId;
                    loginFormObject.companyId = this.spotAdminSelection.companyId;
                }
                this.backendAPI
                    .fourAllPaymentTest(loginFormObject)
                    .toPromise()
                    .then(function (result) {
                    var message = '';
                    if (result) {
                        if (result.status.id === 4) {
                            message = _this.i18nService.get('global.purchase-options.paymentTrxCaptured');
                        }
                        else {
                            // tslint:disable-next-line: max-line-length
                            message = _this.i18nService.get('global.purchase-options.paymentMsgSubheader') + " " + _this.i18nService.getTranslation(result.status.description);
                        }
                    }
                    _this.paymentMessage(message);
                    _this.cart.forEach(function (item, idx) {
                        _this.store.dispatch(CartActions.RemoveItem({ payload: { item: { id: item.id }, index: idx } }));
                    });
                });
                event.preventDefault();
            }
        });
    };
    FourAllPaymentComponent.prototype.goBack = function () {
        this.router.navigateByUrl('/cart');
    };
    return FourAllPaymentComponent;
}());
export { FourAllPaymentComponent };
