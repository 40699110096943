import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ContactDialogService } from '../contact-dialog/contact-dialog.service';
import { Router } from '@angular/router';
import { CommonGenericService } from '@app/core/common.service';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '@app/core/auth';
import { I18nService } from '@app/core';
import { environment } from '@env/environment';
import { HomePageAppService } from '../services/homepageapp.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(contactDialogService, router, commonService, loginFormDialog, auth, i18NService, homePageAppService, routingHelperSrv) {
        var _this = this;
        this.contactDialogService = contactDialogService;
        this.router = router;
        this.commonService = commonService;
        this.loginFormDialog = loginFormDialog;
        this.auth = auth;
        this.i18NService = i18NService;
        this.homePageAppService = homePageAppService;
        this.routingHelperSrv = routingHelperSrv;
        this.footerLinks = [
            {
                label: 'global.search.findYourSPOT',
                navigateTo: function () {
                    _this.router.navigateByUrl('/search');
                }
            },
            {
                label: 'global.search.propertyListings',
                navigateTo: function () {
                    _this.$isLoggedIn.pipe(take(1)).subscribe(function (val) {
                        if (val) {
                            _this.router.navigate(["/list-property"]);
                        }
                        else {
                            _this.loginFormDialog.openDialog('/list-property');
                        }
                    });
                }
            }
        ];
        this.contactLink = {
            label: 'global.menu.options.contactUs'
        };
        this.subscribeLink = {
            label: 'global.newsForm.subList'
        };
        this.aboutLink = {
            label: 'global.menu.options.silBr'
        };
        this.termsLink = {
            label: 'global.menu.options.terms'
        };
        this.privacyPolicyLink = {
            label: 'global.menu.options.privacyPolicy'
        };
        this.solutions = [];
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.globalPhoneNumber1 = this.i18NService.get('global.PhoneNumber1');
        this.globalPhoneNumber2 = this.i18NService.get('global.PhoneNumber2');
        this.globalContactEmail = this.i18NService.get('global.contactEmail');
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.$isLoggedIn = this.auth.isLoggedIn();
        this.solutions = this.homePageAppService.getAllSolutions();
        this.setShortNameAndLinks();
        this.homePageAppService.foundSolutions.subscribe(function (all) {
            _this.solutions = all;
            _this.setShortNameAndLinks();
        });
    };
    FooterComponent.prototype.setShortNameAndLinks = function () {
        var e_1, _a;
        if (this.solutions) {
            try {
                for (var _b = tslib_1.__values(this.solutions), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var solution = _c.value;
                    solution.urlLink = this.homePageAppService.getSolutionURLByObject(solution);
                    solution.nameLink = this.i18NService.getTranslation(solution.shortName);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    FooterComponent.prototype.ngOnDestroy = function () { };
    FooterComponent.prototype.presentContactDialog = function () {
        this.contactDialogService.openDialog();
    };
    FooterComponent.prototype.presentNewsletterDialog = function () { };
    FooterComponent.prototype.dismissContactDialog = function () { };
    FooterComponent.prototype.goToSiiLaHomePage = function () {
        this.commonService.navgiateToSiilaHomePage();
    };
    FooterComponent.prototype.goToSiilaProductsPage = function () {
        window.open(this.homePageAppService.getProductsURL(), '_system');
    };
    FooterComponent.prototype.goToSiilaTermsPage = function () {
        this.routingHelperSrv.navigateToTermsPage();
    };
    FooterComponent.prototype.goToSiilaPrivacyPolicyPage = function () {
        this.routingHelperSrv.navigateToPrivacyPolicyPage();
    };
    FooterComponent.prototype.siilaHomePageURL = function () {
        return environment.serverUrl;
    };
    FooterComponent.prototype.siilaSpotHomePageURL = function () {
        return environment.spotServerUrl;
    };
    FooterComponent.prototype.openContactEmail = function () {
        window.location.href = "mailto:spot@siila.com.br";
        return false;
    };
    FooterComponent.prototype.goToHomePage = function () {
        return this.commonService.goToHomePage();
    };
    FooterComponent.prototype.goToAllProducts = function () {
        return this.homePageAppService.getProductsURL();
    };
    FooterComponent.prototype.goToMarketAnalytics = function () {
        return this.homePageAppService.getMarketAnalyticsURL();
    };
    FooterComponent.prototype.goToIndices = function () {
        return this.homePageAppService.getIndicesURL();
    };
    FooterComponent.prototype.goToSpot = function () {
        return this.homePageAppService.getSpotURL();
    };
    FooterComponent.prototype.goToAcademy = function () {
        return this.homePageAppService.getAcademyURL();
    };
    FooterComponent.prototype.goToGrocs = function () {
        return this.homePageAppService.getGrocsURL();
    };
    FooterComponent.prototype.goToInvestorSurvey = function () {
        return this.homePageAppService.getInvestorSurveyURL();
    };
    FooterComponent.prototype.goToFiiDataPRO = function () {
        return this.homePageAppService.getFiiDataProURL();
    };
    FooterComponent.prototype.getInstitutionalInvestorsURL = function () {
        return this.homePageAppService.getInstitutionalInvestorsURL();
    };
    FooterComponent.prototype.getOwnersDevelopersURL = function () {
        return this.homePageAppService.getOwnersDevelopersURL();
    };
    FooterComponent.prototype.getTenantsOccupiersURL = function () {
        return this.homePageAppService.getTenantsOccupiersURL();
    };
    FooterComponent.prototype.getBrokerageFirmsURL = function () {
        return this.homePageAppService.getBrokerageFirmsURL();
    };
    FooterComponent.prototype.getValuationAdvisorsURL = function () {
        return this.homePageAppService.getValuationAdvisorsURL();
    };
    FooterComponent.prototype.getServiceProvidersURL = function () {
        return this.homePageAppService.getServiceProvidersURL();
    };
    FooterComponent.prototype.getReitInvestorsURL = function () {
        return this.homePageAppService.getReitInvestorsURL();
    };
    return FooterComponent;
}());
export { FooterComponent };
