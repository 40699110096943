import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { RenewModalComponent } from '../renew-modal/renew-modal.component';
import { PopoverController } from '@ionic/angular';
var CurrentPlansComponent = /** @class */ (function () {
    function CurrentPlansComponent(router, registerService, purchaseCreditsService, popoverCtrl, ngZone) {
        this.router = router;
        this.registerService = registerService;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.ngZone = ngZone;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.screenWidth = window.innerWidth;
        this.getParams();
    }
    CurrentPlansComponent.prototype.ngOnInit = function () {
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.nonAdminInit();
    };
    CurrentPlansComponent.prototype.getParams = function () {
        var navigation = this.router.getCurrentNavigation();
        this.renew = navigation.extras.state && navigation.extras.state.revise ? navigation.extras.state.revise : null;
        this.listingId =
            navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
        this.listing = navigation.extras.state && navigation.extras.state.listing ? navigation.extras.state.listing : null;
    };
    CurrentPlansComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    CurrentPlansComponent.prototype.nameChange = function (event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
            this.getAllCredits();
        }
    };
    CurrentPlansComponent.prototype.checkAdmin = function (role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user.roles.includes(role)) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    CurrentPlansComponent.prototype.checkSpotAdmin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user.roles.includes('SPOT_ADMIN')) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    CurrentPlansComponent.prototype.nonAdminInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, index, userIndex;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCompanies()];
                    case 1:
                        _a.allCompanies = _c.sent();
                        this.user = JSON.parse(localStorage.getItem('user'));
                        _b = this;
                        return [4 /*yield*/, this.getUsersByCompany(this.user.company.id)];
                    case 2:
                        _b.users = _c.sent();
                        index = this.allCompanies.findIndex(function (v) {
                            return v.id === _this.user.company.id;
                        });
                        userIndex = this.users.findIndex(function (v) {
                            return v.id === _this.user.id;
                        });
                        this.selectCompany = this.allCompanies[index];
                        this.offeredByUserId = this.users[userIndex];
                        this.getAllCredits();
                        return [2 /*return*/];
                }
            });
        });
    };
    CurrentPlansComponent.prototype.portChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUsersByCompany($event.value.id)];
                    case 1:
                        _a.users = _b.sent();
                        this.agentComponent.clear();
                        this.offeredByUserId = null;
                        this.userCredits = [];
                        return [2 /*return*/];
                }
            });
        });
    };
    CurrentPlansComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    CurrentPlansComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    CurrentPlansComponent.prototype.getAllCredits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.purchaseCreditsService.getCurrentCredits(this.offeredByUserId.id)];
                    case 1:
                        _a.userCredits = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CurrentPlansComponent.prototype.navigateToPurchase = function () {
        this.router.navigateByUrl('/list-property');
    };
    CurrentPlansComponent.prototype.navigateToMyListing = function () {
        this.router.navigateByUrl('/user-home/my-listings');
    };
    CurrentPlansComponent.prototype.navigateToCreate = function (credit, ev) {
        var _this = this;
        if (this.renew && this.listingId) {
            this.openRenewalPopover(ev, credit, this.listingId, this.listing);
        }
        else if (this.selectCompany && this.offeredByUserId) {
            this.ngZone.run(function () {
                _this.router.navigateByUrl('/user-home/my-listings/create-property', {
                    state: {
                        credit: credit,
                        company: _this.selectCompany,
                        offeredByUserId: _this.offeredByUserId,
                        date: new Date()
                    },
                    replaceUrl: true,
                    queryParams: { date: new Date().getTime() }
                });
            });
        }
    };
    CurrentPlansComponent.prototype.openRenewalPopover = function (ev, credit, listingId, listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: RenewModalComponent,
                            event: ev,
                            cssClass: 'popover-renewal',
                            componentProps: { credit: credit, listingId: listingId, listing: listing },
                            backdropDismiss: false
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CurrentPlansComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    return CurrentPlansComponent;
}());
export { CurrentPlansComponent };
