import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
var MultiLanguageService = /** @class */ (function () {
    function MultiLanguageService() {
    }
    MultiLanguageService.prototype.createMultiLanguageForm = function (formArrayTitle) {
        var _a;
        return new FormGroup((_a = {},
            _a[formArrayTitle] = new FormArray([]),
            _a));
    };
    MultiLanguageService.prototype.getLanguageOption = function () {
        return {
            en: 'global.languageChoice.english',
            es: 'global.languageChoice.spanish',
            'pt-br': 'global.languageChoice.brasilportuguese'
        };
    };
    MultiLanguageService.prototype.createLanguageFormGroup = function (fb, selectedLanguage, formControlName, value) {
        var _a;
        var formControlPropName = formControlName ? formControlName : selectedLanguage;
        return fb.group((_a = {},
            _a[formControlPropName] = new FormControl(value ? value : '', Validators.required),
            _a));
    };
    MultiLanguageService.prototype.getLanguageModel = function () {
        return {
            en: false,
            es: false,
            'pt-br': false
        };
    };
    MultiLanguageService.prototype.santizeFormValue = function (formValue, fieldName) {
        var santizeValue = {};
        formValue[fieldName].forEach(function (item) {
            Object.keys(item).forEach(function (key) {
                var _a;
                Object.assign(santizeValue, (_a = {}, _a[key] = item[key], _a));
            });
        });
        return santizeValue;
    };
    MultiLanguageService.prototype.ngQuillEditorToolbarOption = function () {
        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image', 'video']
            ]
        };
    };
    MultiLanguageService.prototype.getBrowserLanguage = function (lang) {
        var supportLanguage = {
            en: 'en',
            es: 'es',
            pt: 'pt-br'
        };
        return supportLanguage.hasOwnProperty(lang.toLowerCase()) ? supportLanguage[lang] : 'en';
    };
    MultiLanguageService.prototype.getCurrentLanguage = function (currentLanguage) {
        var language = '';
        if (currentLanguage.toLowerCase() === 'pt-br') {
            language = 'pt';
        }
        else if (currentLanguage.toLowerCase() === 'en-us') {
            language = 'en';
        }
        return language;
    };
    return MultiLanguageService;
}());
export { MultiLanguageService };
