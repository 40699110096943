import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18nService, PlatformHelperService } from '@app/core';
import { ToastController } from '@ionic/angular';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { faTrash, faEllipsisV, faEdit, faSearch, faFilter, faArrowCircleDown, faFileDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DownloadFileService } from '../services/downloadFile.service';
import { ShareEmailComponent } from '../share-email/share-email.component';
import { environment } from '@env/environment';
import { EditTourBookComponent } from '../edit-tour-book/edit-tour-book.component';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
var TourBooksComponent = /** @class */ (function () {
    function TourBooksComponent(favoriteServices, i18nService, dialog, toastCtrl, messageFormDialog, breakpointObserver, downloadFileService, platformHelpService, routingHelperSrv) {
        var _this = this;
        this.favoriteServices = favoriteServices;
        this.i18nService = i18nService;
        this.dialog = dialog;
        this.toastCtrl = toastCtrl;
        this.messageFormDialog = messageFormDialog;
        this.breakpointObserver = breakpointObserver;
        this.downloadFileService = downloadFileService;
        this.platformHelpService = platformHelpService;
        this.routingHelperSrv = routingHelperSrv;
        this.changeStepOutput = new EventEmitter();
        this.pageSize = 10;
        this.page = 0;
        this.collection = 0;
        this.seeSearch = false;
        this.isMobile = false;
        this.filterCount = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    TourBooksComponent.prototype.ngOnInit = function () {
        this.trash = faTrash;
        this.ellipsisV = faEllipsisV;
        this.edit = faEdit;
        this.search = faSearch;
        this.filter = faFilter;
        this.arrowDown = faArrowCircleDown;
        this.fileDownload = faFileDownload;
        this.share = faShare;
        this.getTourBooks(0);
    };
    TourBooksComponent.prototype.getTourBooks = function (page) {
        var _this = this;
        this.page = page;
        var lang = this.i18nService.getCurrentLanguage();
        var bodySpotSet = {
            sortBy: this.param,
            direction: this.direction,
            titleFilter: this.filterTourBook
        };
        this.favoriteServices.getTourBook(page, lang, bodySpotSet).then(function (value) {
            _this.tourBookList = value;
            _this.collection = _this.tourBookList.totalElements;
            _this.pageSize = _this.tourBookList.size;
        });
    };
    TourBooksComponent.prototype.changePage = function (page) {
        this.getTourBooks(page - 1);
    };
    TourBooksComponent.prototype.searchProperty = function () {
        this.seeSearch = !this.seeSearch;
    };
    TourBooksComponent.prototype.searchFilter = function () {
        var _this = this;
        if (this.filterCount == 0) {
            this.filterCount = 1;
            setTimeout(function () {
                _this.getTourBooks(0);
                _this.filterCount = 0;
            }, 2000);
        }
    };
    TourBooksComponent.prototype.nameAscending = function () {
        this.param = 'name';
        this.direction = 'ASC';
        this.getTourBooks(0);
    };
    TourBooksComponent.prototype.nameDescending = function () {
        this.param = 'name';
        this.direction = 'DESC';
        this.getTourBooks(0);
    };
    TourBooksComponent.prototype.oldestFirst = function () {
        this.param = 'date';
        this.direction = 'ASC';
        this.getTourBooks(0);
    };
    TourBooksComponent.prototype.newestFirst = function () {
        this.param = 'date';
        this.direction = 'DESC';
        this.getTourBooks(0);
    };
    TourBooksComponent.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert-detail'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TourBooksComponent.prototype.propertiesText = function (message) {
        this.messageFormDialog.openDialogMessage(message);
    };
    TourBooksComponent.prototype.shareTourBook = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(ShareEmailComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                id: item.id,
                url: environment.spotServerUrl + "/api/tourbookdashboard/download/" + item.id + "/" + item.fileName
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.presentToast(_this.i18nService.get('favorites.sendEmailSuccess'));
            }
        });
    };
    TourBooksComponent.prototype.downloadTourBook = function (item) {
        this.downloadFileService.downloadPDF("/api/tourbookdashboard/download/" + item.id + "/", item.fileName, this.platformHelpService.isIosSafari());
    };
    TourBooksComponent.prototype.deleteTourBook = function (id) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                message: this.i18nService.get('favorites.tourBooks.deleteTour'),
                id: id,
                apiName: '/api/tourbookdashboard/'
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.presentToast(_this.i18nService.get('favorites.deleteTourMessage'));
                _this.getTourBooks(0);
            }
        });
    };
    TourBooksComponent.prototype.goToSpotSet = function () {
        this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
    };
    TourBooksComponent.prototype.editTourBook = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(EditTourBookComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                title: true,
                id: item.id,
                name: item.reportTitle
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.getTourBooks(0);
                _this.presentToast(val);
            }
        });
    };
    return TourBooksComponent;
}());
export { TourBooksComponent };
