import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { PlatformHelperService } from './helpers';
import { I18nService } from '../core/i18n.service';
import { environment } from '@env/environment';
import { LocalNumberPipe } from '../shared/pipes/number-pipe';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
var CommonGenericService = /** @class */ (function () {
    function CommonGenericService(i18n, modalCtrl, platformHelpService, _decimalPipe) {
        this.i18n = i18n;
        this.modalCtrl = modalCtrl;
        this.platformHelpService = platformHelpService;
        this._decimalPipe = _decimalPipe;
        this.EXISTING_REGIONS = { mx: 'Mexico', br: 'Brazil' };
        this.BLUE_PIN = "/assets/maps/pin-blue.png";
    }
    CommonGenericService.prototype.getUserRegion = function () {
        var _this = this;
        var regionISO = this.EXISTING_REGIONS.br;
        Object.keys(this.EXISTING_REGIONS).forEach(function (key) {
            regionISO = environment.spotServerUrl.includes(key) ? _this.EXISTING_REGIONS[key] : regionISO;
        });
        return regionISO;
    };
    CommonGenericService.prototype.getLeaseTypeTxt = function (industrialLeaseType) {
        if (industrialLeaseType) {
            if (industrialLeaseType == 'DIRECT') {
                return this.i18n.get('spaceDetail.direct');
            }
            else {
                return this.i18n.get('spaceDetail.sublease');
            }
        }
        return '';
    };
    CommonGenericService.prototype.isBuildingExist = function (list, building) {
        return list.findIndex(function (item) { return item.buildingId === building.buildingId; }) !== -1;
    };
    CommonGenericService.prototype.getByteString = function (dataURI) {
        return dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    };
    CommonGenericService.prototype.convertByteToTypeArray = function (byteString) {
        var imageArray = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            imageArray[i] = byteString.charCodeAt(i);
        }
        return imageArray;
    };
    CommonGenericService.prototype.convertDataURIToFile = function (dataURI) {
        var byteString = this.getByteString(dataURI);
        var mimeString = dataURI
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        var imageArray = this.convertByteToTypeArray(byteString);
        return {
            mime: mimeString,
            imageArray: imageArray
        };
    };
    CommonGenericService.prototype.convertBlobToFile = function (blobFile, fileName) {
        return Object.assign(blobFile, {
            lastModifed: new Date(),
            name: fileName
        });
    };
    CommonGenericService.prototype.isJSON = function (str) {
        try {
            JSON.parse(str);
        }
        catch (error) {
            return false;
        }
        return true;
    };
    CommonGenericService.prototype.getFileNameFromPath = function (fileNamePath) {
        return fileNamePath
            .split('\\')
            .pop()
            .split('/')
            .pop();
    };
    CommonGenericService.prototype.getAllMatchingListing = function (listingList, listing) {
        return listingList.filter(function (item) { return item.buildingId === listing.buildingId; });
    };
    CommonGenericService.prototype.navgiateToSiilaHomePage = function () {
        window.open(environment.serverUrl, '_blank');
    };
    CommonGenericService.prototype.navgiateToSiilaNews = function () {
        window.open(environment.serverUrl + "/resource", '_blank');
    };
    CommonGenericService.prototype.navgiateToSiilaSpot = function () {
        //window.open(this.homepageservice.getSpotURL(), '_blank');
    };
    CommonGenericService.prototype.navgiateToSiilaProductsPage = function () {
        // window.open(this.homepageservice.getProductsURL(), '_system');
    };
    CommonGenericService.prototype.changeMarkerIcon = function (marker, eventType, icon) {
        google.maps.event.addDomListener(marker, eventType, function () {
            if (!marker.isSelected) {
                marker.setIcon(icon);
            }
        });
    };
    CommonGenericService.prototype.getUniqueBuilding = function (buildingList) {
        var _this = this;
        return buildingList.reduce(function (acccumulator, item) {
            var itemAlreadyExist = _this.isBuildingExist(acccumulator, item);
            if (!itemAlreadyExist) {
                acccumulator.push(item);
            }
            return acccumulator;
        }, []);
    };
    CommonGenericService.prototype.createListingInfoWindow = function (listings) {
        var infoWindowContent = "\n     <div style=\"height:25px; width: fit-content; height: fit-content\">\n      <div style=\"display: flex; flex-flow:column nowrap\">\n        <div style=\"color: #043650; font-weight:600\"> " + this.i18n.getTranslation(listings[0].buildingTitle) + " </div>\n        <div> " + this.i18n.getTranslation(listings[0].buildingAddress) + " </div>\n        <div style=\"color: #ff9900; padding-top: 10px; padding-bottom: 5px; font-weight: 500\">\n          " + listings.length + " " + (listings.length === 1 ? 'listing' : 'listings') + " available\n        </div>\n      <div>\n    </div>";
        return new google.maps.InfoWindow({
            content: infoWindowContent
        });
    };
    CommonGenericService.prototype.createMapInfoWindow = function (building) {
        var infoWindowContent = "\n     <div style=\"height:25px; width: fit-content; height: fit-content\">\n      <div style=\"display: flex; flex-flow:column nowrap\">\n        <div style=\"color: #043650; font-weight:600\"> " + this.i18n.getTranslation(building.title) + " </div>\n        <div> " + this.i18n.getTranslation(building.address) + " </div>\n      <div>\n    </div>";
        return new google.maps.InfoWindow({
            content: infoWindowContent
        });
    };
    CommonGenericService.prototype.createModal = function (modalProp) {
        return this.modalCtrl.create(modalProp);
    };
    CommonGenericService.prototype.getDefaultIntlPhoneProp = function () {
        var userRegionISO = this.getUserRegion();
        return {
            preferredCountries: [CountryISO[userRegionISO], CountryISO.UnitedStates],
            enablePlaceholder: true,
            enableAutoCountrySelect: false,
            searchCountryFlag: true,
            selectFirstCountry: false,
            maxLength: 15,
            validation: true,
            selectedCountryIso: '',
            phoneValidation: true,
            tooltipField: TooltipLabel.Name,
            separateDialCode: true
        };
    };
    CommonGenericService.prototype.convertImageBase64ToFile = function (dataURI, fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.convertImageBase64ToFileSync(dataURI, fileName)];
            });
        });
    };
    CommonGenericService.prototype.convertImageBase64ToFileSync = function (dataURI, fileName) {
        var isSafari = this.platformHelpService.isIosChrome();
        var isChrome = this.platformHelpService.isIosChrome();
        var imageData = this.convertDataURIToFile(dataURI);
        var imageFile;
        if (isSafari) {
            var blobFile = new Blob(imageData.imageArray, { type: imageData.mime });
            imageFile = this.convertBlobToFile(blobFile, fileName);
        }
        else if (isChrome) {
            imageFile = new File([imageData.imageArray], fileName, { type: imageData.mime });
        }
        else {
            imageFile = new File([imageData.imageArray], fileName, { type: imageData.mime });
        }
        return imageFile;
    };
    CommonGenericService.prototype.getValidImageExension = function () {
        return ['.jpg', '.jpeg', '.bmp', '.png', '.gif', '.tif'];
    };
    CommonGenericService.prototype.validateExtension = function (exts, fileName) {
        return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$', 'i').test(fileName);
    };
    CommonGenericService.prototype.getLanguageOption = function () {
        return [
            {
                label: 'global.enUS',
                value: 'en'
            },
            {
                label: 'global.esMX',
                value: 'es'
            },
            {
                label: 'global.ptBR',
                value: 'pt-br'
            }
        ];
    };
    CommonGenericService.prototype.getLanguage = function (langs, index) {
        var key = langs[index];
        var languages = {
            en: 'global.languageChoice.english',
            es: 'global.languageChoice.spanish',
            'pt-br': 'global.languageChoice.brasilportuguese'
        };
        return languages[key];
    };
    CommonGenericService.prototype.getItemIndex = function (items, key) {
        return items.findIndex(function (item) { return item === key; });
    };
    CommonGenericService.prototype.updateLocalStorageData = function (localStorageName, newData) {
        localStorage.removeItem(localStorageName);
        localStorage.setItem(localStorageName, JSON.stringify(newData));
    };
    CommonGenericService.prototype.isObjectEmpty = function (object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    CommonGenericService.prototype.isPropertyType = function (buildingTypeId, listing) {
        var listingBuildingType = listing.hasOwnProperty('building')
            ? listing.building.buildingType.id
            : listing.buildingTypeId;
        return listingBuildingType === buildingTypeId;
    };
    CommonGenericService.prototype.santizeListingSearchDTO = function (searchModel) {
        var copyOfSearchModel = Object.assign({}, searchModel);
        var listSearchDTO = {};
        copyOfSearchModel.propertyType =
            copyOfSearchModel.propertyType && copyOfSearchModel.propertyType === 'all'
                ? null
                : copyOfSearchModel.propertyType;
        copyOfSearchModel.listingType =
            copyOfSearchModel.listingType && copyOfSearchModel.listingType === 'all' ? null : copyOfSearchModel.listingType;
        listSearchDTO.buildingTypes = copyOfSearchModel.propertyType ? [copyOfSearchModel.propertyType] : null;
        listSearchDTO.listingLeaseOrSale = copyOfSearchModel.listingType ? [copyOfSearchModel.listingType] : null;
        listSearchDTO.keyword = copyOfSearchModel.keywordText ? copyOfSearchModel.keywordText : '';
        return listSearchDTO;
    };
    CommonGenericService.prototype.santizeListingSearchModel = function (model) {
        if (model.hasOwnProperty('listingType')) {
            model.listingType = model.listingType === 'all' ? null : model.listingType;
        }
        if (model.hasOwnProperty('propertyType')) {
            model.propertyType = model.propertyType === 'all' ? null : model.propertyType;
        }
        return model;
    };
    CommonGenericService.prototype.createMapMarker = function (property, infoWindow) {
        return new google.maps.Marker({
            position: {
                lat: property.latitude,
                lng: property.longitude
            },
            map: null,
            draggable: false,
            icon: this.BLUE_PIN
        });
    };
    CommonGenericService.prototype.setArbitraryArray = function (lists) {
        return lists && lists.length
            ? lists.map(function (item, index) {
                return index;
            })
            : [];
    };
    CommonGenericService.prototype.isBeginingOfSlide = function (currentSlideIndex, prevSlideIndex, numberOfPhoto) {
        return [
            currentSlideIndex === 0 && prevSlideIndex === 1,
            currentSlideIndex === 0 && !prevSlideIndex,
            currentSlideIndex === 0 && prevSlideIndex === numberOfPhoto
        ].some(function (condition) { return condition; });
    };
    CommonGenericService.prototype.isEndOfSlide = function (currentSlideIndex, prevSlideIndex, numberOfPhoto) {
        return [
            currentSlideIndex === numberOfPhoto && prevSlideIndex === numberOfPhoto - 1,
            currentSlideIndex === numberOfPhoto && !prevSlideIndex
        ].some(function (condition) { return condition; });
    };
    CommonGenericService.prototype.checkApplicationEnvironment = function (environment) {
        return window.location.host.includes(environment);
    };
    CommonGenericService.prototype.transformArrayStringList = function (lists, attr) {
        var transformList = lists.map(function (item) {
            return item[attr];
        });
        return transformList.join(', ');
    };
    CommonGenericService.prototype.translateLocaleText = function (lists, attr) {
        var _this = this;
        return lists.map(function (item) {
            var isValidJSON = _this.isJSON(item[attr]);
            item[attr] = isValidJSON ? _this.i18n.getTranslation(item[attr]) : item[attr];
            return item;
        });
    };
    CommonGenericService.prototype.santizeMultiLanguageRawData = function (formData) {
        var sanitizeData = {};
        formData.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
                var _a;
                Object.assign(sanitizeData, (_a = {}, _a[key] = item[key], _a));
            });
        });
        return JSON.stringify(sanitizeData);
    };
    CommonGenericService.prototype.removeObjectAttr = function (objects, keys) {
        var newObjests = Object.assign({}, objects);
        keys.forEach(function (key) {
            if (newObjests.hasOwnProperty(key)) {
                delete newObjests[key];
            }
        });
        return newObjests;
    };
    CommonGenericService.prototype.sortDateDesc = function (dates, attr) {
        return dates.sort(function (first, second) {
            return new Date(second[attr]) - new Date(first[attr]);
        });
    };
    CommonGenericService.prototype.deleteNullAttributesFrom = function (listingSearchDto) {
        Object.keys(listingSearchDto).forEach(function (key) { return listingSearchDto[key] == null && delete listingSearchDto[key]; });
        return listingSearchDto;
    };
    CommonGenericService.prototype.modalProps = function (component, props, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            component: component,
            componentProps: props,
            cssClass: cssClass
        };
    };
    CommonGenericService.prototype.capitalizeFirstLetter = function (word) {
        if (!word || word.length == 0) {
            return word;
        }
        word = word.toLowerCase();
        var str = word.split(' ');
        for (var i = 0, x = str.length; i < x; i++) {
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
        return str.join(' ');
    };
    CommonGenericService.prototype.getFormatedDate = function (date) {
        if (date instanceof Date) {
            var year = date.getFullYear();
            var day = date.getDate();
            var month = date.getMonth() + 1;
            if (month - 10 < 0) {
                month = '0' + month;
            }
            if (day - 10 < 0) {
                day = '0' + day;
            }
            return month + '-' + day + '-' + year;
        }
    };
    CommonGenericService.prototype.getPropertyTypeText = function (buildingTypeId) {
        if (buildingTypeId) {
            switch (buildingTypeId) {
                case 1001:
                    return this.i18n.get('global.list-your-property.industrial');
                case 2001:
                    return this.i18n.get('global.list-your-property.office');
            }
        }
        return 'not-found';
    };
    CommonGenericService.prototype.getTypeText = function (type) {
        if (type) {
            switch (type) {
                case 'L':
                    return 'Lease';
                case 'S':
                    return 'Sale';
                case 'C':
                    return 'Coworking';
                default:
                    return '';
            }
        }
        return 'not-found';
    };
    CommonGenericService.prototype.getAvailableAreaText = function (spaceRangesDTO, spotBuildingType) {
        if (spotBuildingType && spotBuildingType == 'C') {
            return this.getPositions(spaceRangesDTO);
        }
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = this.formatNumberTo(rangeDTO.minArea);
            var maxValue = this.formatNumberTo(rangeDTO.maxArea);
            if (minValue != maxValue && parseFloat(minValue) !== 0) {
                return minValue + " m\u00B2 - " + maxValue + " m\u00B2";
            }
            else if (minValue != maxValue && parseFloat(minValue) === 0) {
                return maxValue + " m\u00B2";
            }
            else {
                return maxValue + " m\u00B2";
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getAvgAskingRent = function (spaceRangesDTO, spotBuildingType) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minAskingPriceOrRent;
            var maxValue = rangeDTO.maxAskingPriceOrRent;
            /*  const decimals = spotBuildingType && spotBuildingType == 'S' ? 0 : 2; */
            if (minValue != maxValue && minValue !== 0) {
                return "R$ " + this.formatNumberTo(minValue /* , decimals */) + " - R$ " + this.formatNumberTo(maxValue /* , decimals */);
            }
            else if (minValue != maxValue && minValue === 0) {
                return "R$ " + this.formatNumberTo(maxValue /* , decimals */);
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return "R$ " + this.formatNumberTo(minValue /* , decimals */);
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getAskingPrice = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minAskingPrice;
            var maxValue = rangeDTO.maxAskingPrice;
            if (minValue != maxValue) {
                return "R$ " + this.formatNumberTo(minValue, 0) + " - R$ " + this.formatNumberTo(maxValue, 0);
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return "R$ " + this.formatNumberTo(minValue);
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getAskingPricePerArea = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minAskingPricePerArea;
            var maxValue = rangeDTO.maxAskingPricePerArea;
            if (minValue != maxValue) {
                return "R$ " + this.formatNumberTo(minValue) + "/m\u00B2 - R$" + this.formatNumberTo(maxValue) + "/m\u00B2";
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return "R$ " + this.formatNumberTo(minValue) + "/m\u00B2";
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getMonthlyCost = function (space) {
        if (space && space.monthlyCost) {
            if (space.monthlyCost > 0) {
                return "R$ " + this.formatNumberTo(space.monthlyCost);
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getMonthlyCostWithPositions = function (parameters) {
        if (parameters.positions == 0 ||
            parameters.positions == null ||
            parameters.monthlyCost == 0 ||
            parameters.monthlyCost == null) {
            return this.i18n.get('global.list-your-property.negotiable');
        }
        var value = this.formatNumberTo(parameters.positions * parameters.monthlyCost);
        return "R$ " + value;
    };
    CommonGenericService.prototype.getMonthlyRentPerPerson = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minMonthlyCost;
            var maxValue = rangeDTO.maxMonthlyCost;
            if (minValue != maxValue) {
                return "R$" + this.formatNumberTo(minValue) + " - R$" + this.formatNumberTo(maxValue);
            }
            else if (minValue != 0 && minValue != null) {
                return "R$" + this.formatNumberTo(minValue);
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getPositions = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minPositions;
            var maxValue = rangeDTO.maxPositions;
            if (minValue != maxValue) {
                minValue = minValue == 0 ? 1 : minValue;
                maxValue = maxValue == 0 ? 1 : maxValue;
                return this.formatNumberTo(minValue) + " - " + this.formatNumberTo(maxValue);
            }
            else {
                minValue = minValue == 0 ? 1 : minValue;
                return "" + this.formatNumberTo(minValue);
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getAskingRent = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minAskingRent;
            var maxValue = rangeDTO.maxAskingRent;
            if (minValue != maxValue && minValue != 0 && minValue != null) {
                return "R$ " + this.formatNumberTo(minValue) + " - R$ " + this.formatNumberTo(maxValue);
            }
            else {
                if (maxValue != 0 && maxValue != null) {
                    return "R$ " + this.formatNumberTo(maxValue);
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.getAskingRentPerArea = function (spaceRangesDTO) {
        if (spaceRangesDTO) {
            var rangeDTO = spaceRangesDTO;
            var minValue = rangeDTO.minAskingRentPerArea;
            var maxValue = rangeDTO.maxAskingRentPerArea;
            if (minValue != maxValue && minValue != 0 && minValue != null) {
                return "R$ " + this.formatNumberTo(minValue) + "/m\u00B2 - R$ " + this.formatNumberTo(maxValue) + "/m\u00B2";
            }
            else {
                if (maxValue != 0 && maxValue != null) {
                    return "R$ " + this.formatNumberTo(maxValue) + "/m\u00B2";
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    };
    CommonGenericService.prototype.formatNumberTo = function (value, decimals) {
        if (decimals == null || decimals == undefined)
            return this._decimalPipe.transform(value, "1.0-0");
        if (decimals >= 0) {
            var numberFormat = "1." + decimals + "-" + decimals;
            return this._decimalPipe.transform(value, numberFormat);
        }
    };
    CommonGenericService.prototype.getAvailableAreaLabel = function (spot) {
        var key = 'global.availableArea';
        if (spot.type == 'C') {
            key = 'spaceDetail.availablePositions';
        }
        return this.i18n.get(key) + ':';
    };
    CommonGenericService.prototype.getAskingRentPriceLabel = function (spot) {
        var key = 'buildinsDetail.askingRentCard';
        if (spot.type == 'S') {
            key = 'buildinsDetail.askingPriceCard';
        }
        return this.i18n.get(key) + ':';
    };
    CommonGenericService.prototype.isMobileWidth = function () {
        if (window.innerWidth < 700) {
            return true;
        }
        return false;
    };
    CommonGenericService.prototype.mapCoworkingType = function (coworkingType) {
        if (CoworkingSpaceType.PRIVATE_OFFICE == coworkingType) {
            return this.i18n.get('buildinsDetail.privateOffice');
        }
        if (CoworkingSpaceType.DEDICATED_WORKSPACE == coworkingType) {
            return this.i18n.get('buildinsDetail.dedicatedWorkspace');
        }
        if (CoworkingSpaceType.OPEN_WORKSPACE == coworkingType) {
            return this.i18n.get('buildinsDetail.openWorkspace');
        }
        if (CoworkingSpaceType.FULL_FLOOR_OFFICES == coworkingType) {
            return this.i18n.get('buildinsDetail.fullFloorOffices');
        }
    };
    CommonGenericService.prototype.goToHomePage = function () {
        var homePage = '/';
        if (this.i18n.currentUrlContainsCurrentLanguage()) {
            homePage = this.i18n.concatLanguagePathURL(homePage);
        }
        return homePage;
    };
    return CommonGenericService;
}());
export { CommonGenericService };
