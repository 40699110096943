import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { FavoriteSpotService } from '@app/shared/services/favorite-spot.service';
var FavoritesComponent = /** @class */ (function () {
    function FavoritesComponent(breakpointObserver, favoriteService) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.favoriteService = favoriteService;
        this.favoritesCount = 0;
        this.displayScrollToTop = false;
        this.isMobile = false;
        this.spotBuildingSearchRequest = {};
        this.isFavorite = true;
        this.listSpotSet = [];
        this.selectedIndex = 0;
        this.countSpotSet = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    FavoritesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM', 'PROMOTED'];
                this.spotBuildingSearchRequest.favoriteSearch = true;
                return [2 /*return*/];
            });
        });
    };
    FavoritesComponent.prototype.updateCounts = function (total) {
        this.favoritesCount = total;
    };
    FavoritesComponent.prototype.getHeaderResults = function () {
        return this.favoritesCount + " SPOTs Saved As Favorites";
    };
    FavoritesComponent.prototype.isFooterVisible = function (event) {
        this.displayScrollToTop = event.visible;
    };
    FavoritesComponent.prototype.changeStep = function (step) {
        this.selectedIndex = step;
    };
    FavoritesComponent.prototype.eventSpotSet = function (event) {
        this.countSpotSet = event;
    };
    return FavoritesComponent;
}());
export { FavoritesComponent };
