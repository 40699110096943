import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { RegisterService } from '@app/core/register.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { RemoveCreditsPopoverComponent } from '@app/search-navigation/components/remove-credits-popover';
import { PopoverController } from '@ionic/angular';
var ReviewMyPaymentComponent = /** @class */ (function () {
    function ReviewMyPaymentComponent(router, popoverCtrl, registerService, purchaseCreditsService, userActivityService) {
        this.router = router;
        this.popoverCtrl = popoverCtrl;
        this.registerService = registerService;
        this.purchaseCreditsService = purchaseCreditsService;
        this.userActivityService = userActivityService;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
    }
    ReviewMyPaymentComponent.prototype.ngOnInit = function () {
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.nonAdminInit();
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_MYPAYMENTS_PAGE);
    };
    ReviewMyPaymentComponent.prototype.nameChange = function (event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
            //this.getPayments();
            this.getLisitngPaymentActivity();
        }
    };
    ReviewMyPaymentComponent.prototype.checkAdmin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user.roles.includes('ROLE_ADMIN')) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    ReviewMyPaymentComponent.prototype.checkSpotAdmin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = JSON.parse(localStorage.getItem('user'));
                if (this.user.roles.includes('SPOT_ADMIN')) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    ReviewMyPaymentComponent.prototype.initUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.checkAdmin()];
                    case 1:
                        _a.admin = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.nonAdminInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, index, userIndex;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCompanies()];
                    case 1:
                        _a.allCompanies = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.checkAdmin()];
                    case 2:
                        _b.admin = _d.sent();
                        this.user = JSON.parse(localStorage.getItem('user'));
                        _c = this;
                        return [4 /*yield*/, this.getUsersByCompany(this.user.company.id)];
                    case 3:
                        _c.users = _d.sent();
                        index = this.allCompanies.findIndex(function (v) {
                            return v.id === _this.user.company.id;
                        });
                        userIndex = this.users.findIndex(function (v) {
                            return v.id === _this.user.id;
                        });
                        this.selectCompany = this.allCompanies[index];
                        this.offeredByUserId = this.users[userIndex];
                        //this.getPayments();
                        this.getLisitngPaymentActivity();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.portChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUsersByCompany($event.value.id)];
                    case 1:
                        _a.users = _b.sent();
                        this.agentComponent.clear();
                        this.offeredByUserId = null;
                        this.userCredits = [];
                        this.purchases = [];
                        this.listingActivities = [];
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    ReviewMyPaymentComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    ReviewMyPaymentComponent.prototype.getPayments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.purchaseCreditsService.getUserPayments(this.offeredByUserId.id)];
                    case 1:
                        _a.purchases = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.getLisitngPaymentActivity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.purchaseCreditsService.getUserListingPaymentActivity(this.offeredByUserId.id)];
                    case 1:
                        _a.listingActivities = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.navigateToPurchase = function () {
        this.router.navigateByUrl('/list-property');
    };
    ReviewMyPaymentComponent.prototype.navigateToMyListing = function () {
        this.router.navigateByUrl('/user-home/my-listings');
    };
    ReviewMyPaymentComponent.prototype.removeCredits = function (listingPurchaseId, availableCredits) {
        this.openRemoveCreditsPopover(listingPurchaseId, availableCredits);
    };
    ReviewMyPaymentComponent.prototype.openRemoveCreditsPopover = function (listingPurchaseId, availableCredits) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: RemoveCreditsPopoverComponent,
                            event: null,
                            cssClass: 'popover-remove-credits',
                            componentProps: { listingPurchaseId: listingPurchaseId, availableCredits: availableCredits },
                            backdropDismiss: false
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                _this.getLisitngPaymentActivity();
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ReviewMyPaymentComponent.prototype.navigateToCreate = function (id, premium) {
        if (this.selectCompany && this.offeredByUserId) {
            this.router.navigateByUrl('/user-home/my-listings/create-property', {
                state: {
                    orderId: id,
                    premium: premium,
                    company: this.selectCompany,
                    offeredByUserId: this.offeredByUserId,
                    date: new Date()
                },
                replaceUrl: true
            });
        }
    };
    ReviewMyPaymentComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    return ReviewMyPaymentComponent;
}());
export { ReviewMyPaymentComponent };
