import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListPropertyService } from '@app/core/listings';
import * as CartActions from '@app/store/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { I18nService } from '@app/core';
import { Location } from '@angular/common';
var ListCartComponent = /** @class */ (function () {
    function ListCartComponent(breakpointObserver, router, store, listPropertyService, alertCtrl, registerService, i18NService, _location) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.router = router;
        this.store = store;
        this.listPropertyService = listPropertyService;
        this.alertCtrl = alertCtrl;
        this.registerService = registerService;
        this.i18NService = i18NService;
        this._location = _location;
        this.isMobile = false;
        this.cart = [];
        this.typeSelection = [];
        this.totalPrice = 0;
        this.selectOptions = {
            cssClass: 'remove-ok'
        };
        this.upgrade = [];
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.initDropdowns();
        this.clearPurchaseSelectionState();
    }
    ListCartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.select(CartActions.GetItem).subscribe(function (store) {
            _this.cart = store.cart.items;
            _this.cart.forEach(function (item, index) {
                if (item.upgrade) {
                    _this.upgrade[index] = true;
                }
                else {
                    _this.upgrade[index] = false;
                }
            });
            _this.totalPrice = _this.updateTotalPrice();
        });
        this.paymentProcessedHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
        this.paymentProcessedSubHeader = this.i18NService.get('global.purchase-options.paymentProcessed');
        this.paymentProcessedOk = this.i18NService.get('global.purchase-options.paymentProcessedOK');
    };
    ListCartComponent.prototype.clearPurchaseSelectionState = function () {
        this.store.dispatch(CartActions.ClearSelection({ payload: { item: null } }));
    };
    ListCartComponent.prototype.nameChange = function (event) {
        if (event.value && event.value.id) {
            this.offeredByUserId = event.value;
        }
    };
    ListCartComponent.prototype.updateListing = function (premium, index) {
        var flag;
        if (premium) {
            flag = false;
        }
        else {
            flag = true;
        }
        var updatedItem = this.updateCartObject(flag, index);
        this.store.dispatch(CartActions.UpdateItem({ payload: { item: updatedItem, index: index } }));
    };
    ListCartComponent.prototype.updateTotalPrice = function () {
        var _this = this;
        this.totalPrice = 0;
        this.cart.forEach(function (item, index) {
            if (item.upgrade) {
                _this.totalPrice = _this.totalPrice = _this.totalPrice + item.premiumPrice * item.numOfListings;
            }
            else {
                _this.totalPrice = _this.totalPrice + item.listingPricePerMonth * item.numOfListings;
            }
        });
        this.listPropertyService.setCartTotalPrice(this.totalPrice);
        this.listPropertyService.setCartItems(this.cart);
        return this.totalPrice;
    };
    ListCartComponent.prototype.removeFromCart = function (id, index) {
        this.store.dispatch(CartActions.RemoveItem({ payload: { item: { id: id }, index: index } }));
    };
    ListCartComponent.prototype.updateCartObject = function (premium, index) {
        var obj = Object.assign({}, this.cart[index], { upgrade: premium });
        return obj;
    };
    ListCartComponent.prototype.skipPayment = function () {
        var _this = this;
        var price = this.listPropertyService.getCartTotalPrice() + '00';
        var cartElements = this.listPropertyService.getCartItems();
        var loginFormObject = {};
        loginFormObject.purchaseOptions = cartElements;
        loginFormObject.paymentInstallments = 1;
        loginFormObject.paymentAmount = 0;
        if (this.spotAdmin) {
            loginFormObject.userId = this.offeredByUserId ? this.offeredByUserId.id : null;
            loginFormObject.companyId = this.selectCompany.id;
        }
        this.listPropertyService
            .skipPaymentAPI(loginFormObject)
            .toPromise()
            .then(function (result) {
            _this.cart.forEach(function (item, index) {
                _this.removeFromCart(item.id, index);
            });
            _this.showPaymentMessage();
        });
    };
    ListCartComponent.prototype.navigateToPaymentPage = function () {
        var spotAdminSelection = {};
        if (this.selectCompany || this.offeredByUserId) {
            spotAdminSelection.companyId = this.selectCompany.id;
            spotAdminSelection.userId = this.offeredByUserId.id;
        }
        this.listPropertyService.setPaymentSpotAdminSelection(spotAdminSelection);
        this.router.navigateByUrl('/payment');
    };
    ListCartComponent.prototype.addListing = function () {
        this.router.navigateByUrl('/list-property');
    };
    ListCartComponent.prototype.goBack = function () {
        this.router.navigateByUrl('/list-property');
    };
    ListCartComponent.prototype.back = function () {
        this._location.back();
    };
    ListCartComponent.prototype.showPaymentMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this.paymentProcessedHeader,
                            subHeader: this.paymentProcessedSubHeader,
                            buttons: [this.paymentProcessedOk]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            _this.router.navigateByUrl('/user-home/my-listings');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListCartComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    ListCartComponent.prototype.initDropdowns = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, index, userIndex;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCompanies()];
                    case 1:
                        _a.allCompanies = _c.sent();
                        this.user = JSON.parse(localStorage.getItem('user'));
                        _b = this;
                        return [4 /*yield*/, this.getUsersByCompany(this.user.company.id)];
                    case 2:
                        _b.users = _c.sent();
                        index = this.allCompanies.findIndex(function (v) {
                            return v.id === _this.user.company.id;
                        });
                        userIndex = this.users.findIndex(function (v) {
                            return v.id === _this.user.id;
                        });
                        this.selectCompany = this.allCompanies[index];
                        this.offeredByUserId = this.users[userIndex];
                        return [2 /*return*/];
                }
            });
        });
    };
    ListCartComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    ListCartComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    ListCartComponent.prototype.portChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUsersByCompany($event.value.id)];
                    case 1:
                        _a.users = _b.sent();
                        this.agentComponent.clear();
                        this.offeredByUserId = null;
                        return [2 /*return*/];
                }
            });
        });
    };
    return ListCartComponent;
}());
export { ListCartComponent };
