import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListPropertyService } from '@app/core/listings/list-property.service';
var ListingProptypePopoverComponent = /** @class */ (function () {
    function ListingProptypePopoverComponent(navParams, popoverCtrl, listingService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this.btnFilter = false;
        this.loading = true;
    }
    ListingProptypePopoverComponent.prototype.ngOnInit = function () {
        this.getPopData();
        this.setMinOptions(this.rangeFilters, this.searchDto);
    };
    ListingProptypePopoverComponent.prototype.getPopData = function () {
        this.checkboxValues = this.navParams.data.checkboxValues;
        if (this.navParams.data.propType === '') {
            this.propertyType = null;
        }
        else {
            this.propertyType = this.navParams.data.propType;
            this.searchDto = this.navParams.data.searchDto;
        }
    };
    ListingProptypePopoverComponent.prototype.setMinOptions = function (filter, searchDto) {
        var _this = this;
        this.listingService
            .getMinMaxOptions(searchDto)
            .toPromise()
            .then(function (result) {
            localStorage.setItem('minMax', JSON.stringify(result));
            _this.staticMinMax = _this.listingService.setStaticMinMaxValues(result);
            _this.rangeModelValues = _this.listingService.mapMinMaxOptions(_this.searchDto, _this.staticMinMax);
            // if (Object.entries(this.searchDto).length === 0 && this.searchDto.constructor === Object!) {
            // } else {
            //   if (this.listingService.isRangeFilters(this.searchDto)) {
            //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
            //   } else {
            //     this.rangeModelValues = this.listingService.mapMinMaxOptions(this.rangeFilters, this.staticMinMax);
            //   }
            // }
            _this.loading = false;
        });
    };
    ListingProptypePopoverComponent.prototype.dismiss = function () {
        this.rangeFilters = this.listingService.setSearchRangeFilters(this.rangeModelValues, this.staticMinMax);
        var searchCopyDto = tslib_1.__assign({}, this.searchDto, this.rangeFilters);
        if (searchCopyDto.ceilingHeightMax === this.staticMinMax.ceilingHeight.max) {
            searchCopyDto.ceilingHeightMax = null;
        }
        if (searchCopyDto.offeringPriceEnd === this.staticMinMax.offeringPrice.max) {
            searchCopyDto.offeringPriceEnd = null;
        }
        if (searchCopyDto.offeringPricePerAreaEnd === this.staticMinMax.offeringPricePerArea.max) {
            searchCopyDto.offeringPricePerAreaEnd = null;
        }
        if (searchCopyDto.monthlyRentMax === this.staticMinMax.monthlyRent.max) {
            searchCopyDto.monthlyRentMax = null;
        }
        if (searchCopyDto.monthlyRentPerAreaMax === this.staticMinMax.monthlyRentPerArea.max) {
            searchCopyDto.monthlyRentPerAreaMax = null;
        }
        if (searchCopyDto.rentableAreaEnd === this.staticMinMax.rentableArea.max) {
            searchCopyDto.rentableAreaEnd = null;
        }
        this.popoverCtrl.dismiss(searchCopyDto);
    };
    ListingProptypePopoverComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    ListingProptypePopoverComponent.prototype.clearSearch = function () {
        this.searchDto = this.listingService.clearFilters(this.searchDto, this.checkboxValues);
        this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
    };
    ListingProptypePopoverComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    };
    return ListingProptypePopoverComponent;
}());
export { ListingProptypePopoverComponent };
