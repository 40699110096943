import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { CommonGenericService } from '@app/core/common.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UpdateEmailModalComponent } from '../update-email/update-email-modal.component';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { UpdatePasswordModalComponent } from '../update-password/update-password-modal.component';
import { EditImageModalComponent } from '../edit-image/edit-image-modal.component';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { ImageService } from '@app/core/image.service';
import { I18nService } from '@app/core';
var MyAccountComponent = /** @class */ (function () {
    function MyAccountComponent(auth, commonService, router, userAccountService, userHomeService, userActivityService, imageService, i18nService) {
        this.auth = auth;
        this.commonService = commonService;
        this.router = router;
        this.userAccountService = userAccountService;
        this.userActivityService = userActivityService;
        this.imageService = imageService;
        this.i18nService = i18nService;
        this.isMobile = false;
        this.advancedSettingsClass = 'collapse';
        this.expandAdvancedSettings = false;
        this.imagePlaceholder = 'assets/images/default-profile-photo.png';
        this.intlTelephoneOptions = this.commonService.getDefaultIntlPhoneProp();
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.noValidate = false;
        this.logoId = null;
        this.userHomeService = userHomeService;
        this.userProfileForm = this.userHomeService.createUserProfileForm();
    }
    MyAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileLogoSubscription = this.imageService.getLogoId().subscribe(function (val) {
            _this.logoId = val;
            if (_this.logoId != null && _this.auth.$isLoginSubject.value) {
                _this.saveUserAccount(_this.logoId);
            }
        });
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_PROFILE_PAGE);
        this.profileImageSubscription = this.userHomeService.profileImage$.subscribe(function (image) {
            _this.profileImage = image;
        });
        this.getUserProfile();
        this.getLanguage = this.commonService.getLanguage;
        this.multiLangOptions = this.userHomeService.getMultiLangOption();
    };
    MyAccountComponent.prototype.ngOnDestroy = function () {
        this.profileImageSubscription.unsubscribe();
        this.profileLogoSubscription.unsubscribe();
    };
    MyAccountComponent.prototype.initUserProfileForm = function () {
        this.userBiographyValue = this.userAccountInfo.biography ? JSON.parse(this.userAccountInfo.biography) : undefined;
        this.userBiographyValueInitial = this.userAccountInfo.biography
            ? JSON.parse(this.userAccountInfo.biography)
            : undefined;
        this.setUserProfileFormValue('profileInfo', 'jobTitle', this.userAccountInfo.jobTitle);
        this.setUserProfileFormValue('userName', 'firstName', this.userAccountInfo.firstName);
        this.setUserProfileFormValue('userName', 'lastName', this.userAccountInfo.lastName);
        this.setUserProfileFormValue('location', 'address', this.userAccountInfo.address);
        this.setUserProfileFormValue('location', 'city', this.userAccountInfo.city);
        this.setUserProfileFormValue('location', 'state', this.userAccountInfo.state);
        this.setUserProfileFormValue('location', 'postalCode', this.userAccountInfo.postalCode);
        this.setUserProfileFormValue('location', 'country', this.userAccountInfo.country);
        this.setUserProfileFormValue('location', 'company', this.userAccountInfo.company.name);
        this.setUserProfileFormValue('advancedSettings', 'excludeFromCommunity', this.userAccountInfo.excludeFromCommunity);
        this.setUserProfileFormValue('advancedSettings', 'emailNotification', this.userAccountInfo.emailNotification);
        this.setUserProfileFormValue('advancedSettings', 'excludeFromCommunityEmails', this.userAccountInfo.excludeFromCommunityEmails);
        this.setUserProfileFormValue('contact', 'primaryPhone', this.userHomeService.getContactNumber(this.userAccountInfo, 'PHONE_NUMBER'));
        this.setUserProfileFormValue('contact', 'whatsApp', this.userHomeService.getContactNumber(this.userAccountInfo, 'WHATSAPP'));
    };
    MyAccountComponent.prototype.setUserProfileFormValue = function (groupName, fieldName, fieldValue) {
        var formFieldValue = fieldValue !== null || fieldName !== 'undefined' ? fieldValue : '';
        this.userProfileForm
            .get(groupName)
            .get(fieldName)
            .setValue(formFieldValue);
    };
    MyAccountComponent.prototype.getCurrentImageProfile = function (prop) {
        var userAccountInfo = this.userHomeService.setUserAccountProp(this.userAccountInfo, prop);
        var newProfileImage = this.userHomeService.setProfileImageProp(prop);
        this.userHomeService.profileImage$.next(newProfileImage);
        // this.userAccountService.currentUser$.next(userAccountInfo);
    };
    MyAccountComponent.prototype.getUserProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userPhotos, image, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.userAccountService.getUserPhotoList().toPromise()];
                    case 1:
                        userPhotos = _b.sent();
                        image = this.userAccountService.getUploadedImageId(userPhotos);
                        _a = this;
                        return [4 /*yield*/, this.userAccountService.getAccount().toPromise()];
                    case 2:
                        _a.userAccountInfo = _b.sent();
                        this.userHomeService.getContactCountriesISO(this.userAccountInfo.phones);
                        this.profileImage = this.userHomeService.setProfileImageProp(image);
                        this.initUserProfileForm();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyAccountComponent.prototype.getMediumImg = function (logoId) {
        if (logoId === void 0) { logoId = null; }
        return this.imageService.mediumThumbnail(logoId);
    };
    MyAccountComponent.prototype.revomeLogo = function () {
        this.saveUserAccount('');
        this.imageService.setLogoId(null);
    };
    MyAccountComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) {
                            if (result.data.action === 'changeEmailSuccess' || result.data.action === 'changePasswordSuccess') {
                                _this.auth.logout();
                            }
                            else if (result.data.action === 'updateUserProfile') {
                                _this.getMostCurrentProfile();
                            }
                            else if (result.data.action === 'updateProfileImageSuccess') {
                                _this.getCurrentImageProfile(result.data.extraData);
                            }
                        });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    MyAccountComponent.prototype.getMostCurrentProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userAccount, userAccountInfo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userAccountService.getAccount().toPromise()];
                    case 1:
                        userAccount = _a.sent();
                        userAccountInfo = {
                            profile: userAccount,
                            profileImage: this.profileImage.croppedImage,
                            originalImage: this.profileImage.originalImage
                        };
                        // this.commonService.updateLocalStorageData('userProfile', userAccount);
                        // this.userAccountService.currentUser$.next(userAccountInfo);
                        this.userProfileForm.reset();
                        this.goBackToUserHome();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyAccountComponent.prototype.openUpdateEmailModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, updateEmailModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            auth: this.auth,
                            commonService: this.commonService,
                            currentEmail: this.userAccountInfo.login,
                            openInfoModal: this.openInfoModal,
                            userAccountService: this.userAccountService,
                            userHomeService: this.userHomeService
                        };
                        cssClass = 'change-email-modal';
                        modalProps = this.userHomeService.getModalProp(UpdateEmailModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        updateEmailModal = _a.sent();
                        return [4 /*yield*/, updateEmailModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MyAccountComponent.prototype.openUpdatePasswordModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, updatePasswordModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            auth: this.auth,
                            commonService: this.commonService,
                            login: this.userAccountInfo.login,
                            openInfoModal: this.openInfoModal,
                            userHomeService: this.userHomeService,
                            userAccountService: this.userAccountService
                        };
                        cssClass = 'change-password-modal';
                        modalProps = this.userHomeService.getModalProp(UpdatePasswordModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        updatePasswordModal = _a.sent();
                        return [4 /*yield*/, updatePasswordModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MyAccountComponent.prototype.openEditImageModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, editImageModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            commonService: this.commonService,
                            getCurrentImageProfile: this.getCurrentImageProfile,
                            login: this.userAccountInfo.login,
                            profileImage: this.profileImage,
                            openInfoModal: this.openInfoModal,
                            profileImageUrl: this.profileImage.originalImage,
                            userAccountService: this.userAccountService,
                            userAccountInfo: this.userAccountInfo,
                            userHomeService: this.userHomeService
                        };
                        cssClass = 'edit-image-modal';
                        modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MyAccountComponent.prototype.openEditLogoModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userlogo, imageLogo, componentProps, cssClass, modalProps, editImageModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userlogo = [];
                        if (this.logoId) {
                            userlogo.push(this.logoId);
                        }
                        else {
                            userlogo.push(this.userAccountInfo.companyLogoImageId != undefined ? this.userAccountInfo.companyLogoImageId : null);
                        }
                        imageLogo = this.userAccountService.getUploadedImageId(userlogo);
                        this.imageLogos = this.userHomeService.setProfileImageProp(imageLogo);
                        componentProps = {
                            commonService: this.commonService,
                            getCurrentImageProfile: this.getCurrentImageProfile,
                            login: this.userAccountInfo.login,
                            profileImage: this.imageLogos,
                            openInfoModal: this.openInfoModal,
                            profileImageUrl: this.imageLogos.originalImage,
                            userAccountService: this.userAccountService,
                            userAccountInfo: this.userAccountInfo,
                            userHomeService: this.userHomeService,
                            editLogo: true
                        };
                        cssClass = 'edit-image-modal';
                        modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Object.defineProperty(MyAccountComponent.prototype, "biographyFormArray", {
        get: function () {
            return this.userProfileForm.get('profileInfo').get('biography');
        },
        enumerable: true,
        configurable: true
    });
    MyAccountComponent.prototype.canDeactivate = function () {
        if (this.noValidate) {
            // only when saving
            this.noValidate = false;
            return true;
        }
        var biographyList = this.userProfileForm.value.profileInfo.biography;
        var biography = biographyList.reduce(function (accumulator, item) {
            accumulator = Object.assign(accumulator, item);
            return accumulator;
        }, {});
        var bio = JSON.stringify(biography);
        return !this.userProfileForm.touched && (bio == this.userAccountInfo.biography || !this.userAccountInfo.biography);
    };
    MyAccountComponent.prototype.validateWhatsAppNumber = function () {
        var whatsAppControl = this.userProfileForm.get('contact').get('whatsApp');
        if (whatsAppControl.value == null) {
            return true;
        }
        if (whatsAppControl.errors || whatsAppControl.value.internationalNumber.length === 0) {
            var headerLabel = 'global.user.editUserProfile';
            var bodyMessage = 'global.user.profileForm.invalidNumber';
            if ((whatsAppControl.errors && whatsAppControl.errors.invalidNumberForRegion) ||
                whatsAppControl.value.internationalNumber.length === 0) {
                bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
            }
            this.openInfoModal(headerLabel, bodyMessage);
            return false;
        }
        return true;
    };
    MyAccountComponent.prototype.isFormInvalid = function () {
        var primaryPhoneControl = this.userProfileForm.get('contact').get('primaryPhone');
        var jobTitle = this.userProfileForm.get('profileInfo').get('jobTitle');
        var userNameControl = this.userProfileForm.get('userName');
        var jobTitleErrors = jobTitle.errors ? jobTitle.errors.required : false;
        var userNameErrors = userNameControl.invalid;
        return primaryPhoneControl.value == null || jobTitleErrors || userNameErrors;
    };
    MyAccountComponent.prototype.saveUserAccount = function (logoId) {
        var _this = this;
        if (logoId === void 0) { logoId = null; }
        if (logoId || logoId == '') {
            this.noValidate = true;
            this.userAccountInfo.companyLogoImageId = logoId == '' ? null : logoId;
            var santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
            var userProfilePayload = this.userHomeService.sanitizeUserProfile(this.userAccountInfo, santizedRawFormData);
            this.userAccountService.saveUserProfile(userProfilePayload).subscribe(function () {
                _this.getUserProfile();
            });
        }
        else {
            var isFormInvalid = this.isFormInvalid();
            if (!isFormInvalid && this.validateWhatsAppNumber()) {
                this.noValidate = true;
                this.userAccountInfo.companyLogoImageId = this.logoId;
                var santizedRawFormData = this.userHomeService.santizeUserProfileForm(this.userProfileForm);
                var userProfilePayload = this.userHomeService.sanitizeUserProfile(this.userAccountInfo, santizedRawFormData);
                this.userAccountService.saveUserProfile(userProfilePayload).subscribe(function () {
                    var headerLabel = 'global.user.editUserProfile';
                    var bodyMessage = 'global.user.message.userProfileUpdateSuccessfully';
                    var action = 'updateUserProfile';
                    _this.openInfoModal(headerLabel, bodyMessage, action);
                });
            }
        }
    };
    MyAccountComponent.prototype.goBackToUserHome = function () {
        this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
    };
    MyAccountComponent.prototype.expandCollapseAdvSettings = function () {
        this.expandAdvancedSettings = !this.expandAdvancedSettings;
        this.advancedSettingsClass = this.expandAdvancedSettings ? 'expand' : 'collapse';
    };
    MyAccountComponent.prototype.setSubmitButtonClass = function () {
        var isInvalidForm = this.isFormInvalid();
        return isInvalidForm ? 'non-mobile-btn-disabled mobile-btn-disabled' : 'none-mobile-btn mobile-btn';
    };
    return MyAccountComponent;
}());
export { MyAccountComponent };
