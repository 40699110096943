import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CommonGenericService } from '@app/core/common.service';
var MoreDetailsComponent = /** @class */ (function () {
    function MoreDetailsComponent(registerService, commonService) {
        this.registerService = registerService;
        this.commonService = commonService;
        this.offeredByUserApi = 'api/users/allOfSameCompany';
        this.selectedDescLanguage = [];
        this.selectedTitleLanguage = [];
        this.basicListingType = false;
    }
    MoreDetailsComponent.prototype.ngOnInit = function () {
        this.multiLangInit();
        this.listing ? this.initExistingValues() : null;
        this.nonAdminInit();
    };
    MoreDetailsComponent.prototype.nonAdminInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, userIndex;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.checkRole('ROLE_SPOT_ADMIN')];
                    case 1:
                        _a.spotAdmin = _e.sent();
                        _b = this;
                        return [4 /*yield*/, this.checkRole('ROLE_LISTING_ADMIN')];
                    case 2:
                        _b.listingAdmin = _e.sent();
                        _c = this;
                        return [4 /*yield*/, this.commonService.getLanguageOption()];
                    case 3:
                        _c.languages = _e.sent();
                        //this.allCompanies = await this.getCompanies();
                        this.user = JSON.parse(localStorage.getItem('user'));
                        if (this.listing && this.listing.listingType && this.listing.listingType === 'BASIC') {
                            this.basicListingType = true;
                            this.moreDetailsForm.controls['propertyManagementCompany'].setValidators([Validators.required]);
                        }
                        if (!this.selectCompany) return [3 /*break*/, 5];
                        _d = this;
                        return [4 /*yield*/, this.getUsersByCompany(this.selectCompany.id)];
                    case 4:
                        _d.users = _e.sent();
                        _e.label = 5;
                    case 5:
                        if (!this.offeredByUserId) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.users.findIndex(function (v) {
                                return v.id === _this.offeredByUserId.id;
                            })];
                    case 6:
                        userIndex = _e.sent();
                        this.moreDetailsForm.controls['offeredByUserId'].setValue(this.users[userIndex]);
                        _e.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MoreDetailsComponent.prototype.initExistingValues = function () {
        if (this.listing.title) {
            if (this.isJsonString(this.listing.title)) {
                this.existingTitle = JSON.parse(this.listing.title);
            }
            else {
                this.existingTitle = { en: this.listing.title };
            }
        }
        if (this.listing.comments) {
            if (this.isJsonString(this.listing.comments)) {
                this.existingComments = JSON.parse(this.listing.comments);
            }
            else {
                this.existingComments = { en: this.listing.comments };
            }
        }
    };
    MoreDetailsComponent.prototype.isJsonString = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    MoreDetailsComponent.prototype.titleChange = function (value) {
        var _this = this;
        if (value.title.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.title).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.title[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1
                ? this.moreDetailsForm.controls['title'].setValue(null)
                : this.moreDetailsForm.controls['title'].setValue(JSON.stringify(mergedObject_1));
        }
        else {
            this.moreDetailsForm.controls['title'].setValue(null);
        }
    };
    MoreDetailsComponent.prototype.descriptionChange = function (value) {
        var _this = this;
        if (value.description.length > 0) {
            var mergedObject_2 = {};
            var nullValues_2 = false;
            Object.keys(value.description).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.description[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_2 = true;
                    }
                    else {
                        mergedObject_2 = Object.assign(mergedObject_2, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_2
                ? this.moreDetailsForm.controls['description'].setValue(null)
                : this.moreDetailsForm.controls['description'].setValue(JSON.stringify(mergedObject_2));
        }
        else {
            this.moreDetailsForm.controls['description'].setValue(null);
        }
    };
    MoreDetailsComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    MoreDetailsComponent.prototype.multiLangInit = function () {
        this.titleFieldOptions = {
            columnLabel: 'global.registerSpot.title',
            element: { input: true },
            formArrayName: 'title',
            required: true
        };
        this.descriptionFieldOptions = {
            columnLabel: 'global.registerSpot.description',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
    };
    MoreDetailsComponent.prototype.nameChange = function ($event) {
        this.moreDetailsForm.controls['offeredByUserId'].setValue($event.value);
    };
    MoreDetailsComponent.prototype.portChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUsersByCompany($event.value.id)];
                    case 1:
                        _a.users = _b.sent();
                        this.agentComponent.clear();
                        return [2 /*return*/];
                }
            });
        });
    };
    MoreDetailsComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    MoreDetailsComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    MoreDetailsComponent.prototype.onSelectChange = function (selectedValue) {
        this.tagValues = selectedValue.detail.value;
        this.moreDetailsForm.controls.tags.setValue(this.tagValues);
    };
    return MoreDetailsComponent;
}());
export { MoreDetailsComponent };
