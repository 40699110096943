import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import * as i0 from "@angular/core";
var LanguageService = /** @class */ (function () {
    function LanguageService() {
    }
    Object.defineProperty(LanguageService.prototype, "locale", {
        get: function () {
            return this._locale || 'en-US';
        },
        set: function (value) {
            this._locale = value;
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.registerCulture = function (culture) {
        if (!culture) {
            return;
        }
        // Register locale data since only the en-US locale data comes with Angular
        switch (culture) {
            case 'pt-BR': {
                registerLocaleData(localePT);
                break;
            }
        }
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
