import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Logger } from './logger.service';
import enJsonFile from '../../translations/en-US.json';
import countryJSONFile from '../../translations/pt-BR.json';
import { LanguageService } from './language.service';
import { environment } from '@env/environment';
import { ReixSpotLangs, ReixLangCulture, ReixSpotLanguagesService } from './reixspotlanguages.service';
var log = new Logger('I18nService');
var languageKey = 'language';
/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s) {
    return s;
}
var I18nService = /** @class */ (function () {
    function I18nService(translateService, languageSvc, reixSpotLangSrv) {
        var _this = this;
        this.translateService = translateService;
        this.languageSvc = languageSvc;
        this.reixSpotLangSrv = reixSpotLangSrv;
        this.supportedLanguagesByURL = [];
        this.isLangEN = function () { return _this.isCultureEN; };
        this.getDefaultLang = function () { return ReixSpotLangs.COUNTRY_LANG.toString(); };
        // Embed languages to avoid extra HTTP requests
        translateService.setTranslation(ReixLangCulture.EN, enJsonFile);
        translateService.setTranslation(ReixLangCulture.COUNTRY_LANG, countryJSONFile);
        this.supportedLanguagesByURL = [ReixSpotLangs.COUNTRY_LANG, ReixSpotLangs.EN];
        this.enURLPath = "/" + ReixSpotLangs.EN;
        this.countryURLPath = "/" + ReixSpotLangs.COUNTRY_LANG;
        this.translateService.onLangChange.subscribe(function (response) {
            _this.currentLanguage = _this.getLangBasedSPOTLanguage(response.lang);
            _this.isCultureEN = response.lang == ReixLangCulture.EN;
        });
    }
    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param defaultLanguage The default language to use.
     * @param supportedLanguages The list of supported languages.
     */
    I18nService.prototype.init = function (defaultLanguage, supportedLanguages) {
        var _this = this;
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = ReixLangCulture.COUNTRY_LANG;
        // Warning: this subscription will always be alive for the app's lifetime
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(function (event) {
            sessionStorage.setItem(languageKey, event.lang);
            _this.languageSvc.locale = event.lang;
        });
    };
    /**
     * Cleans up language change subscription.
     */
    I18nService.prototype.destroy = function () {
        this.langChangeSubscription.unsubscribe();
    };
    Object.defineProperty(I18nService.prototype, "language", {
        /**
         * Gets the current language.
         * @return The current language code.
         */
        get: function () {
            return this.translateService.currentLang;
        },
        /**
         * Sets the current language.
         * Note: The current language is saved to the session storage.
         * If no parameter is specified, the language is loaded from session storage (if present).
         * @param language The IETF language code to set.
         */
        set: function (language) {
            language = language || sessionStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
            var isSupportedLanguage = this.supportedLanguages.includes(language);
            // If no exact match is found, search without the region
            if (language && !isSupportedLanguage) {
                language = language.split('-')[0];
                language = this.supportedLanguages.find(function (supportedLanguage) { return supportedLanguage.startsWith(language); }) || '';
                isSupportedLanguage = Boolean(language);
            }
            // Fallback if language is not supported
            if (!isSupportedLanguage) {
                language = this.defaultLanguage;
            }
            log.debug("Language set to " + language + " - " + this.getCurrentLanguage());
            this.languageSvc.locale = language;
            this.translateService.use(language);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Gets the current language.
     * @return The current language code.
     */
    I18nService.prototype.getTranslation = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                // Using the default language in lower case in order to get the pt-br values defined in some JSON objects.
                var defaultLanguage = this.getCurrentLanguage();
                var newValue = JSON.parse(textValue)[defaultLanguage.toLowerCase()];
                return newValue ? newValue : JSON.parse(textValue)[Object.keys(JSON.parse(textValue))[0]];
            }
            else {
                return '';
            }
        }
        catch (e) {
            //console.error('failed to parse locale string', textValue, this.getCurrentLanguage());
        }
        return textValue;
    };
    /**
     * Get the value defined on the translations JSON files.
     * @param translationKey
     */
    I18nService.prototype.get = function (translationKey, interpolateParams) {
        var result = '';
        this.translateService.get(translationKey, interpolateParams).subscribe(function (data) {
            result = data;
        });
        return result;
    };
    I18nService.prototype.setLanguageBasedOnURL = function (url) {
        var urlParts = url.split('/');
        var lang = urlParts[urlParts.length - 1];
        var indexOfQueryParams = lang.indexOf('?');
        if (indexOfQueryParams != -1) {
            lang = lang.substr(0, indexOfQueryParams);
        }
        if (lang.toLocaleLowerCase() === ReixSpotLangs.COUNTRY_LANG &&
            this.language &&
            this.language === ReixLangCulture.COUNTRY_LANG) {
            document.documentElement.setAttribute('lang', 'pt');
            return;
        }
        if (lang.toLocaleLowerCase() === ReixSpotLangs.EN && this.language && this.language === ReixLangCulture.EN) {
            document.documentElement.setAttribute('lang', ReixSpotLangs.EN);
            return;
        }
        switch (lang.toLowerCase()) {
            case ReixSpotLangs.COUNTRY_LANG:
                this.language = ReixLangCulture.COUNTRY_LANG;
                document.documentElement.setAttribute('lang', 'pt');
                break;
            case ReixSpotLangs.EN:
                this.language = ReixLangCulture.EN;
                document.documentElement.setAttribute('lang', ReixSpotLangs.EN);
                break;
            default:
                this.language = null;
                break;
        }
    };
    I18nService.prototype.isLanguageParam = function (requestParam) {
        return this.supportedLanguagesByURL.includes(requestParam);
    };
    I18nService.prototype.removeLanguageFromURL = function (url) {
        var newUrl = url;
        var urlParams = url.split('/');
        if (urlParams.length >= 2) {
            var langParam = urlParams[urlParams.length - 1];
            if (this.isLanguageParam(langParam)) {
                newUrl = urlParams.slice(0, urlParams.length - 1).join('/');
            }
        }
        return newUrl;
    };
    I18nService.prototype.getLanguagePathURL = function () {
        var currentURL = window.location.href;
        var urlParams = currentURL.split('/');
        if (urlParams.length >= 2) {
            var langParam = urlParams[urlParams.length - 1];
            if (this.isLanguageParam(langParam)) {
                return langParam;
            }
        }
        return this.getDefaultLang();
    };
    I18nService.prototype.getHomeSPOTURL = function () {
        if (this.language === ReixLangCulture.EN) {
            return this.enURLPath;
        }
        else if (this.language === ReixLangCulture.COUNTRY_LANG) {
            return this.countryURLPath;
        }
        return '/';
    };
    I18nService.prototype.concatLangParamBasedOnURL = function (url) {
        if (!this.hasURLLangPathParam(url)) {
            return this.concatLanguagePathURL(url);
        }
        return url;
    };
    I18nService.prototype.hasURLLangPathParam = function (url) {
        var spotURI = this.separateURIAndQueryParams(url);
        var currentURL = spotURI.url;
        var urlParams = currentURL.split('/');
        if (urlParams.length >= 2) {
            var langParam = urlParams[urlParams.length - 1];
            if (this.isLanguageParam(langParam)) {
                return true;
            }
        }
        return false;
    };
    I18nService.prototype.currentURLHasLangParam = function () {
        return this.hasURLLangPathParam(window.location.href);
    };
    I18nService.prototype.concatLanguagePathURL = function (currentPath) {
        var path = this.adjustLastSlash(currentPath);
        if (this.language === ReixLangCulture.EN) {
            path = path.concat(this.enURLPath);
        }
        else if (this.language === ReixLangCulture.COUNTRY_LANG) {
            path = path.concat(this.countryURLPath);
        }
        return path;
    };
    I18nService.prototype.adjustLastSlash = function (currentPath) {
        var path = currentPath;
        if (path == null || path === undefined) {
            return path;
        }
        if (path.endsWith('/')) {
            path = path.slice(0, path.length - 1);
        }
        return path;
    };
    I18nService.prototype.currentUrlContainsCurrentLanguage = function () {
        var currentURL = window.location.href;
        var urlParams = currentURL.split('/');
        if (urlParams.length >= 2) {
            var langParam = urlParams[urlParams.length - 1];
            if (this.language === ReixLangCulture.EN && langParam === ReixSpotLangs.EN) {
                return true;
            }
            else if (this.language === ReixLangCulture.COUNTRY_LANG && langParam === ReixSpotLangs.COUNTRY_LANG) {
                return true;
            }
        }
        return false;
    };
    /**
     * Get the current language defined for the user
     * It returns en, pt-br
     */
    I18nService.prototype.getCurrentLanguage = function () {
        if (this.currentLanguage === undefined) {
            var currentLanguageStorage = environment.defaultLanguage;
            if (currentLanguageStorage) {
                return this.getLangBasedSPOTLanguage(currentLanguageStorage);
            }
            else {
                var locale = this.languageSvc.locale;
                return this.getLangBasedSPOTLanguage(locale);
            }
        }
        return this.currentLanguage;
    };
    I18nService.prototype.getCurrentLocale = function () {
        return this.getLocaleBasedSPOTLanguage(this.getCurrentLanguage());
    };
    I18nService.prototype.getLangBasedSPOTLanguage = function (language) {
        if (language === ReixLangCulture.EN) {
            return ReixSpotLangs.EN;
        }
        else if (language === ReixLangCulture.COUNTRY_LANG) {
            return ReixSpotLangs.COUNTRY_LANG;
        }
        return language;
    };
    I18nService.prototype.getLocaleBasedSPOTLanguage = function (language) {
        if (language === ReixSpotLangs.EN) {
            return ReixLangCulture.EN;
        }
        else if (language === ReixSpotLangs.COUNTRY_LANG) {
            return ReixLangCulture.COUNTRY_LANG;
        }
        return language;
    };
    I18nService.prototype.getTranslationInTargetLanguage = function (key, language, interpolateParams) {
        var currentLanguage = this.translateService.currentLang;
        this.translateService.currentLang = language;
        var translation = this.translateService.instant(key, interpolateParams);
        this.translateService.currentLang = currentLanguage;
        return translation;
    };
    /**
     *
     * @param translationKey
     */
    I18nService.prototype.getMetatagTranslation = function (translationKey, interpolateParams) {
        if (this.currentUrlContainsCurrentLanguage()) {
            return this.get(translationKey, interpolateParams);
        }
        else {
            var result = this.getTranslationInTargetLanguage(translationKey, this.getCurrentLanguage(), interpolateParams);
            return result;
        }
    };
    I18nService.prototype.addCurrentLanguageToPath = function (currentPath) {
        var spotURI = this.separateURIAndQueryParams(currentPath);
        var url = spotURI.url;
        var queryParams = spotURI.queryParams;
        var newURL = this.concatLanguagePathURL(this.removeLanguageFromURL(url));
        if (queryParams) {
            newURL = newURL.concat(queryParams);
        }
        return newURL;
    };
    I18nService.prototype.addDefaultLanguageToPath = function (currentPath) {
        if (this.hasURLLangPathParam(currentPath)) {
            return currentPath;
        }
        var spotURI = this.separateURIAndQueryParams(currentPath);
        var url = spotURI.url;
        var queryParams = spotURI.queryParams;
        var newURL = this.adjustLastSlash(this.removeLanguageFromURL(url));
        newURL = newURL.concat(this.countryURLPath);
        if (queryParams) {
            newURL = newURL.concat(queryParams);
        }
        return newURL;
    };
    I18nService.prototype.separateURIAndQueryParams = function (currentPath) {
        var indexQueryParam = currentPath.indexOf('?');
        var url = currentPath;
        var queryParams = null;
        if (indexQueryParam != -1) {
            url = currentPath.substring(0, indexQueryParam);
            queryParams = currentPath.substring(indexQueryParam, currentPath.length);
        }
        return { url: url, queryParams: queryParams };
    };
    return I18nService;
}());
export { I18nService };
