import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService, LanguageService } from '@app/core';
import { ListingPriceService } from '@app/core/listings';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { SpotBuildingsUtilService } from '@app/spot-buildings/util/spot-buildings-util.service';
import { validateURL } from '@app/shared/util/url-validator';
var SpotBuildingsFeaturesComponent = /** @class */ (function () {
    function SpotBuildingsFeaturesComponent(spotBuildingsUtilService, langService, listingPriceService, createSpotBuildingsService, i18NService) {
        this.spotBuildingsUtilService = spotBuildingsUtilService;
        this.langService = langService;
        this.listingPriceService = listingPriceService;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.i18NService = i18NService;
        this.allConstructionStatus = [];
        this.update = 0;
        this.videoURLInvalid = false;
    }
    SpotBuildingsFeaturesComponent.prototype.ngOnInit = function () {
        this.getAmenitiesBuildings();
        this.getIndustrialStatus();
        this.multiLangInit();
    };
    SpotBuildingsFeaturesComponent.prototype.ngOnChanges = function (changes) {
        this.getAmenitiesBuildings();
        if (this.buildingDetail && this.update == 0) {
            this.update++;
            this.initValuesUpdate();
        }
    };
    SpotBuildingsFeaturesComponent.prototype.checkDate = function (ev) { };
    SpotBuildingsFeaturesComponent.prototype.multiLangInit = function () {
        this.InvestmentFieldOptions = {
            columnLabel: 'global.list-your-property.investmentOpportunity',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
    };
    SpotBuildingsFeaturesComponent.prototype.initValuesUpdate = function () {
        this.spotBuildings.rentableArea = this.buildingDetail.rentableArea;
        this.spotBuildings.averageFloorPrivateArea = this.buildingDetail.averageFloorPrivateArea;
        this.spotBuildings.ceilingHeight = this.buildingDetail.ceilingHeight;
        this.spotBuildings.yearBuilt = this.buildingDetail.yearBuilt;
        this.spotBuildings.yearRenovated = this.buildingDetail.yearRenovated;
        this.spotBuildings.numberStories = this.buildingDetail.numberStories;
        this.spotBuildings.carParkingSpaces = this.buildingDetail.carParkingSpaces;
        this.spotBuildings.parkingSpaces = this.buildingDetail.parkingSpaces;
        this.spotBuildings.landArea = this.buildingDetail.landArea;
        this.spotBuildings.truckParkingSpaces = this.buildingDetail.truckParkingSpaces;
        this.spotBuildings.numberOfBuildings = this.buildingDetail.numberOfBuildings;
        this.spotBuildings.investmentOpportunity = this.buildingDetail.investmentOpportunity;
        this.spotBuildings.majorTenants = this.buildingDetail.majorTenants;
        this.spotBuildings.capRate = this.buildingDetail.capRate;
        this.spotBuildings.percentageLeased = this.buildingDetail.percentageLeased;
        this.vrTourUrl = this.buildingDetail.vrTourUrl ? this.buildingDetail.vrTourUrl : null;
        this.spotBuildings.vrTourUrl = this.vrTourUrl;
        this.spotBuildings.area = this.buildingDetail.area;
        this.spotBuildings.askingPrice = this.buildingDetail.askingPrice;
        this.spotBuildings.docks = this.buildingDetail.docks;
        this.spotBuildings.loadCapacity = this.buildingDetail.loadCapacity;
        this.spotBuildings.currentOccupancy = this.buildingDetail.currentOccupancy;
        this.spotBuildings.startDate = this.buildingDetail.startDate;
        this.spotBuildings.endDate = this.buildingDetail.endDate;
        this.spotBuildings.revenue = this.buildingDetail.revenue;
        this.spotBuildings.lastMonthlyMeeting = this.buildingDetail.lastMonthlyMeeting;
        if (this.buildingDetail.industrialStatus) {
            this.spotBuildings.industrialStatus = this.buildingDetail.industrialStatus;
            this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(this.buildingDetail.industrialStatus.name);
        }
        this.selectContructionStatus = this.spotBuildings.industrialStatus;
        this.existingInvestment = this.buildingDetail.investmentOpportunity;
        //init features
        this.spotBuildings.twentyFourHourSecurity = this.buildingDetail.twentyFourHourSecurity;
        this.spotBuildings.electricGenerator = this.buildingDetail.electricGenerator;
        this.spotBuildings.heliport = this.buildingDetail.heliport;
        this.spotBuildings.sprinklers = this.buildingDetail.sprinklers;
        this.spotBuildings.leedStatus = this.buildingDetail.leedStatus;
        this.spotBuildings.singleTenant = this.buildingDetail.singleTenant;
        this.spotBuildings.multiTenant = this.buildingDetail.multiTenant;
        this.spotBuildings.bicycleRack = this.buildingDetail.bicycleRack;
        this.spotBuildings.fiberOptic = this.buildingDetail.fiberOptic;
        this.spotBuildings.centralAirConditioning = this.buildingDetail.centralAirConditioning;
        this.spotBuildings.petFriendly = this.buildingDetail.petFriendly;
        this.spotBuildings.truckYard = this.buildingDetail.truckYard;
        this.spotBuildings.crossDocking = this.buildingDetail.crossDocking;
        this.spotBuildings.sluiceGate = this.buildingDetail.sluiceGate;
        this.spotBuildings.blockAndSteelConstruction = this.buildingDetail.blockAndSteelConstruction;
        this.spotBuildings.blockConstruction = this.buildingDetail.blockConstruction;
        this.spotBuildings.steelConstruction = this.buildingDetail.steelConstruction;
        this.spotBuildings.includesOffice = this.buildingDetail.includesOffice;
        this.spotBuildings.includesRetail = this.buildingDetail.includesRetail;
        this.spotBuildings.coldStorage = this.buildingDetail.coldStorage;
        this.spotBuildings.fullBuildOut = this.buildingDetail.fullBuildOut;
        this.spotBuildings.skyLights = this.buildingDetail.skyLights;
        this.spotBuildings.bulletProofCabin = this.buildingDetail.bulletProofCabin;
        this.spotBuildings.mailPackageHandling = this.buildingDetail.mailPackageHandling;
        this.spotBuildings.enhancedCleaning = this.buildingDetail.enhancedCleaning;
        this.spotBuildings.barista = this.buildingDetail.barista;
        this.spotBuildings.cafeteria = this.buildingDetail.cafeteria;
    };
    SpotBuildingsFeaturesComponent.prototype.checkFeaturesUpdate = function (building) {
        for (var i = 0; i < this.buildingsOfficeIndustrialFilters.length; i++) {
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'twentyFourHourSecurity') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.twentyFourHourSecurity;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'electricGenerator') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.electricGenerator;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'heliport') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.heliport;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'sprinklers') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.sprinklers;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'leedStatus') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.leedStatus;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'singleTenant') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.singleTenant;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'multiTenant') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.multiTenant;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'bicycleRack') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.bicycleRack;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'fiberOptic') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.fiberOptic;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'centralAirConditioning') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.centralAirConditioning;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'petFriendly') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.petFriendly;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'truckYard') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.truckYard;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'crossDocking') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.crossDocking;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'sluiceGate') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.sluiceGate;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'blockAndSteelConstruction') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.blockAndSteelConstruction;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'blockConstruction') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.blockConstruction;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'steelConstruction') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.steelConstruction;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'includesOffice') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.includesOffice;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'includesRetail') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.includesRetail;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'coldStorage') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.coldStorage;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'fullBuildOut') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.fullBuildOut;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'skyLights') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.skyLights;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'bulletProofCabin') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.bulletProofCabin;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'mailAndPackage') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.mailPackageHandling;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'enhancedCleaning') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.enhancedCleaning;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'barista') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.barista;
            }
            if (this.buildingsOfficeIndustrialFilters[i].fieldName === 'cafeteria') {
                this.buildingsOfficeIndustrialFilters[i].selected = building.cafeteria;
            }
        }
    };
    SpotBuildingsFeaturesComponent.prototype.getIndustrialStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var constructionStatus;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createSpotBuildingsService.getIndustrialStatus()];
                    case 1:
                        constructionStatus = _a.sent();
                        this.originalConstructions = constructionStatus;
                        Object.values(constructionStatus).forEach(function (val) {
                            _this.allConstructionStatus.push({ id: val.id, name: _this.i18NService.getTranslation(val.name) });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsFeaturesComponent.prototype.constructionChange = function ($event) {
        var p = this.selectContructionStatus;
        this.spotBuildings.industrialStatus = $event.value; /* Object.assign({}, construction); */
    };
    SpotBuildingsFeaturesComponent.prototype.getAmenitiesBuildings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.propertyType[0] == 1001)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.spotBuildingsUtilService.getAmenitiesBuildings('industrialBuildings', this.spotBuildings.type, this.spotBuildings.entireSale)];
                    case 1:
                        _a.buildingsOfficeIndustrialFilters = _c.sent();
                        if (this.buildingDetail) {
                            this.checkFeaturesUpdate(this.buildingDetail);
                        }
                        else {
                            this.checkFeaturesUpdate(this.spotBuildings);
                            if (this.spotBuildings.industrialStatus) {
                                this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(this.spotBuildings.industrialStatus.name);
                                this.selectContructionStatus = this.spotBuildings.industrialStatus;
                            }
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.spotBuildingsUtilService.getAmenitiesBuildings('officeBuildings', this.spotBuildings.type, this.spotBuildings.entireSale)];
                    case 3:
                        _b.buildingsOfficeIndustrialFilters = _c.sent();
                        if (this.buildingDetail) {
                            this.checkFeaturesUpdate(this.buildingDetail);
                        }
                        else {
                            this.checkFeaturesUpdate(this.spotBuildings);
                            if (this.spotBuildings.industrialStatus) {
                                this.spotBuildings.industrialStatus.name = this.i18NService.getTranslation(this.spotBuildings.industrialStatus.name);
                                this.selectContructionStatus = this.spotBuildings.industrialStatus;
                            }
                        }
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsFeaturesComponent.prototype.setOfficeIndustrialTypes = function (index) {
        if (this.buildingsOfficeIndustrialFilters[index].selected) {
            this.buildingsOfficeIndustrialFilters[index].selected = false;
        }
        else {
            this.buildingsOfficeIndustrialFilters[index].selected = true;
        }
        var features = this.spotBuildingsUtilService.checkFields(this.buildingsOfficeIndustrialFilters);
        this.checkFeaturesRequest(features);
    };
    SpotBuildingsFeaturesComponent.prototype.checkFeaturesRequest = function (features) {
        this.spotBuildings.twentyFourHourSecurity = features.twentyFourHourSecurity;
        this.spotBuildings.electricGenerator = features.electricGenerator;
        this.spotBuildings.heliport = features.heliport;
        this.spotBuildings.sprinklers = features.sprinklers;
        this.spotBuildings.leedStatus = features.leedStatus;
        this.spotBuildings.singleTenant = features.singleTenant;
        this.spotBuildings.multiTenant = features.multiTenant;
        this.spotBuildings.bicycleRack = features.bicycleRack;
        this.spotBuildings.fiberOptic = features.fiberOptic;
        this.spotBuildings.centralAirConditioning = features.centralAirConditioning;
        this.spotBuildings.petFriendly = features.petFriendly;
        this.spotBuildings.truckYard = features.truckYard;
        this.spotBuildings.crossDocking = features.crossDocking;
        this.spotBuildings.sluiceGate = features.sluiceGate;
        this.spotBuildings.blockAndSteelConstruction = features.blockAndSteelConstruction;
        this.spotBuildings.blockConstruction = features.blockConstruction;
        this.spotBuildings.steelConstruction = features.steelConstruction;
        this.spotBuildings.includesOffice = features.includesOffice;
        this.spotBuildings.includesRetail = features.includesRetail;
        this.spotBuildings.coldStorage = features.coldStorage;
        this.spotBuildings.fullBuildOut = features.fullBuildOut;
        this.spotBuildings.skyLights = features.skyLights;
        this.spotBuildings.bulletProofCabin = features.bulletProofCabin;
        this.spotBuildings.mailPackageHandling = features.mailPackageHandling;
        this.spotBuildings.enhancedCleaning = features.enhancedCleaning;
        this.spotBuildings.barista = features.barista;
        this.spotBuildings.cafeteria = features.cafeteria;
    };
    SpotBuildingsFeaturesComponent.prototype.checkCharacters = function (event, dec) {
        var num = event.detail.value;
        var regexp;
        if (num) {
            var lastChar = num.substr(num.length - 1);
            if (dec) {
                if (this.langService.locale === 'en-US') {
                    regexp = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
                }
                else {
                    regexp = /^-?(?:\d+|\d{1,3}(?:.\d{3})+)(?:(\.|,)\d+)?$/;
                }
            }
            else {
                regexp = /^[0-9]*$/;
            }
            var tempValue = num.substring(0, num.length - 1);
            if ((!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
                num.substr(0) === '.' ||
                num.substr(0) === ',' ||
                (!dec && !regexp.test(num))) {
                event.srcElement.value = tempValue;
            }
            // Validating maximum value for integer
            if (!dec && tempValue > 2147483647) {
                event.srcElement.value = 2147483647;
            }
        }
    };
    SpotBuildingsFeaturesComponent.prototype.formaPropertySize = function () {
        this.spotBuildings.rentableArea = this.listingPriceService.convertForNumber(this.spotBuildings.rentableArea);
        if (this.spotBuildings.rentableArea > 0) {
            this.spotBuildings.rentableArea = this.listingPriceService.convertForDisplay(this.spotBuildings.rentableArea);
        }
    };
    SpotBuildingsFeaturesComponent.prototype.validateCustomURL = function () {
        var _this = this;
        this.videoURLInvalid = false;
        if (this.vrTourUrl) {
            var vrTourUrl2 = this.vrTourUrl.split('\n');
            if (vrTourUrl2.length > 2) {
                this.videoURLInvalid = true;
            }
            else {
                vrTourUrl2.forEach(function (item) {
                    if (!validateURL(item.trim())) {
                        _this.videoURLInvalid = true;
                    }
                    else {
                        _this.spotBuildings.vrTourUrl = _this.vrTourUrl;
                    }
                });
            }
        }
        else {
            this.spotBuildings.vrTourUrl = this.vrTourUrl;
        }
    };
    return SpotBuildingsFeaturesComponent;
}());
export { SpotBuildingsFeaturesComponent };
