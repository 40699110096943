import * as tslib_1 from "tslib";
import { OnInit, ElementRef, NgZone, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { I18nService } from '@app/core';
var Marker = /** @class */ (function () {
    function Marker(lat, lng) {
        this.lat = lat;
        this.lng = lng;
    }
    return Marker;
}());
export { Marker };
var BuildingLocation = /** @class */ (function () {
    function BuildingLocation(marker, address, stateName, cityName, cityId) {
        this.marker = marker;
        this.address = address;
        this.stateName = stateName;
        this.cityName = cityName;
        this.cityId = cityId;
    }
    return BuildingLocation;
}());
export { BuildingLocation };
var MapComponent = /** @class */ (function () {
    function MapComponent(mapsAPILoader, ngZone, i18NService) {
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        this.i18NService = i18NService;
        this.defaultLat = -23.5955;
        this.defaultLng = -46.6885;
        this.newLocationEvent = new EventEmitter();
    }
    MapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.zoom = 15;
        this.enterAddressDesc = this.i18NService.get('map.enterAddress');
        this.title = this.i18NService.get('map.whereBuildingLocated');
        this.buildingMarker = new Marker(this.defaultLat, this.defaultLng);
        //load Places Autocomplete
        this.mapsAPILoader.load().then(function () {
            _this.setCurrentLocation();
            _this.geoCoder = new google.maps.Geocoder();
            _this.setNewMarker(_this.defaultLat, _this.defaultLng);
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', function () {
                _this.ngZone.run(function () {
                    //get the place result
                    var place = autocomplete.getPlace();
                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    //set latitude, longitude
                    _this.setNewMarker(place.geometry.location.lat(), place.geometry.location.lng());
                });
            });
        });
    };
    // Get Current Location Coordinates
    MapComponent.prototype.setCurrentLocation = function () {
        /*
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(position => {
            this.buildingMarker.lat = position.coords.latitude;
            this.buildingMarker.lng = position.coords.longitude;
            this.getAddress(this.buildingMarker.lat, this.buildingMarker.lng);
          });
        }
        */
    };
    MapComponent.prototype.addMarker = function (event) {
        var coords = event.coords;
        this.setNewMarker(coords.lat, coords.lng);
    };
    MapComponent.prototype.markerDragEnd = function ($event) {
        this.setNewMarker($event.coords.lat, $event.coords.lng);
    };
    MapComponent.prototype.findAddressStateAndCityFrom = function (marker) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.geoCoder.geocode({ location: { lat: marker.lat, lng: marker.lng } }, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            var address = results[0].formatted_address;
                            if (address) {
                                for (var i_1 = 0; i_1 < 2; i_1++) {
                                    var lastIdx = address.lastIndexOf(',');
                                    address = address.substring(0, lastIdx);
                                }
                            }
                            var stateName = null;
                            var cityName = null;
                            for (var i = 0; i < results[0].address_components.length; i++) {
                                for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                                    //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                                    if (results[0].address_components[i].types[b] == 'administrative_area_level_1') {
                                        stateName = results[0].address_components[i].long_name;
                                    }
                                    else if (results[0].address_components[i].types[b] == 'locality' ||
                                        results[0].address_components[i].types[b] == 'administrative_area_level_2') {
                                        cityName = results[0].address_components[i].long_name;
                                    }
                                }
                            }
                            var buildingLocation = new BuildingLocation(marker, address, stateName, cityName);
                            _this.newLocationEvent.emit(buildingLocation);
                        }
                        else {
                        }
                    }
                    else {
                        console.error('Geocoder failed due to: ' + status);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    MapComponent.prototype.setNewMarker = function (lat, lng) {
        if (lat && lng) {
            this.buildingMarker.lat = lat;
            this.buildingMarker.lng = lng;
            this.findAddressStateAndCityFrom(this.buildingMarker);
        }
    };
    return MapComponent;
}());
export { MapComponent };
