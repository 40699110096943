import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, I18nService } from '@app/core';
import { ListingPriceService } from '@app/core/listings';
var ListingPriceComponent = /** @class */ (function () {
    function ListingPriceComponent(registerService, _ts, langService, listingPriceService, i18NService) {
        this.registerService = registerService;
        this._ts = _ts;
        this.langService = langService;
        this.listingPriceService = listingPriceService;
        this.i18NService = i18NService;
        this.regex = '^(0*100{1,1}.?((?<=.)0*)?%?$)|(^0*d{0,2}.?((?<=.)d*)?%?)$';
        this.screenWidth = window.innerWidth;
    }
    ListingPriceComponent.prototype.ngOnInit = function () {
        this.initMeasurement();
        if (this.listing) {
            this.listing.isForLeaseSale === 'SALE'
                ? this.setValidation('S')
                : this.listing.isForLeaseSale === 'LEASE' || this.listing.isForLeaseSale === 'L'
                    ? this.setValidation('L')
                    : '';
        }
        else {
            this.setValidation('L');
        }
        this.setListingClass();
    };
    ListingPriceComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    ListingPriceComponent.prototype.initMeasurement = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getMeasurementStands()];
                    case 1:
                        _a.measurementStandards = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getMeasurementTranslations(this.measurementStandards)];
                    case 2:
                        _b.measurementStandards = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingPriceComponent.prototype.setListingClass = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.listingClass === 'PROMOTED') {
                    this.listingPriceForm.controls['leaseableAreaTotal'].disable();
                }
                return [2 /*return*/];
            });
        });
    };
    ListingPriceComponent.prototype.getMeasurementStands = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getMeasurementStandard().toPromise()];
            });
        });
    };
    ListingPriceComponent.prototype.setValidation = function (type) {
        if (type === 'L') {
            // Rent
            if (this.listingPriceForm.value.negRentableArea) {
                this.listingPriceForm.controls.monthlyRent.setValue(null);
                this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
                this.listingPriceForm.controls['monthlyRent'].setValidators([]);
                this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyRent.disable();
                this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyRentPerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyRent.enable();
                this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyRentPerArea'].enable();
            }
            // Condo
            if (this.listingPriceForm.value.negCondoFeeArea) {
                this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
                this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
                this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
                this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyCondoFee.disable();
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyCondoFee.enable();
                this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
            }
            // IPTU
            if (this.listingPriceForm.value.negIPTUArea) {
                this.listingPriceForm.controls.monthlyIptu.setValue(null);
                this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
                this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
                this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
                this.listingPriceForm.controls.monthlyIptu.disable();
                this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
                this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
            }
            else {
                this.listingPriceForm.controls.monthlyIptu.enable();
                this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
                this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
                this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
            }
        }
        else if (type === 'S') {
            if (this.listingPriceForm.value.pricePer) {
                this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
                this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
                this.listingPriceForm.controls.totalAskingPrice.disable();
                this.listingPriceForm.controls['pricePerSquare'].disable();
            }
            else {
                this.listingPriceForm.controls.totalAskingPrice.enable();
                this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
                this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
                this.listingPriceForm.controls['pricePerSquare'].enable();
            }
        }
    };
    ListingPriceComponent.prototype.checkTotal = function () {
        if (this.listingPriceForm.value.pricePer) {
            this.listingPriceForm.controls.totalAskingPrice.enable();
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([Validators.required]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
            this.listingPriceForm.controls['pricePerSquare'].setValue(null);
            this.listingPriceForm.controls['pricePerSquare'].enable();
        }
        else {
            this.listingPriceForm.controls.totalAskingPrice.setValue(null);
            this.listingPriceForm.controls.pricePerSquare.setValue('');
            this.listingPriceForm.controls['totalAskingPrice'].setValidators([]);
            this.listingPriceForm.controls['totalAskingPrice'].updateValueAndValidity();
            this.listingPriceForm.controls.totalAskingPrice.disable();
            this.listingPriceForm.controls['pricePerSquare'].disable();
        }
    };
    ListingPriceComponent.prototype.checkMonthlyCondo = function () {
        if (this.listingPriceForm.value.negCondoFeeArea) {
            this.listingPriceForm.controls.monthlyCondoFee.enable();
            this.listingPriceForm.controls['monthlyCondoFee'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyCondoFee.disable();
            this.listingPriceForm.controls.monthlyCondoFee.setValue(null);
            this.listingPriceForm.controls.monthlyCondoFeePerArea.setValue('');
            this.listingPriceForm.controls['monthlyCondoFee'].setValidators([]);
            this.listingPriceForm.controls['monthlyCondoFee'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyCondoFeePerArea'].disable();
        }
    };
    ListingPriceComponent.prototype.checkMonthlyIPTU = function () {
        if (this.listingPriceForm.value.negIPTUArea) {
            this.listingPriceForm.controls.monthlyIptu.enable();
            this.listingPriceForm.controls['monthlyIptu'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyIptuPerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyIptu.setValue(null);
            this.listingPriceForm.controls.monthlyIptuPerArea.setValue('');
            this.listingPriceForm.controls['monthlyIptu'].setValidators([]);
            this.listingPriceForm.controls['monthlyIptu'].updateValueAndValidity();
            this.listingPriceForm.controls.monthlyIptu.disable();
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyIptuPerArea'].disable();
        }
    };
    ListingPriceComponent.prototype.checkMonthly = function () {
        if (this.listingPriceForm.value.negRentableArea) {
            this.listingPriceForm.controls.monthlyRent.enable();
            this.listingPriceForm.controls['monthlyRent'].setValidators([Validators.required]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyRentPerArea'].enable();
        }
        else {
            this.listingPriceForm.controls.monthlyRent.setValue(null);
            this.listingPriceForm.controls.monthlyRentPerArea.setValue('');
            this.listingPriceForm.controls['monthlyRent'].setValidators([]);
            this.listingPriceForm.controls['monthlyRent'].updateValueAndValidity();
            this.listingPriceForm.controls.monthlyRent.disable();
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(null);
            this.listingPriceForm.controls['monthlyRentPerArea'].disable();
        }
    };
    ListingPriceComponent.prototype.percentageMask = function () {
        var percent = this.listingPriceForm.value.currentOccupancy;
        if (percent >= 0 && percent < 100) {
            this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
        }
        else {
            this.listingPriceForm.controls['currentOccupancy'].setValue(100);
        }
    };
    ListingPriceComponent.prototype.percentageBlur = function () {
        if (this.listingPriceForm.value.currentOccupancy >= 0 && this.listingPriceForm.value.currentOccupancy < 100) {
            var percent = parseFloat(this.listingPriceForm.value.currentOccupancy);
            percent = percent.toFixed(2);
            this.listingPriceForm.controls['currentOccupancy'].setValue(percent);
        }
    };
    ListingPriceComponent.prototype.interestMask = function () {
        var percentInterestOffered = this.listingPriceForm.value.percentInterestOffered;
        if (percentInterestOffered >= 0 && percentInterestOffered < 100) {
            this.listingPriceForm.controls['percentInterestOffered'].setValue(percentInterestOffered);
        }
        else {
            this.listingPriceForm.controls['percentInterestOffered'].setValue(100);
        }
    };
    ListingPriceComponent.prototype.getMeasurementTranslations = function (measurements) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(measurements).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, measurements];
            });
        });
    };
    ListingPriceComponent.prototype.checkCharacters = function (event) {
        var num = event.detail.value;
        var regexp;
        if (num) {
            var lastChar = num.substr(num.length - 1);
            if (this.langService.locale === 'en-US') {
                regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
            }
            else {
                regexp = /^-?(?:\d{0,12}|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
            }
            var tempValue = num.substring(0, num.length - 1);
            if ((!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
                num.substr(0) === '.' ||
                num.substr(0) === ',') {
                if (tempValue.length && tempValue.length > 12) {
                    console.error('Invalid Value: ', tempValue);
                    event.srcElement.value = null;
                }
                else {
                    event.srcElement.value = tempValue;
                }
            }
        }
    };
    ListingPriceComponent.prototype.checkRentableArea = function () {
        var price = this.listingPriceForm.value.totalAskingPrice;
        var area = this.listingPriceForm.value.rentableArea;
        price = this.listingPriceService.convertForNumber(price);
        area = this.listingPriceService.convertForNumber(area);
        var pricePerSquare = price / area;
        if (isFinite(pricePerSquare) && pricePerSquare !== 0) {
            pricePerSquare = (pricePerSquare * 100) / 100;
            pricePerSquare = this.listingPriceService.convertForDisplay(pricePerSquare);
            this.listingPriceForm.controls['pricePerSquare'].setValue(pricePerSquare);
            this.listingPriceForm.controls['pricePerSquare'].updateValueAndValidity();
        }
    };
    ListingPriceComponent.prototype.rentableAreaBlur = function () {
        var area = this.listingPriceForm.value.rentableArea;
        area = this.listingPriceService.convertForNumber(area);
        if (isFinite(area) && area !== 0) {
            area = this.listingPriceService.convertForDisplay(area);
            this.listingPriceForm.controls['rentableArea'].setValue(area);
            this.checkRentableArea();
        }
    };
    ListingPriceComponent.prototype.totalPriceBlur = function ($event) {
        var price = this.listingPriceForm.value.totalAskingPrice;
        price = this.listingPriceService.convertForNumber(price);
        if (isFinite(price) && price !== 0) {
            price = this.listingPriceService.convertForDisplay(price);
            this.listingPriceForm.controls['totalAskingPrice'].setValue(price);
            this.checkRentableArea();
        }
    };
    ListingPriceComponent.prototype.updateAmountPer = function () {
        if (this.listingPriceForm.value.rentableArea) {
            var price = this.listingPriceForm.value.pricePerSquare;
            var area = this.listingPriceForm.value.rentableArea;
            price = this.listingPriceService.convertForNumber(price);
            area = this.listingPriceService.convertForNumber(area);
            var totalAskingPrice = price * area;
            if (isFinite(totalAskingPrice) && totalAskingPrice !== 0) {
                totalAskingPrice = this.listingPriceService.convertForDisplay(totalAskingPrice);
                this.listingPriceForm.controls['totalAskingPrice'].setValue(totalAskingPrice);
            }
        }
    };
    ListingPriceComponent.prototype.interestBlur = function () {
        if (this.listingPriceForm.value.percentInterestOffered >= 0 &&
            this.listingPriceForm.value.percentInterestOffered < 100) {
            var percent = parseFloat(this.listingPriceForm.value.percentInterestOffered);
            percent = percent.toFixed(2);
            this.listingPriceForm.controls['percentInterestOffered'].setValue(percent);
        }
    };
    ListingPriceComponent.prototype.checkLeaseableArea = function () {
        var rent = this.listingPriceForm.value.monthlyRent;
        var area = this.listingPriceForm.value.leaseableArea;
        rent = this.listingPriceService.convertForNumber(rent);
        area = this.listingPriceService.convertForNumber(area);
        var monthlyRentPerArea = rent / area;
        if (isFinite(monthlyRentPerArea) && monthlyRentPerArea !== 0 && monthlyRentPerArea !== '') {
            var monthlyRentPerArea2 = (monthlyRentPerArea * 100) / 100;
            rent = this.listingPriceService.convertForDisplay(monthlyRentPerArea2);
            this.listingPriceForm.controls['monthlyRentPerArea'].setValue(rent);
            this.listingPriceForm.controls['monthlyRentPerArea'].updateValueAndValidity();
        }
        this.updateMonthlyCondo();
        this.updateMonthlyIptu();
    };
    ListingPriceComponent.prototype.leaseableAreaBlur = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var area;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        area = this.listingPriceForm.value.leaseableArea;
                        area = this.listingPriceService.convertToNumber(area);
                        if (!(isFinite(area) && area !== 0 && area !== '')) return [3 /*break*/, 2];
                        area = this.listingPriceService.convertForDisplay(area);
                        this.listingPriceForm.controls['leaseableArea'].setValue(area);
                        return [4 /*yield*/, this.monthlyRentBlur(event)];
                    case 1:
                        _a.sent();
                        this.checkLeaseableArea();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ListingPriceComponent.prototype.leaseableAreatTotalBlur = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var area;
            return tslib_1.__generator(this, function (_a) {
                area = this.listingPriceForm.value.leaseableAreaTotal;
                area = this.listingPriceService.convertToNumber(area);
                if (isFinite(area) && area !== 0 && area !== '') {
                    area = this.listingPriceService.convertForDisplay(area);
                    this.listingPriceForm.controls['leaseableAreaTotal'].setValue(area);
                }
                return [2 /*return*/];
            });
        });
    };
    ListingPriceComponent.prototype.monthlyRentBlur = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rent;
            return tslib_1.__generator(this, function (_a) {
                rent = this.listingPriceForm.value.monthlyRent;
                rent = this.listingPriceService.convertForNumber(rent);
                if (isFinite(rent) && rent !== 0 && rent !== '') {
                    rent = this.listingPriceService.convertForDisplay(rent);
                    this.listingPriceForm.controls['monthlyRent'].setValue(rent);
                    this.checkLeaseableArea();
                }
                return [2 /*return*/];
            });
        });
    };
    ListingPriceComponent.prototype.updateMonthlyFee = function () {
        if (this.listingPriceForm.value.leaseableArea) {
            var rent = this.listingPriceForm.value.monthlyRentPerArea;
            var area = this.listingPriceForm.value.leaseableArea;
            rent = this.listingPriceService.convertForNumber(rent);
            area = this.listingPriceService.convertForNumber(area);
            var monthlyRent = rent * area;
            if (isFinite(monthlyRent) && monthlyRent !== 0 && monthlyRent !== '') {
                monthlyRent = this.listingPriceService.convertForDisplay(monthlyRent);
                this.listingPriceForm.controls['monthlyRent'].setValue(monthlyRent);
                this.checkLeaseableArea();
            }
        }
    };
    ListingPriceComponent.prototype.standardChange = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    ListingPriceComponent.prototype.condoFeeBlur = function ($event) {
        var condoFee = this.listingPriceForm.value.monthlyCondoFee;
        condoFee = this.listingPriceService.convertForNumber(condoFee);
        if (isFinite(condoFee) && condoFee !== 0 && condoFee !== '') {
            condoFee = this.listingPriceService.convertForDisplay(condoFee);
            this.listingPriceForm.controls['monthlyCondoFee'].setValue(condoFee);
            this.updateMonthlyCondo();
        }
    };
    ListingPriceComponent.prototype.updateMonthlyCondoFee = function () {
        if (this.listingPriceForm.value.leaseableArea) {
            var condoFeeArea = this.listingPriceForm.value.monthlyCondoFeePerArea;
            var area = this.listingPriceForm.value.leaseableArea;
            condoFeeArea = this.listingPriceService.convertForNumber(condoFeeArea);
            area = this.listingPriceService.convertForNumber(area);
            var monthlyCondoFee = condoFeeArea * area;
            monthlyCondoFee = this.listingPriceService.convertForDisplay(monthlyCondoFee);
            this.listingPriceForm.controls['monthlyCondoFee'].setValue(monthlyCondoFee);
            this.updateMonthlyCondo();
        }
    };
    ListingPriceComponent.prototype.updateMonthlyCondo = function () {
        var condoFee = this.listingPriceForm.value.monthlyCondoFee;
        var area = this.listingPriceForm.value.leaseableArea;
        condoFee = this.listingPriceService.convertForNumber(condoFee);
        area = this.listingPriceService.convertForNumber(area);
        var monthlyCondoFeePerArea = condoFee / area;
        if (isFinite(monthlyCondoFeePerArea) && monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
            monthlyCondoFeePerArea = (monthlyCondoFeePerArea * 100) / 100;
            monthlyCondoFeePerArea = this.listingPriceService.convertForDisplay(monthlyCondoFeePerArea);
            if (monthlyCondoFeePerArea !== 0 && monthlyCondoFeePerArea !== '') {
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].setValue(monthlyCondoFeePerArea);
                this.listingPriceForm.controls['monthlyCondoFeePerArea'].updateValueAndValidity();
            }
        }
    };
    //IPTU FORMATTING
    ListingPriceComponent.prototype.updateIptuFee = function () {
        if (this.listingPriceForm.value.leaseableArea) {
            var iptuArea = this.listingPriceForm.value.monthlyIptuPerArea;
            var area = this.listingPriceForm.value.leaseableArea;
            iptuArea = this.listingPriceService.convertForNumber(iptuArea);
            area = this.listingPriceService.convertForNumber(area);
            var monthlyIptu = iptuArea * area;
            monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
            this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
            this.updateMonthlyIptu();
        }
    };
    ListingPriceComponent.prototype.updateMonthlyIptu = function () {
        var iptu = this.listingPriceForm.value.monthlyIptu;
        var area = this.listingPriceForm.value.leaseableArea;
        iptu = this.listingPriceService.convertForNumber(iptu);
        area = this.listingPriceService.convertForNumber(area);
        var monthlyIptuPerArea = iptu / area;
        if (isFinite(monthlyIptuPerArea) && monthlyIptuPerArea !== 0 && monthlyIptuPerArea !== '') {
            monthlyIptuPerArea = (monthlyIptuPerArea * 100) / 100;
            monthlyIptuPerArea = this.listingPriceService.convertForDisplay(monthlyIptuPerArea);
            this.listingPriceForm.controls['monthlyIptuPerArea'].setValue(monthlyIptuPerArea);
            this.listingPriceForm.controls['monthlyIptuPerArea'].updateValueAndValidity();
        }
    };
    ListingPriceComponent.prototype.iptuFeeBlur = function ($event) {
        var monthlyIptu = this.listingPriceForm.value.monthlyIptu;
        monthlyIptu = this.listingPriceService.convertForNumber(monthlyIptu);
        if (isFinite(monthlyIptu) && monthlyIptu !== 0 && monthlyIptu !== '') {
            monthlyIptu = this.listingPriceService.convertForDisplay(monthlyIptu);
            this.listingPriceForm.controls['monthlyIptu'].setValue(monthlyIptu);
            this.updateMonthlyIptu();
        }
    };
    ListingPriceComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18NService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    return ListingPriceComponent;
}());
export { ListingPriceComponent };
