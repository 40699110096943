import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListingService } from '@app/core/listings';
import * as Fuse from 'fuse.js';
import { TranslateService } from '@ngx-translate/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
var ListingLocationPopoverComponent = /** @class */ (function () {
    function ListingLocationPopoverComponent(navParams, popoverCtrl, listingService, _ts, i18n, breakpointObserver) {
        var _this = this;
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this._ts = _ts;
        this.i18n = i18n;
        this.breakpointObserver = breakpointObserver;
        this.selectedCityState = '';
        this.inputCity = '';
        this.inputState = '';
        this.cityOptionSelected = false;
        this.stateOptionSelected = false;
        this.cityOptions = [];
        this.stateOptions = [];
        this.defaultCityStateOptions = [];
        this.otherOptions = [];
        this.allCities = [];
        this.preSelectedCityIndex = -1;
        this.selectOptions = {
            cssClass: 'remove-ok'
        };
        this.spotNearMe = {
            selected: false
        };
        this.isOther = false;
        this.selected = '';
        this.isMobile = false;
        this.onlyStateCity = false;
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    ListingLocationPopoverComponent.prototype.ngOnInit = function () {
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.getPopData();
    };
    ListingLocationPopoverComponent.prototype.setSearchBarFocus = function () {
        var _this = this;
        var isStateOther = this.isOtherState();
        if (isStateOther) {
            setTimeout(function () {
                _this.searchbar.setFocus();
            }, 1000);
        }
    };
    ListingLocationPopoverComponent.prototype.setSearchModel = function () {
        var _this = this;
        this.allCities = this.locationData.allCities;
        this.defaultCityStateOptions = this.listingService.filterCityState(this.allCities, this.locationData.states);
        if (this.locationData.stateId || this.locationData.cityId) {
            this.selectedCityState = this.listingService.getCurrentLocation(this.locationData, this.defaultCityStateOptions);
            if (this.selectedCityState == 'other') {
                var state = this.locationData.states.filter(function (item) { return item.id === _this.locationData.stateId; });
                this.inputState = this.getI18nValue(state.length > 0 ? state[0].name : '');
                /*  this.SelectOther(); */
                this.stateOptionSelected = true;
            }
            this.getCities();
            this.setSearchBarFocus();
        }
        else {
            this.getCities();
        }
    };
    ListingLocationPopoverComponent.prototype.getFullCityName = function () {
        var _this = this;
        return this.cities
            .filter(function (city) {
            return city.id === _this.locationData.cityId;
        })
            .map(function (city) { return city.name; });
    };
    // Get Data passed from component opening popover
    ListingLocationPopoverComponent.prototype.getPopData = function () {
        this.locationData = this.navParams.data;
        this.spotNearMe.selected = this.locationData.isSpotNearMeSearch;
        this.setSearchModel();
        this.unsetCityAndState();
    };
    // Dismissing popover with data passed back to main component
    ListingLocationPopoverComponent.prototype.dismiss = function (fromCancel) {
        this.locationData.fromCancel = fromCancel;
        this.popoverCtrl.dismiss(this.locationData);
    };
    ListingLocationPopoverComponent.prototype.getFuzzyOptions = function () {
        if (this.cityOptionSelected) {
            this.onlyStateCity = false;
            this.cityOptionSelected = false;
            return;
        }
        if (!this.inputCity.trim().length) {
            this.cityOptions = [];
            this.locationData.cityId = null;
            this.onlyStateCity = false;
            return;
        }
        this.onlyStateCity = true;
        //Commented line is used for exact-match autosuggest. Second line is used for fuzzy search.
        this.cityOptions = this.fuse.search(this.inputCity);
    };
    ListingLocationPopoverComponent.prototype.getFuzzyOptionsState = function () {
        if (this.stateOptionSelected) {
            this.onlyStateCity = false;
            this.stateOptionSelected = false;
            return;
        }
        if (!this.inputState.trim().length) {
            this.onlyStateCity = false;
            this.stateOptions = [];
            this.locationData.stateId = null;
            return;
        }
        this.onlyStateCity = true;
        var options = {
            keys: ['urlName']
        };
        this.fuseState = new Fuse(this.locationData.states, options);
        //Commented line is used for exact-match autosuggest. Second line is used for fuzzy search.
        this.stateOptions = this.fuseState.search(this.inputState);
    };
    ListingLocationPopoverComponent.prototype.unsetCityAndGetCityOptions = function () {
        this.locationData.cityId = null;
        this.inputCity = '';
        this.spotNearMe.selected = false;
        this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
        this.getCities();
    };
    ListingLocationPopoverComponent.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, cityNameFull, options;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        if (!this.locationData.stateId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.listingService.getCitiesByState(this.locationData.stateId)];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _b = this.allCities;
                        _c.label = 3;
                    case 3:
                        _a.cities = _b;
                        if (this.locationData.cityId && this.selectedCityState == 'other') {
                            cityNameFull = this.getFullCityName();
                            this.inputCity = this.getI18nValue(cityNameFull);
                            this.cityOptionSelected = true;
                        }
                        options = {
                            keys: ['name']
                        };
                        this.fuse = new Fuse(this.cities, options);
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingLocationPopoverComponent.prototype.selectCity = function (city) {
        this.locationData.cityId = city.id;
        this.inputCity = this.getI18nValue(city.name);
        this.cityOptionSelected = true;
        if (!this.locationData.stateId) {
            this.locationData.stateId = city.countryState.id;
        }
        this.cityOptions = [];
        this.preSelectedCityIndex = -1;
        this.spotNearMe.selected = false;
        this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
    };
    ListingLocationPopoverComponent.prototype.selectState = function (state) {
        this.locationData.stateId = state.id;
        this.inputState = this.getI18nValue(state.name);
        this.stateOptionSelected = true;
        this.cityOptionSelected = false;
        this.stateOptions = [];
        this.unsetCityAndGetCityOptions();
    };
    ListingLocationPopoverComponent.prototype.clearSearch = function () {
        this.locationData.stateId = null;
        this.locationData.cityId = null;
        this.inputCity = '';
        this.inputState = '';
        this.selectedCityState = '';
        this.dismiss(false);
    };
    ListingLocationPopoverComponent.prototype.getI18nValue = function (textValue) {
        try {
            return this.i18n.getTranslation(textValue);
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    ListingLocationPopoverComponent.prototype.unsetCityAndState = function () {
        this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
        this.listingService.spotNearMeFilter.next(this.locationData.isSpotNearMeSearch);
        if (this.spotNearMe.selected) {
            this.locationData.cityId = null;
            this.inputCity = '';
            this.locationData.stateId = null;
            this.selectedCityState = null;
            this.getCities();
        }
    };
    ListingLocationPopoverComponent.prototype.onSpotNearMeChange = function () {
        this.spotNearMe.selected = !this.spotNearMe.selected;
        this.unsetCityAndState();
        this.dismiss(false);
    };
    ListingLocationPopoverComponent.prototype.selectCityState = function (state, param) {
        var _this = this;
        if (param === void 0) { param = 0; }
        this.selectedCityState = state.detail ? state.detail.value : state;
        this.spotNearMe.selected = false;
        this.locationData.isSpotNearMeSearch = this.spotNearMe.selected;
        if (this.selectedCityState !== 'other') {
            var locationFilter_1 = this.listingService.setLocationFilter(this.selectedCityState);
            Object.keys(locationFilter_1).forEach(function (key) {
                _this.locationData[key] = locationFilter_1[key];
            });
        }
        this.setSearchBarFocus();
    };
    ListingLocationPopoverComponent.prototype.isOtherState = function () {
        var element = document.getElementsByClassName('popover-content');
        if (this.selectedCityState && this.selectedCityState === 'other') {
            element[0].style.height = '660px';
            return true;
        }
        else {
            if (element && element.length > 0) {
                element[0].style.height = '600px';
            }
            return false;
        }
        return this.selectedCityState && this.selectedCityState === 'other';
    };
    ListingLocationPopoverComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.dismiss(false);
        }
        if (this.cityOptions && this.cityOptions.length) {
            if ((event.key === 'ArrowDown' || event.key === 'Tab') &&
                this.preSelectedCityIndex < Math.max(2, this.cityOptions.length - 1)) {
                this.preSelectedCityIndex++;
            }
            else if (event.key === 'ArrowUp' && this.preSelectedCityIndex > -1) {
                this.preSelectedCityIndex--;
            }
            if (event.key === 'Enter' && this.preSelectedCityIndex > -1) {
                this.selectCity(this.cityOptions[this.preSelectedCityIndex]);
            }
        }
        else if (event.key === 'Enter') {
            this.dismiss(false);
        }
    };
    ListingLocationPopoverComponent.prototype.SelectOther = function () {
        var element;
        if (this.isOther) {
            element = document.getElementsByClassName('state-flex-col-other');
            this.selectedCityState = this.selected == 'other' ? '' : this.selected;
            this.isOther = false;
            element[0].style.background = '#fff';
            element[0].style.border = '1px solid #043650';
            element[0].style.color = ' #043650';
        }
        else {
            var element_1 = document.getElementsByClassName('state-flex-col-other');
            this.selected = this.selectedCityState;
            this.selectedCityState = 'other';
            this.stateOptionSelected = this.inputState == '' ? false : true;
            this.cityOptionSelected = this.inputCity == '' ? false : true;
            this.isOther = true;
            element_1[0].style.background = 'rgb(169,169,169)';
            element_1[0].style.border = '1px solid rgb(169,169,169)';
            element_1[0].style.color = 'rgb(84,77,77)';
        }
    };
    ListingLocationPopoverComponent.prototype.SelectCityState = function () {
        this.dismiss(false);
    };
    return ListingLocationPopoverComponent;
}());
export { ListingLocationPopoverComponent };
