import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingDetailService } from '../../../core/listings/listing-detail.service';
import { ImageService } from '../../../core/image.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { IonContent, IonSlides, PopoverController, ToastController, AlertController } from '@ionic/angular';
import { faShareAltSquare } from '@fortawesome/free-solid-svg-icons';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { PlatformHelperService, PropertyTypeHelperService, PurchaseCreditsService, I18nService } from '@app/core';
import { faStar, faAward, faBuilding, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { CommonGenericService } from '@app/core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EventCheckerService } from '@app/core/listings';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/auth';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { LoginFormDialogService } from '@app/shared/login';
import { MatDialog } from '@angular/material/dialog';
import { AgentNotificationComponent } from '@app/search-navigation/components/agent-notification/agent-notification.component';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { Location } from '@angular/common';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '../listing-search/filter-selected-keys';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { GenericMapService } from '@app/core/generic-map.service';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
var ListingDetailComponent = /** @class */ (function () {
    function ListingDetailComponent(activatedRoute, router, listingService, breakpointObserver, imgService, detailPositionService, ngNavigatorShareService, platformHelperService, commonService, propertyHelperService, deviceDetectorService, purchaseService, _eventChecker, userActivityService, metaService, i18nService, auth, loginFormDialog, dialog, agentNotPopOverCtrl, whatsAppService, toastCtrl, _location, listingSearchService, analyticsService, commomMapSvc, coworkingService, alertCtrl) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.listingService = listingService;
        this.breakpointObserver = breakpointObserver;
        this.imgService = imgService;
        this.detailPositionService = detailPositionService;
        this.ngNavigatorShareService = ngNavigatorShareService;
        this.platformHelperService = platformHelperService;
        this.commonService = commonService;
        this.propertyHelperService = propertyHelperService;
        this.deviceDetectorService = deviceDetectorService;
        this.purchaseService = purchaseService;
        this._eventChecker = _eventChecker;
        this.userActivityService = userActivityService;
        this.metaService = metaService;
        this.i18nService = i18nService;
        this.auth = auth;
        this.loginFormDialog = loginFormDialog;
        this.dialog = dialog;
        this.agentNotPopOverCtrl = agentNotPopOverCtrl;
        this.whatsAppService = whatsAppService;
        this.toastCtrl = toastCtrl;
        this._location = _location;
        this.listingSearchService = listingSearchService;
        this.analyticsService = analyticsService;
        this.commomMapSvc = commomMapSvc;
        this.coworkingService = coworkingService;
        this.alertCtrl = alertCtrl;
        this.isMobile = false;
        this.showDetails = true;
        this.currentPhotoIndex = 0;
        this.breadcrumbNavOptions = {
            usePlatformLocation: false
        };
        this.options = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.toolbarText = {
            next: 'global.menu.next',
            prev: 'global.menu.previous',
            nextListing: 'global.menu.nextListing',
            prevListing: 'global.menu.previousListing',
            search: 'global.menu.search',
            returnToSearch: 'global.menu.returnToSearch'
        };
        this.placeholderImage = 'assets/placeholder-image.png';
        this.loading = true;
        this.displayScrollToTop = true;
        this.isAndroid = false;
        this.slideOpts = {
            initialSlide: 0,
            slidesPerView: 3
        };
        this.slideOptsTwo = {
            initialSlide: 0,
            slidesPerView: 2
        };
        this.slideOptsMobile = {
            initialSlide: 0,
            slidesPerView: 1
        };
        this.contactPhone = false;
        this.contactEmail = false;
        this.whatsappPhone = false;
        this.googleMapSize = '400x300';
        this.googleMapSizeOnePhoto = '640x300';
        this.openUrl = false;
        this.sharedPrivatePositionsText = '';
        this.defaultCoworkingType = '';
        this.showCoworkingPrivate = false;
        this.isLoggedIn$ = this.auth.$isLoginSubject;
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        this.faShareAltSquare = faShareAltSquare;
        this.faMapMarkerAlt = faMapMarkerAlt;
        this.ngNavigatorShareService = ngNavigatorShareService;
        this.isDesktop = this.platformHelperService.isDesktopMode();
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.getParams();
    }
    ListingDetailComponent.prototype.getParams = function () {
        var navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras) {
            if (navigation.extras.state) {
                this.localStorageKey = navigation.extras.state.localStorageKey;
            }
            if (navigation.extras.state && navigation.extras.state.openUrl) {
                this.openUrl = true;
            }
            if (navigation.extras.state &&
                navigation.extras.state.searchCriteria &&
                navigation.extras.state.searchCriteria.coworkingPositions) {
                this.coworkingPositionsSeachParam = navigation.extras.state.searchCriteria.coworkingPositions;
            }
            this.searchTags =
                navigation.extras.state && navigation.extras.state.searchTags ? navigation.extras.state.searchTags : null;
            this.searchDTO =
                navigation.extras.state && navigation.extras.state.searchCriteria
                    ? navigation.extras.state.searchCriteria
                    : null;
            this.checkBoxValues =
                navigation.extras.state && navigation.extras.state.checkBoxValues
                    ? navigation.extras.state.checkBoxValues
                    : null;
            this.myListingAnalytics =
                navigation.extras.state && navigation.extras.state.myListingAnalytics
                    ? navigation.extras.state.myListingAnalytics
                    : null;
            this.myListingPage =
                navigation.extras.state && navigation.extras.state.myListingPage ? navigation.extras.state.myListingPage : null;
            if (this.myListingAnalytics) {
                this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.LISTING_ANALYTICS);
            }
            else if (this.myListingPage) {
                this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.MY_LISTINGS);
            }
            else if (navigation.extras.state &&
                navigation.extras.state.localStorageKey === SelectedFilterKey.FAVORITES_SEARCH) {
                this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.FAVORITES_SEARCH);
            }
            else {
                this.listingSearchService.saveLastSeenSearchPage(SelectedFilterKey.REGULAR_SEARCH);
            }
        }
    };
    ListingDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isDesktopM = this.deviceDetectorService.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetectorService.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetectorService.isTablet() ? 'tablet' : '';
        if (this.isDesktopM.length) {
            this.currentDevice = this.isDesktopM;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
        this.loading = true;
        this.subscribeToRouteParams();
        this.detectDevice();
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
        this.faHeart = faHeart;
        this.favoritesLoginText = this.i18nService.get('global.form.favoriteSignIn');
        this.showMoreLoginText = this.i18nService.get('global.form.defaultSignInMessage');
        this.updateListingNow();
        this.defaultCoworkingType = this.listingSearchService.findCoworkingTypeFromSearch();
    };
    ListingDetailComponent.prototype.isFavoriteListing = function () {
        return this.listing ? this.listing.favorite : false;
    };
    ListingDetailComponent.prototype.setFavoriteLisitng = function () {
        var _this = this;
        if (!this.isLoggedIn) {
            this.openLoginDialog(this.favoritesLoginText)
                .afterClosed()
                .subscribe(function () {
                if (_this.isLoggedIn) {
                    _this.toggleFavorite();
                }
            });
        }
        else {
            this.toggleFavorite();
        }
    };
    ListingDetailComponent.prototype.toggleFavorite = function () {
        var _this = this;
        this.listingService.toggleFavoriteListing(this.listing, this.listing.favorite || false).subscribe(function () {
            var updatedListing = tslib_1.__assign({}, _this.listing, { favorite: !_this.listing.favorite });
            _this._eventChecker.updateListing$.next(updatedListing);
            _this.listing = updatedListing;
        });
    };
    ListingDetailComponent.prototype.openLoginDialog = function (text) {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: text
        });
    };
    ListingDetailComponent.prototype.openShowMoreLogInModal = function (id) {
        var _this = this;
        var subscribeDialog = this.dialog.open(SubscribeModalComponent, {
            width: '400px',
            panelClass: 'my-custom-dialog-class',
            data: id
        });
        subscribeDialog.afterClosed().subscribe(function (val) {
            if (val != undefined) {
                switch (val) {
                    case 1:
                        _this.showBrokerWhatsapp(val);
                        break;
                    case 2:
                        _this.navigateToBroker(_this.listing.offeredByUser.id, val);
                        break;
                    case 3:
                        _this.showContactEmail(val);
                        break;
                    case 4:
                        _this.showContactPhone(val);
                        break;
                    default:
                        return null;
                }
            }
        });
    };
    ListingDetailComponent.prototype.getPropSubTypeText = function () {
        var propSubTypeTxt = this.i18nService.get(this.propertyType);
        if (this.listing) {
            if (this.listing.isForLeaseSale === ListingOfferType.Lease ||
                this.listing.isForLeaseSale === ListingOfferType.Sale ||
                this.listing.isForLeaseSale === ListingOfferType.Coworking) {
                var translationKey = this.listing.isForLeaseSale === ListingOfferType.Lease
                    ? 'global.list-your-property.forLease'
                    : this.listing.isForLeaseSale === ListingOfferType.Sale
                        ? 'global.list-your-property.forSale'
                        : this.listing.isForLeaseSale === ListingOfferType.Coworking
                            ? 'global.list-your-property.forCoworking'
                            : '';
                propSubTypeTxt = propSubTypeTxt.concat(' ' + this.i18nService.get(translationKey));
                if (this.listing.propertySubTypes) {
                    propSubTypeTxt = propSubTypeTxt.concat(this.addColon(this.listing.propertySubTypes));
                    if (this.listingSubtypes) {
                        propSubTypeTxt = propSubTypeTxt.concat(' ' + this.listingSubtypes);
                    }
                }
            }
        }
        return propSubTypeTxt;
    };
    ListingDetailComponent.prototype.getBuildingTitle = function () {
        if (this.listing && this.listing.building) {
            return this.i18nService.getTranslation(this.listing.propertyName);
        }
        return '';
    };
    ListingDetailComponent.prototype.calculateCoworkingValuesByNavigation = function () {
        if (this.coworkingPositionsSeachParam) {
            this.listing.coworkingPositions = this.coworkingPositionsSeachParam;
            if (this.listing.coworkingDailyPrice) {
                this.listing.coworkingDailyPrice = this.listing.coworkingDailyPrice * this.listing.coworkingPositions;
            }
            if (this.listing.coworkingMonthlyPrice) {
                this.listing.coworkingMonthlyPrice = this.listing.coworkingMonthlyPrice * this.listing.coworkingPositions;
            }
        }
    };
    ListingDetailComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    };
    ListingDetailComponent.prototype.detectDevice = function () {
        var os = this.deviceDetectorService.os;
        this.isAndroid = os.toLowerCase() === 'android';
    };
    ListingDetailComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    ListingDetailComponent.prototype.openEditListingMenu = function (event, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        return [4 /*yield*/, this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data)];
                    case 1:
                        data = _a.sent();
                        this.dialogRef = this.dialog.open(MyListingEditComponent, {
                            height: 'auto',
                            width: '550px',
                            data: data ? { data: data, myListingPage: false } : null
                        });
                        this.dialogRef.afterClosed().subscribe(function (result) { });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.getNonPreviewListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var baseId, newDetailsURL, lang, result, currentURL, isOffice, _a, detailsMetaTags, fullTitleText, keywordsMetaTag, descText, locale, listingTitle, fullDetailsURL, tags, activeIds, latitude, longitude, mapImgForOneListing, customSize, heightAspectRatio, scale, staticMapImageUrl, googleMapImage, e_1;
                    var _this = this;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 7, , 8]);
                                baseId = paramMap.baseId;
                                this.listingId = paramMap.listingId;
                                if (isNaN(baseId)) {
                                    baseId = this.listingId;
                                    this.listingId = undefined;
                                }
                                newDetailsURL = '';
                                lang = this.i18nService.getCurrentLanguage();
                                this.listingId = this.listingId ? this.listingId : -1;
                                return [4 /*yield*/, this.listingService
                                        .getListingDetailsSummarized(baseId, this.listingId, lang)
                                        .toPromise()];
                            case 1:
                                result = _b.sent();
                                if (!result) return [3 /*break*/, 5];
                                if (result.mostRecentActiveListing && result.newDetailsURL) {
                                    newDetailsURL = result.newDetailsURL;
                                    this.listingId = result.mostRecentActiveListing;
                                    if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                        newDetailsURL = this.i18nService.addCurrentLanguageToPath(newDetailsURL);
                                    }
                                    currentURL = window.location.href;
                                    if (currentURL && !currentURL.endsWith(newDetailsURL)) {
                                        this._location.go(newDetailsURL);
                                    }
                                }
                                else {
                                    this.navigateToNotFoundPage();
                                    return [2 /*return*/];
                                }
                                isOffice = this.commonService.isPropertyType(2001, result.listing);
                                this.listing = result.listing;
                                this.calculateCoworkingValuesByNavigation();
                                this.setSharedPrivatePositionsText();
                                this.listing.premiumAgents = result.premiumAgents;
                                this.listingFaIcon = this.listing && this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
                                this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
                                this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(result.listing.building.buildingType.id);
                                if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
                                    this.listing.propertySubTypes = this.commonService.translateLocaleText(this.listing.propertySubTypes, 'name');
                                    this.listingSubtypes = this.commonService.transformArrayStringList(this.listing.propertySubTypes, 'name');
                                }
                                this.marker = { lat: this.listing.building.latitude, lng: this.listing.building.longitude };
                                _a = this;
                                return [4 /*yield*/, this.listingService.getListingPhotos(this.listingId).toPromise()];
                            case 2:
                                _a.listingPhotos = _b.sent();
                                detailsMetaTags = this.buildTitleMetatag(this.listing);
                                return [4 /*yield*/, detailsMetaTags];
                            case 3:
                                fullTitleText = (_b.sent()).metaTagTitle;
                                return [4 /*yield*/, detailsMetaTags];
                            case 4:
                                keywordsMetaTag = (_b.sent()).keywordsMetaTag;
                                descText = this.removeHTMLCode(this.i18nService.getTranslation(this.listing.comments));
                                locale = this.i18nService.getCurrentLocale();
                                listingTitle = this.i18nService.getTranslation(this.listing.title);
                                fullDetailsURL = "" + environment.spotServerUrl + newDetailsURL;
                                tags = [
                                    //change to be same as title
                                    { name: 'name', content: encodeURIComponent(fullTitleText) },
                                    //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
                                    { name: 'og:locale', content: [locale] },
                                    { name: 'keywords', content: keywordsMetaTag },
                                    { name: 'title', content: [fullTitleText] },
                                    {
                                        name: 'mobile-web-app-title',
                                        content: encodeURIComponent(fullTitleText)
                                    },
                                    { name: 'description', content: [descText] },
                                    { name: 'author', content: 'SiiLA' },
                                    { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
                                    { name: 'og:site_name', content: 'SiiLA SPOT' },
                                    { name: 'og:type', content: 'article' },
                                    { name: 'og:title', content: [listingTitle] },
                                    { name: 'og:description', property: 'og:description', content: descText },
                                    { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
                                    {
                                        name: 'og:image',
                                        property: 'og:image',
                                        content: this.listingPhotos && this.listingPhotos.length
                                            ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.getCroppedOrOriginalImage(this.listingPhotos[0])))
                                            : null
                                    },
                                    {
                                        name: 'image',
                                        content: this.listingPhotos && this.listingPhotos.length
                                            ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.getCroppedOrOriginalImage(this.listingPhotos[0])))
                                            : null
                                    },
                                    { name: 'twitter:card', content: "summary_large_image" },
                                    { name: 'twitter:title', content: [listingTitle] },
                                    {
                                        name: 'twitter:image',
                                        content: this.listingPhotos && this.listingPhotos.length
                                            ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.getCroppedOrOriginalImage(this.listingPhotos[0])))
                                            : null
                                    },
                                    { name: 'twitter:description', content: descText }
                                ];
                                this.metaService.setDynamicTags(tags, fullTitleText);
                                activeIds = this.getPositionData();
                                this.currentDetailPosition(activeIds);
                                if (this.listingPhotos && this.listingPhotos.length > 0 && this.listingPhotos.length <= 2) {
                                    latitude = this.listing.building.latitude;
                                    longitude = this.listing.building.longitude;
                                    mapImgForOneListing = this.listingPhotos.length == 1 ? true : false;
                                    customSize = '';
                                    if (this.isMobile && this.screenWidth) {
                                        heightAspectRatio = Math.ceil((this.screenWidth * 3) / 4);
                                        customSize = this.screenWidth + "x" + heightAspectRatio;
                                    }
                                    else {
                                        customSize = mapImgForOneListing ? this.googleMapSizeOnePhoto : this.googleMapSize;
                                    }
                                    scale = mapImgForOneListing ? 1 : 1;
                                    staticMapImageUrl = this.commomMapSvc.generateStaticSatelliteMap(latitude, longitude, customSize, scale);
                                    googleMapImage = {
                                        google: true,
                                        customSize: customSize,
                                        url: staticMapImageUrl,
                                        mapImgForOneListing: mapImgForOneListing
                                    };
                                    this.listingPhotos.push(googleMapImage);
                                }
                                this.loading = false;
                                return [3 /*break*/, 6];
                            case 5:
                                this.navigateToNotFoundPage();
                                return [2 /*return*/];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                e_1 = _b.sent();
                                if (e_1) {
                                    this.navigateToNotFoundPage();
                                    setTimeout(function () {
                                        _this.metaService.setNonFoundTags();
                                    }, 300);
                                }
                                return [3 /*break*/, 8];
                            case 8: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    ListingDetailComponent.prototype.navigateToNotFoundPage = function () {
        var state = {
            badURL: window.location.href
        };
        this.router.navigateByUrl('/not-found', { state: state });
    };
    /**
     *  Use the following pattern:
        <listing type> | <property type> | <neighborhood> | <building name> | SiiLA SPOT
  
        full example = "Lease | Corporate Floor, Corporate Office Space | Faria Lima | Aqwa Corporate - Fase 1 | SiiLA SPOT"
     */
    ListingDetailComponent.prototype.buildTitleMetatag = function (listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metaTagTitle, listingTypeKey, listingTypeTag, propertyTypeTag, propertyTypeKey, propertySubTypeTags, propertySubTypesWithComma, propertyTypeTitleTag, neighborhood, buildingName, keywordsMetaTag, companyName, brokerId, keywordsMetaTags;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metaTagTitle = '';
                        listingTypeKey = listing.isForLeaseSale === ListingOfferType.Lease
                            ? 'global.list-your-property.lease'
                            : listing.isForLeaseSale === ListingOfferType.Sale
                                ? 'global.list-your-property.sale'
                                : listing.isForLeaseSale === ListingOfferType.Coworking
                                    ? 'global.list-your-property.coworking'
                                    : '';
                        listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18nService.get(listingTypeKey).toLowerCase());
                        propertyTypeTag = '';
                        propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
                        propertyTypeTag = this.i18nService.get(propertyTypeKey);
                        propertySubTypeTags = null;
                        propertySubTypesWithComma = null;
                        if (listing.propertySubTypes && listing.propertySubTypes.length > 0) {
                            propertySubTypeTags = listing.propertySubTypes.map(function (sub) { return sub.name; }).join(' | ');
                            propertySubTypesWithComma = listing.propertySubTypes.map(function (sub) { return sub.name; }).join();
                        }
                        propertyTypeTitleTag = propertySubTypeTags ? propertySubTypeTags : propertyTypeTag;
                        neighborhood = listing.neighborhood;
                        buildingName = this.i18nService.getTranslation(listing.propertyName);
                        metaTagTitle = buildingName + " | " + listingTypeTag + " | " + propertyTypeTitleTag + " | " + neighborhood + " | SiiLA SPOT";
                        keywordsMetaTag = '';
                        companyName = '';
                        if (!listing.offeredByUser) return [3 /*break*/, 2];
                        brokerId = listing.offeredByUser.id;
                        return [4 /*yield*/, this.getUserInfo(brokerId, this.listingId).then(function (broker) {
                                companyName = broker.companyName;
                                return companyName;
                            })];
                    case 1:
                        companyName = _a.sent();
                        _a.label = 2;
                    case 2:
                        keywordsMetaTags = [];
                        keywordsMetaTags.push(buildingName);
                        keywordsMetaTags.push(listingTypeTag);
                        keywordsMetaTags.push(propertyTypeTag);
                        if (propertySubTypesWithComma) {
                            keywordsMetaTags.push(propertySubTypesWithComma);
                        }
                        keywordsMetaTags.push(neighborhood);
                        keywordsMetaTags.push(companyName);
                        keywordsMetaTag = keywordsMetaTags.join();
                        return [2 /*return*/, { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag }];
                }
            });
        });
    };
    ListingDetailComponent.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    ListingDetailComponent.prototype.getGoogleMapImageOrCroppedOrOriginalImage = function (photo) {
        var imgOrUrl = photo && photo.image ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : photo.url;
        return imgOrUrl;
    };
    ListingDetailComponent.prototype.getSlideHeightClass = function (photo) {
        var className = 'slide-height';
        if (photo.google && photo.customSize) {
            className = photo.mapImgForOneListing ? 'one-photo-static-map' : 'slide-height';
        }
        return className;
    };
    ListingDetailComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    ListingDetailComponent.prototype.openListYourSpotDialogue = function () {
        var _this = this;
        this.isLoggedIn$.pipe(take(1)).subscribe(function (val) {
            if (val) {
                _this.openListingMenu();
            }
            else {
                _this.loginFormDialog.openDialog('my-listing');
            }
        });
    };
    ListingDetailComponent.prototype.openListingMenu = function () {
        this.dialogRef = this.dialog.open(MyListingMenuComponent, {
            height: 'auto',
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    ListingDetailComponent.prototype.updateListingNow = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.updateSubscription = this._eventChecker.updateListing$.subscribe(function (listing) {
                    if (listing === 'listing-detail') {
                        _this.getNonPreviewListing();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ListingDetailComponent.prototype.desktopShare = function () {
        var copyText = document.getElementById('shareLink');
        copyText.value = window.location.href;
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand('copy');
        alert('Copied the text: ' + copyText.value);
    };
    ListingDetailComponent.prototype.getPreviewListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.listingService.getListingById(this.createdListing.id).toPromise()];
                    case 1:
                        list = _a.sent();
                        this.listingService
                            .getListingDetails(this.createdListing.id)
                            .toPromise()
                            .then(function (result) {
                            _this.listing = result.listing;
                            _this.calculateCoworkingValuesByNavigation();
                            _this.setSharedPrivatePositionsText();
                            _this.listing.premiumAgents = result.premiumAgents;
                            var isOffice = _this.commonService.isPropertyType(2001, result.listing);
                            _this.listingFaIcon = _this.listing && _this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
                            _this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
                            _this.propertyType = _this.propertyHelperService.getPropertyTypeTranslationName(result.listing.building.buildingType.id);
                            if (_this.listing.propertySubTypes && _this.listing.propertySubTypes.length) {
                                _this.listing.propertySubTypes = _this.commonService.translateLocaleText(_this.listing.propertySubTypes, 'name');
                                _this.listingSubtypes = _this.commonService.transformArrayStringList(_this.listing.propertySubTypes, 'name');
                            }
                        });
                        this.marker = { lat: list.building.latitude, lng: list.building.longitude };
                        this.listingPhotos = this.sortListingPhotosByOrderId(this.imgList);
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error loading preview listing page: ', error_1);
                        this.router.navigateByUrl('/');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.sortListingPhotosByOrderId = function (listingPhotos) {
        if (listingPhotos == null) {
            return null;
        }
        return listingPhotos.sort(function (photo1, photo2) { return (photo1.orderId > photo2.orderId ? 1 : -1); });
    };
    ListingDetailComponent.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.error('roles undefined --> ', e);
        }
    };
    ListingDetailComponent.prototype.hideImageDots = function (index) {
        if (this.screenWidth > 700 &&
            (index === this.listingPhotos.length - 1 || index === this.listingPhotos.length - 2)) {
            return true;
        }
        if (this.screenWidth < 700 && this.screenWidth > 500 && index === this.listingPhotos.length - 1) {
            return true;
        }
    };
    ListingDetailComponent.prototype.checkArrows = function () {
        if (this.screenWidth > 700 && this.listingPhotos.length <= 3) {
            return false;
        }
        else if (this.screenWidth > 500 && this.screenWidth < 700 && this.listingPhotos.length <= 2) {
            return false;
        }
        else {
            return true;
        }
    };
    ListingDetailComponent.prototype.getMeasurementValue = function () {
        if (this.listing.measurementStandard) {
            return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
        }
        else {
            return this.i18nService.get('global.coworking.availableSpace');
            //return 'Available Space!';
        }
    };
    ListingDetailComponent.prototype.checkScreenWidth = function () {
        if (this.screenWidth > 700) {
            return this.slideOpts;
        }
        else if (this.screenWidth < 500) {
            return this.slideOptsMobile;
        }
        else if (this.screenWidth > 500 && this.screenWidth < 700) {
            return this.slideOptsTwo;
        }
    };
    ListingDetailComponent.prototype.showBrokerWhatsapp = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(1);
                return;
            }
        }
        this.whatsappPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    };
    ListingDetailComponent.prototype.navigateToBroker = function (id, notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(2);
                return;
            }
        }
        this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL);
        this.router.navigateByUrl("/listing-user/" + id, { state: { listingId: this.listing.id } });
    };
    ListingDetailComponent.prototype.showContactEmail = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(3);
                return;
            }
        }
        this.contactEmail = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    };
    ListingDetailComponent.prototype.showContactPhone = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(4);
                return;
            }
        }
        this.contactPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
    };
    ListingDetailComponent.prototype.registerUserActivity = function (userActivityType) {
        if (this.listing.offeredByUser) {
            var userActivity = {
                module: userActivityType,
                brokerId: this.listing.offeredByUser.id,
                listingId: this.listing.id
            };
            this.userActivityService.saveUserActivity(userActivity);
            // 'send_to': 'AW-689462276/eHv9CJeNrrcBEIS44cgC',
            //         'event_callback': callback
            // }')
        }
    };
    ListingDetailComponent.prototype.rejectListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transitionTo, action;
            return tslib_1.__generator(this, function (_a) {
                transitionTo = 'draft';
                //  if the admin rejects the modifications the modified listing should go to Revision status and the original listing should go to Active/Revision.
                //  priorListingId should be related to the active one.
                if (this.listing.listingStatus.id === 1 && this.listing.priorListingId) {
                    transitionTo = 'revision';
                }
                action = this.purchaseService.applyAction(this.listing.id, transitionTo);
                if (action) {
                    if (this.currentPosition && this.currentPosition.hasNext) {
                        this.navigateToNext();
                    }
                    else {
                        this.router.navigateByUrl('user-home/my-listings');
                        this._eventChecker.updateListing$.next('refresh');
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ListingDetailComponent.prototype.approveListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var action, error_2, message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.purchaseService.approveListing(this.listing.id)];
                    case 1:
                        action = _a.sent();
                        if (action) {
                            if (this.currentPosition && this.currentPosition.hasNext) {
                                this.navigateToNext();
                                this._eventChecker.updateListing$.next(action);
                            }
                            else {
                                this.router.navigateByUrl('user-home/my-listings');
                                this._eventChecker.updateListing$.next('refresh');
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        message = this.i18nService.get('global.approvingErrorMessage');
                        this.showMessage(null, message, false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.showMessage = function (header, message, keepUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var okText, alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        okText = this.i18nService.get('global.OK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: header,
                                message: message,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.subscribeToRouteParams = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.previewListing) {
                    this.getPreviewListing();
                }
                else {
                    this.getNonPreviewListing();
                }
                return [2 /*return*/];
            });
        });
    };
    ListingDetailComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    ListingDetailComponent.prototype.getUrlUpdate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.i18nService.getCurrentLanguage();
                        return [4 /*yield*/, this.listingService.checkListingDetailRoute(this.listingId, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                        }
                        return [2 /*return*/, detailsURL];
                }
            });
        });
    };
    ListingDetailComponent.prototype.shareLink = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userActivityService.trackListingShare(this.listing.id);
                        return [4 /*yield*/, this.getUrlUpdate()];
                    case 1:
                        res = _a.sent();
                        this.ngNavigatorShareService
                            .share({
                            title: this.getI18nValue(this.listing.title),
                            text: this.i18nService.get('global.listing-card.shareLinkText'),
                            url: environment.spotServerUrl + res.concat(this.i18nService.getLanguagePathURL())
                        })
                            .catch(function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var result, toast;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!error) return [3 /*break*/, 3];
                                        result = this.copyTextToClipboard(environment.spotServerUrl + res.concat(this.i18nService.getLanguagePathURL()));
                                        if (!result) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.toastCtrl.create({
                                                message: this.i18nService.get('global.listing-card.desktopShareText'),
                                                duration: 3500,
                                                animated: true,
                                                keyboardClose: true,
                                                cssClass: 'toast-alert'
                                            })];
                                    case 1:
                                        toast = _a.sent();
                                        toast.onDidDismiss().then(function () { });
                                        return [4 /*yield*/, toast.present()];
                                    case 2: return [2 /*return*/, _a.sent()];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.copyTextToClipboard = function (text) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var txtArea, successful, msg;
            return tslib_1.__generator(this, function (_a) {
                txtArea = document.createElement('textarea');
                txtArea.id = 'txt';
                txtArea.style.position = 'fixed';
                txtArea.style.top = '0';
                txtArea.style.left = '0';
                txtArea.style.opacity = '0';
                txtArea.value = text;
                document.body.appendChild(txtArea);
                txtArea.select();
                try {
                    successful = document.execCommand('copy');
                    msg = successful ? 'successful' : 'unsuccessful';
                    if (successful) {
                        return [2 /*return*/, true];
                    }
                }
                catch (err) {
                }
                finally {
                    document.body.removeChild(txtArea);
                }
                return [2 /*return*/, false];
            });
        });
    };
    ListingDetailComponent.prototype.getLargeThumbnail = function (id) {
        return this.imgService.largeThumbnail(id);
    };
    ListingDetailComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    ListingDetailComponent.prototype.getMediumThumbnail = function (id, dimensions) {
        return this.imgService.mediumThumbnail(id, dimensions);
    };
    ListingDetailComponent.prototype.navigateToNext = function () {
        if (this.currentPosition && this.currentPosition.hasNext) {
            var nextArticle = this.currentPosition.listReference[this.currentPosition.currentIndex + 1];
            this.redirectTo(nextArticle);
        }
    };
    ListingDetailComponent.prototype.navigateToPrev = function () {
        if (this.currentPosition && this.currentPosition.hasPrevious && this.currentPosition.currentIndex) {
            var previousArticle = this.currentPosition.listReference[this.currentPosition.currentIndex - 1];
            this.redirectTo(previousArticle);
        }
    };
    ListingDetailComponent.prototype.currentDetailPosition = function (list) {
        var _this = this;
        if (list) {
            var detailIndex = list.findIndex(function (id) {
                return id == _this.listingId;
            });
            var currentPosition = {
                hasPrevious: detailIndex > 0,
                currentIndex: detailIndex,
                hasNext: detailIndex < list.length - 1,
                listReference: list
            };
            this.currentPosition = currentPosition;
        }
    };
    ListingDetailComponent.prototype.getPositionData = function () {
        return this.detailPositionService.getDetailPosition;
    };
    ListingDetailComponent.prototype.navigateToSearch = function () {
        this.router.navigateByUrl(this.findSearchURLPage());
    };
    ListingDetailComponent.prototype.routeToSearchPage = function () {
        var searchPage = this.findSearchURLPage();
        var searchPageURL = searchPage.split('/');
        if (this.i18nService.currentUrlContainsCurrentLanguage()) {
            searchPageURL = this.i18nService.addCurrentLanguageToPath(searchPage).split('/');
        }
        if (!searchPageURL[0].startsWith('/')) {
            searchPageURL[0] = '/'.concat(searchPageURL[0]);
        }
        return searchPageURL;
    };
    ListingDetailComponent.prototype.findSearchURLPage = function () {
        var lastVisitedPage = this.listingSearchService.readLastSearchPageFromStorage();
        if (this.myListingPage) {
            return 'user-home/my-listings';
        }
        else if (this.myListingAnalytics) {
            return 'user-home/listing-analytics';
        }
        else if (lastVisitedPage === SelectedFilterKey.FAVORITES_SEARCH) {
            return '/search/favorites';
        }
        else {
            return '/search';
        }
    };
    ListingDetailComponent.prototype.getSearchLabel = function () {
        var state = this._location.getState();
        var searchDto = this.listingSearchService.readSearchDTOFromStorage(SelectedFilterKey.REGULAR_SEARCH, null);
        if (searchDto != null) {
            return 'global.menu.returnToSearch';
        }
        else {
            return 'global.menu.newSearch';
        }
    };
    ListingDetailComponent.prototype.redirectTo = function (uri, navi) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = {
                            myListingAnalytics: this.myListingAnalytics,
                            myListingPage: this.myListingPage,
                            searchTags: this.searchTags,
                            searchCriteria: this.searchDTO,
                            checkBoxValues: this.checkBoxValues,
                            localStorageKey: this.localStorageKey
                        };
                        lang = this.i18nService.getCurrentLanguage();
                        return [4 /*yield*/, this.listingService.checkListingDetailRoute(uri, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                        }
                        this.router.navigateByUrl(detailsURL, { state: state });
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingDetailComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) { return (_this.currentPhotoIndex = index); });
    };
    ListingDetailComponent.prototype.getPreviousPhoto = function () {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listingPhotos.length - 1);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    };
    ListingDetailComponent.prototype.getNextPhoto = function () {
        if (this.screenWidth > 700) {
            if (this.currentPhotoIndex === this.listingPhotos.length - 3) {
                this.photoSlider.slideTo(0);
            }
            else {
                this.photoSlider.slideNext();
            }
        }
        else if (this.screenWidth > 500 && this.screenWidth < 700) {
            if (this.currentPhotoIndex === this.listingPhotos.length - 2) {
                this.photoSlider.slideTo(0);
            }
            else {
                this.photoSlider.slideNext();
            }
        }
        else {
            if (this.currentPhotoIndex === this.listingPhotos.length - 1) {
                this.photoSlider.slideTo(0);
            }
            else {
                this.photoSlider.slideNext();
            }
        }
        this.updatePhotoIndex();
    };
    ListingDetailComponent.prototype.addColon = function (propertySubTypes) {
        return propertySubTypes && propertySubTypes.length ? ':' : '';
    };
    ListingDetailComponent.prototype.isHeaderVisible = function (event) {
        this.displayScrollToTop = event.visible ? false : true;
    };
    ListingDetailComponent.prototype.scrollToTop = function (elem) {
        elem.scrollIntoView();
    };
    ListingDetailComponent.prototype.getDefaultTranslation = function (translationArtifact) {
        try {
            var artifact = JSON.parse(translationArtifact);
            if (window.location.href.endsWith('/pt-br') || !window.location.href.endsWith('/en')) {
                return artifact['pt-br'] ? artifact['pt-br'] : artifact['en'];
            }
            else {
                return artifact['en'] ? artifact['en'] : artifact['pt-br'];
            }
        }
        catch (e) {
            var error = e + " : " + translationArtifact;
            console.error(error);
        }
    };
    ListingDetailComponent.prototype.setSharedPrivatePositionsText = function () {
        this.showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
        this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(this.listing, this.showCoworkingPrivate);
    };
    ListingDetailComponent.prototype.clickToChat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullListingDetailsURL, headerKey, headerMsg, msgToUserAgent, message;
            return tslib_1.__generator(this, function (_a) {
                fullListingDetailsURL = encodeURI(this.buildUrl());
                headerKey = 'global.listing-detail.clickToChat.whatsAppMsgHeader';
                headerMsg = this.i18nService.get(headerKey);
                msgToUserAgent = this.i18nService.get('global.listing-detail.clickToChat.msgToUserAgent');
                message = headerMsg + " " + fullListingDetailsURL + "\n\n" + msgToUserAgent;
                this.whatsAppService.sendMessageViaWhataspp(this.listing.offeredByUser.whatsappNumber, message);
                return [2 /*return*/, false];
            });
        });
    };
    ListingDetailComponent.prototype.buildUrl = function () {
        return window.location.href.concat(this.i18nService.getLanguagePathURL());
    };
    ListingDetailComponent.prototype.clickToEmail = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultMsg, message, popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultMsg = this.i18nService.get('global.listing-detail.clickToEmail.msgToUserAgent');
                        message = "" + defaultMsg;
                        return [4 /*yield*/, this.agentNotPopOverCtrl.create({
                                component: AgentNotificationComponent,
                                event: ev,
                                cssClass: 'popover-agent-email',
                                componentProps: {
                                    defaultMsg: message,
                                    brokerId: this.listing.offeredByUser.id,
                                    listingId: this.listing.id,
                                    popupCtrl: this.agentNotPopOverCtrl,
                                    pageURLFrom: encodeURI(this.buildUrl())
                                }
                            })];
                    case 1:
                        popover = _a.sent();
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingDetailComponent.prototype.getParentElem = function (elems) {
        var elem = null;
        for (var i = 0; i < elems.length; i++) {
            if (elems[i].scrollTop > 0) {
                elem = elems[i];
            }
        }
        return elem;
    };
    ListingDetailComponent.prototype.isEndOfPage = function (element) {
        return Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight;
    };
    ListingDetailComponent.prototype.animateScroll = function (parentElement, target, durationPerClick) {
        var _this = this;
        var isAtTarget = parentElement.scrollTop === target || this.isEndOfPage(parentElement);
        if (isAtTarget) {
            return;
        }
        setTimeout(function () {
            var isWithinTarget = parentElement.scrollTop + durationPerClick <= target;
            parentElement.scrollTop = isWithinTarget ? parentElement.scrollTop + durationPerClick : target;
            _this.animateScroll(parentElement, target, durationPerClick);
        }, durationPerClick);
    };
    ListingDetailComponent.prototype.scrollToView = function () {
        var _this = this;
        setTimeout(function () {
            var parentElement = document.getElementsByTagName('MAT-SIDENAV-CONTENT');
            var targetElement = document.getElementById('listingCardView');
            var deltaElem = _this.getParentElem(parentElement);
            var offset = window.outerWidth <= 500 ? 80 : 120;
            var targetPosition = Math.round(targetElement.getBoundingClientRect().top) + Math.round(deltaElem.scrollTop) - offset;
            _this.animateScroll(deltaElem, targetPosition, 10);
        }, 500);
    };
    ListingDetailComponent.prototype.removedTagsFromNearBy = function (searchFilter) {
        this.searchTags = searchFilter.searchTags;
        this.searchDTO = searchFilter.nearbySearchCriteria.searchDTO;
        this.checkBoxValues = searchFilter.nearbySearchCriteria.checkBoxValues;
    };
    ListingDetailComponent.prototype.getUserInfo = function (brokerId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingService
                            .getUserById(brokerId, listingId)
                            .toPromise()
                            .then(function (broker) {
                            return broker;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingDetailComponent.prototype.isRentCondoIPTUNegotiables = function () {
        return ((!this.listing.monthlyRent || this.listing.monthlyRent == 0) &&
            (!this.listing.monthlyCondoFee || this.listing.monthlyCondoFee == 0) &&
            (!this.listing.monthlyIptu || this.listing.monthlyIptu == 0));
    };
    ListingDetailComponent.prototype.hasCoworkingSubType = function (listing) {
        return listing.isForLeaseSale === ListingOfferType.Coworking;
    };
    ListingDetailComponent.prototype.getListingArea = function () {
        if (this.listing &&
            this.listing.isForLeaseSale === 'L' &&
            this.listing &&
            this.listing.monthlyRent &&
            this.listing &&
            this.listing.monthlyRent != 0 &&
            !this.listing.coworkingMaxPositions &&
            this.listing.listingType.toLowerCase() != 'basic') {
            return this.listing.leaseableArea;
        }
        else if (this.listing &&
            this.listing.isForLeaseSale === 'S' &&
            this.listing &&
            this.listing.offeringPrice &&
            this.listing &&
            this.listing.offeringPrice != 0 &&
            !this.listing.coworkingMaxPositions &&
            this.listing.listingType.toLowerCase() != 'basic') {
            return this.listing.rentableArea;
        }
    };
    return ListingDetailComponent;
}());
export { ListingDetailComponent };
