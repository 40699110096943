import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { validateURL } from '@app/shared/util/url-validator';
import { EditCropImageModalComponent } from '@app/shared/edit-crop-image-modal/edit-crop-image-modal.component';
import { HttpClient } from '@angular/common/http';
import { MultipleFilesProgressBarComponent } from '@app/shared/multiple-files-progress-bar/multiple-files-progress-bar.component';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
var ImgUploadComponent = /** @class */ (function () {
    function ImgUploadComponent(http, imageService, commonService, sanitizer) {
        this.http = http;
        this.imageService = imageService;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.videoURLInvalid = false;
        this.vrTourSelected = new EventEmitter();
        this.imageFiles = [];
    }
    ImgUploadComponent.prototype.ngOnInit = function () { };
    ImgUploadComponent.prototype.convertToDataURL = function (imageUrl, callback) {
        var xhr = new XMLHttpRequest();
        this.filename = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = function () {
            var fileReader = new FileReader();
            fileReader.onloadend = function () {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    };
    ImgUploadComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    ImgUploadComponent.prototype.fileChangeEvent = function (event, remove) {
        var _this = this;
        var files = event.currentTarget.files;
        if (files.length > 20) {
            var infoModalParams = {
                headerTitle: 'global.uploadImage',
                bodyMessage: 'global.limitImagesSize'
            };
            this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
        }
        else {
            this.imageFiles = tslib_1.__spread(files);
            this.openMultipleFilesModal().then(function (r) {
                _this.saveAllFilesToImages();
            });
        }
    };
    ImgUploadComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) { });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    ImgUploadComponent.prototype.saveAllFilesToImages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var previousSizeImgList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                previousSizeImgList = this.imgList.length;
                Object.keys(this.imageFiles).forEach(function (i) {
                    var file = _this.imageFiles[i];
                    var mimeType = file.type;
                    if (mimeType.match(/image\/*/) === null) {
                        var message = 'Only images are supported.';
                        _this.imageFiles[i].errorMsg = message;
                        _this.imageFiles[i].uploaded = true;
                    }
                    else {
                        var reader_1 = new FileReader();
                        reader_1.readAsDataURL(file);
                        reader_1.onload = function (_event) {
                            var uploadedImg = {
                                img: reader_1.result,
                                content: file.name,
                                caption: '',
                                photoOrder: parseInt(i) + previousSizeImgList + 1
                            };
                            _this.saveImgToDatabase(uploadedImg).then(function (listingPhoto) {
                                _this.imgList.push(listingPhoto);
                                _this.imageFiles[i].uploaded = true;
                                _this.imgList = _this.sortListingPhotosByOrderId(_this.imgList);
                            });
                        };
                    }
                });
                this.imgList = this.sortListingPhotosByOrderId(this.imgList);
                return [2 /*return*/];
            });
        });
    };
    ImgUploadComponent.prototype.sortListingPhotosByOrderId = function (listingPhotos) {
        if (listingPhotos == null) {
            return null;
        }
        return listingPhotos.sort(function (photo1, photo2) { return (photo1.orderId > photo2.orderId ? 1 : -1); });
    };
    ImgUploadComponent.prototype.openMultipleFilesModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var componentProps, cssClass, modalProps, editImageModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            allImgFiles: this.imageFiles,
                            imgList: this.imgList
                        };
                        cssClass = 'multiple-images-modal';
                        modalProps = this.getModalProp(MultipleFilesProgressBarComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        editImageModal.onDidDismiss().then(function (popoverData) {
                            _this.imageFiles = [];
                            _this.clearFileInput(document.getElementById('inputFile'));
                            if (popoverData.data) {
                            }
                        });
                        return [2 /*return*/, editImageModal.present()];
                }
            });
        });
    };
    ImgUploadComponent.prototype.saveImgToDatabase = function (imgUploaded, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingPhoto;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uploadPhotoWithoutListing(imgUploaded, listingPhotoId)];
                    case 1:
                        listingPhoto = _a.sent();
                        return [2 /*return*/, listingPhoto];
                }
            });
        });
    };
    ImgUploadComponent.prototype.removeImage = function (ev, idx) {
        if (this.saveImgImmediately) {
            this.deleteListingPhoto(this.imgList[idx]);
            if (this.listing && this.listing.id) {
                var deletedListingPhoto = {
                    imageId: this.imgList[idx].image.id,
                    listingId: this.listing.id
                };
                try {
                    this.logListingPhotonDeletion(deletedListingPhoto);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
        this.imgList.splice(idx, 1);
    };
    ImgUploadComponent.prototype.logListingPhotonDeletion = function (deletedListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post('api/deletedphotolog/', deletedListingPhoto)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImgUploadComponent.prototype.deleteListingPhoto = function (listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete("api/listingPhotos/" + listingPhoto.id)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImgUploadComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    ImgUploadComponent.prototype.clearFileInput = function (ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    };
    ImgUploadComponent.prototype.doReorder = function (ev) {
        var _this = this;
        if (this.imgList.length === 1) {
            ev.detail.complete();
            return;
        }
        if (this.imgList.length <= ev.detail.to) {
            var itemMove_1 = this.imgList.splice(ev.detail.from, 1)[0];
            this.imgList.splice(ev.detail.to, 0, itemMove_1);
            ev.detail.complete();
            return;
        }
        Object.keys(this.imgList).forEach(function (key) {
            if (typeof _this.imgList[key] === 'undefined') {
                delete _this.imgList[key];
            }
        });
        var itemMove = this.imgList.splice(ev.detail.from, 1)[0];
        this.imgList.splice(ev.detail.to, 0, itemMove);
        this.imgList.forEach(function (listingPhoto, index) {
            var newOrderId = index + 1;
            if (listingPhoto.orderId != newOrderId) {
                _this.updateOrderOrCaptionFrom(listingPhoto.id, undefined, newOrderId);
                _this.imgList[index].orderId = newOrderId;
            }
        });
        ev.detail.complete();
    };
    // tslint:disable-next-line: member-ordering
    ImgUploadComponent.prototype.validateCustomURL = function () {
        var _this = this;
        this.videoURLInvalid = false;
        if (this.vrTourUrl) {
            var vrTourUrl2 = this.vrTourUrl.split('\n');
            if (vrTourUrl2.length > 2) {
                this.videoURLInvalid = true;
            }
            else {
                vrTourUrl2.forEach(function (item) {
                    if (!validateURL(item.trim())) {
                        _this.videoURLInvalid = true;
                    }
                });
            }
        }
        var urlValidation = {
            videoURLInvalid: this.videoURLInvalid,
            vrTourSelected: this.vrTourUrl
        };
        this.vrTourSelected.emit(urlValidation);
    };
    /** New images are handled with base 64. They are not still saved into db */
    ImgUploadComponent.prototype.isNewImage = function (imgToEdit) {
        return imgToEdit.img && !imgToEdit.image;
    };
    ImgUploadComponent.prototype.getImage = function (img) {
        if (this.isNewImage(img)) {
            if (img.croppedImage) {
                return img.croppedImage;
            }
            else {
                return img.img;
            }
        }
        else {
            if (img.croppedImage) {
                if (img.croppedImage.id) {
                    return this.getMediumImg(img.croppedImage.id);
                }
                else {
                    return img.croppedImage;
                }
            }
            return this.getMediumImg(img.image.id);
        }
    };
    ImgUploadComponent.prototype.getImageContent = function (img) {
        if (!img) {
            return '';
        }
        return this.isNewImage(img) ? img.content : img.image.content;
    };
    ImgUploadComponent.prototype.getModalProp = function (component, componentProps, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            cssClass: cssClass,
            component: component,
            componentProps: componentProps
        };
    };
    ImgUploadComponent.prototype.captionChangeListener = function (imgIndex, event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newCaption;
            return tslib_1.__generator(this, function (_a) {
                event.stopImmediatePropagation();
                newCaption = event.target.value;
                if (newCaption && this.imgList[imgIndex].caption != newCaption) {
                    this.imgList[imgIndex].caption = newCaption;
                    this.updateOrderOrCaptionFrom(this.imgList[imgIndex].id, newCaption);
                }
                return [2 /*return*/];
            });
        });
    };
    ImgUploadComponent.prototype.openEditImageModal = function (imgIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imgToEdit, newImageBased64, originalImageId, imageUrlToEdit, fileName, isNewImage, imgId, croppedImgId, componentProps, cssClass, modalProps, editImageModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.uploadingImages = true;
                        imgToEdit = this.imgList[imgIndex];
                        newImageBased64 = null;
                        originalImageId = null;
                        imageUrlToEdit = null;
                        fileName = null;
                        isNewImage = this.isNewImage(imgToEdit);
                        if (!isNewImage) {
                            imgId = imgToEdit.image.id;
                            if (imgToEdit.originalImage) {
                                originalImageId = imgToEdit.originalImage.id;
                            }
                            fileName = imgToEdit.image.content;
                            imageUrlToEdit = "/images/" + imgId + "/thumbnail/ml";
                            if (imgToEdit.croppedImage) {
                                imageUrlToEdit = null;
                                if (imgToEdit.croppedImage.id) {
                                    croppedImgId = imgToEdit.croppedImage.id;
                                    imageUrlToEdit = "/images/" + croppedImgId + "/thumbnail/ml";
                                }
                                else {
                                    newImageBased64 = imgToEdit.croppedImage;
                                }
                            }
                        }
                        else {
                            newImageBased64 = imgToEdit.croppedImage ? imgToEdit.croppedImage : imgToEdit.img;
                            fileName = imgToEdit.content;
                            if (imgToEdit.originalImageId) {
                                originalImageId = imgToEdit.originalImageId;
                            }
                        }
                        componentProps = {
                            newImageBased64: newImageBased64,
                            originalImageId: originalImageId,
                            imageUrlToEdit: imageUrlToEdit,
                            showCroppingToggle: true,
                            fileName: fileName
                        };
                        cssClass = 'edit-crop-image-modal';
                        modalProps = this.getModalProp(EditCropImageModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        editImageModal.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                var wasImageCropped = popoverData.data.wasImageCropped;
                                var newCroppedImage = popoverData.data.croppedImage;
                                var newOriginalImage = popoverData.data.originalImage;
                                var wasOriginalImgReplaced = popoverData.data.wasOriginalImgReplaced;
                                var wasRestoreToOriginalImage = popoverData.data.wasRestoreToOriginalImage;
                                var originalImageId_1 = popoverData.data.originalImageId;
                                if (newOriginalImage) {
                                    var content = newOriginalImage.file.name && newOriginalImage.file.name != 'null'
                                        ? newOriginalImage.file.name
                                        : 'New Image';
                                    if (isNewImage) {
                                        _this.imgList[imgIndex].img = newOriginalImage.imgBase64;
                                        _this.imgList[imgIndex].content = content;
                                        _this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                                        _this.imgList[imgIndex].originalImageId = originalImageId_1;
                                    }
                                    else {
                                        var caption = imgToEdit.image.caption;
                                        var originalImgId = imgToEdit.originalImage ? imgToEdit.originalImage.id : null;
                                        if (wasOriginalImgReplaced) {
                                            var imgToUpload = {
                                                img: newOriginalImage.imgBase64,
                                                content: content,
                                                caption: caption,
                                                croppedImage: newCroppedImage.imgBase64,
                                                originalImageId: originalImgId,
                                                photoOrder: _this.imgList[imgIndex].orderId
                                            };
                                            _this.saveImgToDatabase(imgToUpload, _this.imgList[imgIndex].id).then(function (listingPhoto) {
                                                _this.uploadingImages = false;
                                                _this.imgList[imgIndex] = listingPhoto;
                                            });
                                            //this.imgList[imgIndex]
                                        }
                                        else {
                                            // Save Cropped Image
                                            _this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
                                            _this.addCroppedImgtoListingPhoto(_this.imgList[imgIndex]).then(function (savedListingPhoto) {
                                                _this.uploadingImages = false;
                                                if (savedListingPhoto) {
                                                    _this.imgList[imgIndex] = savedListingPhoto;
                                                }
                                            });
                                            if (wasRestoreToOriginalImage && _this.imgList[imgIndex].originalImage) {
                                                _this.imgList[imgIndex].image = _this.imgList[imgIndex].originalImage;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ImgUploadComponent.prototype.uploadPhotoWithoutListing = function (imgToUpload, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, imgToFile, croppedFile;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (!imgToUpload.caption) {
                            imgToUpload.caption = '';
                        }
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgToUpload.img, imgToUpload.content)];
                    case 1:
                        imgToFile = _a.sent();
                        croppedFile = null;
                        if (!imgToUpload.croppedImage) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgToUpload.croppedImage, imgToUpload.content)];
                    case 2:
                        croppedFile = _a.sent();
                        _a.label = 3;
                    case 3:
                        formData.append('caption', imgToUpload.caption);
                        formData.append('photoOrder', imgToUpload.photoOrder);
                        formData.append('file', imgToFile);
                        formData.append('content', imgToUpload.content);
                        formData.append('croppedFile', croppedFile);
                        if (listingPhotoId) {
                            formData.append('listingPhotoId', listingPhotoId);
                        }
                        if (imgToUpload.originalImageId && imgToUpload.originalImageId != null) {
                            formData.append('originalImageId', imgToUpload.originalImageId);
                        }
                        return [2 /*return*/, this.http
                                .post("api/listingPhotos/no-listing/upload", formData)
                                .pipe(map(function (val) { return val; }))
                                .toPromise()];
                }
            });
        });
    };
    ImgUploadComponent.prototype.addCroppedImgtoListingPhoto = function (listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var croppedImage, croppedFile, caption;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        croppedImage = listingPhoto.croppedImage;
                        if (croppedImage == null) {
                            return [2 /*return*/, null];
                        }
                        if (!!croppedImage.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(croppedImage, listingPhoto.image.content)];
                    case 1:
                        croppedFile = _a.sent();
                        caption = listingPhoto.image.caption && listingPhoto.image.caption.length > 0
                            ? listingPhoto.image.caption
                            : listingPhoto.image.content;
                        return [4 /*yield*/, this.uploadCroppedImage(croppedFile, caption + "_cropped", listingPhoto.id).then(function (createdImages) {
                                return createdImages;
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, croppedImage];
                }
            });
        });
    };
    ImgUploadComponent.prototype.uploadCroppedImage = function (file, caption, listingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            return tslib_1.__generator(this, function (_a) {
                formData = new FormData();
                formData.append('listingPhotoId', listingPhotoId);
                formData.append('caption', caption);
                formData.append('file', file);
                return [2 /*return*/, this.http
                        .post("api/listingPhotos/cropped/", formData)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImgUploadComponent.prototype.updateOrderOrCaptionFrom = function (listingPhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch("api/listingPhotos/patch/" + listingPhotoId, { orderId: orderId, caption: caption })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    return ImgUploadComponent;
}());
export { ImgUploadComponent };
