import * as tslib_1 from "tslib";
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService, ImageService, PlatformHelperService, PropertyTypeHelperService } from '@app/core';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { IonSlides, ToastController } from '@ionic/angular';
import { faChevronRight, faChevronLeft, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { SpacesParameters, SpaceFilterCondition, SpaceConditionsTypes } from '@app/models/spot-buildings/spot-available-spaces';
import { CommonGenericService } from '@app/core/common.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { parseInt } from 'lodash';
import { environment } from '@env/environment';
import { SpotBuildingSpaceService } from '../../services/spaces.service';
import { IndustrialLeaseType } from '../../../models/spot-buildings/spot-available-spaces';
import { RequestInformationType } from '../../../models/request-information.model';
import { LocalNumberPipe } from '@app/shared/pipes/number-pipe';
import { RoutingHelperService } from '../../../core/helpers/routing-helper.service';
import { AuthenticationService } from '@app/core/auth';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { KoworkingTypes } from '@app/models/koworking-types.model';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
import { SizeIcon } from '../../../models/shared/shared-enums.model';
import { MetaService } from '@app/core/seo';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { SetSpacesComponent } from './set-spaces/set-spaces.component';
import { ReportSpacesComponent } from './report-spaces/report-spaces.component';
import { LoginFormDialogService } from '@app/shared/login';
import { HomePageAppService } from '../../../shared/services/homepageapp.service';
var BuildingsDetailComponent = /** @class */ (function () {
    function BuildingsDetailComponent(activatedRoute, createSpotBuildingsService, imgService, datepipe, i18NService, dialog, breakpointObserver, nearbyListingSvc, spotBuildingsService, propertyHelperService, commonService, popOverCtrlHelper, router, spacesService, _decimalPipe, platformConfigHelper, routingHelperSrv, auth, metaService, analyticsService, deviceDetector, userActivityService, homepageAppSrv, loginFormDialog, toastCtrl) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.imgService = imgService;
        this.datepipe = datepipe;
        this.i18NService = i18NService;
        this.dialog = dialog;
        this.breakpointObserver = breakpointObserver;
        this.nearbyListingSvc = nearbyListingSvc;
        this.spotBuildingsService = spotBuildingsService;
        this.propertyHelperService = propertyHelperService;
        this.commonService = commonService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.router = router;
        this.spacesService = spacesService;
        this._decimalPipe = _decimalPipe;
        this.platformConfigHelper = platformConfigHelper;
        this.routingHelperSrv = routingHelperSrv;
        this.auth = auth;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.deviceDetector = deviceDetector;
        this.userActivityService = userActivityService;
        this.homepageAppSrv = homepageAppSrv;
        this.loginFormDialog = loginFormDialog;
        this.toastCtrl = toastCtrl;
        this.isMobile = false;
        this.sticky = false;
        this.maxSticky = false;
        this.mapOptions = {};
        this.markers = [];
        this.ORANGE_PIN = this.nearbyListingSvc.orangePin;
        this.services = [];
        this.spacesParameters = [];
        this.originalSpaces = [];
        this.showShortDesciption = true;
        this.position1 = 0;
        this.position2 = 0;
        this.positionTop = 0;
        this.cont = 0;
        this.features = 0;
        this.hiddeMoreFeatures = false;
        this.topPosition = 0;
        this.floatingDivHeight = 0;
        this.p3 = 0;
        this.SpotPhotos = [];
        this.isAdmin = false;
        this.showShortSpace = true;
        this.hiddeMoreSpaces = false;
        this.contactEmail = false;
        this.whatsappPhone = false;
        this.contactEmail2 = false;
        this.whatsappPhone2 = false;
        this.filter = 0;
        this.spacesCoworking = [];
        this.coworkingTypes = new KoworkingTypes();
        this.filter1 = false;
        this.filter2 = false;
        this.filter3 = false;
        this.filter4 = false;
        this.seeSuit = true;
        this.seePositions = true;
        this.favSizeIcon = SizeIcon.LG;
        this.portfolioCount = -1;
        this.spaceOffMarket = [1, 2, 3, 4, 5, 6];
        this.count = 0;
        this.countSapces = 0;
        this.selectallSpaces = false;
        this.clickReport = false;
        this.clickSet = false;
        this.scrollEvent = function (event) {
            if (!_this.isMobile && _this.count != 0) {
                if (_this.cont == 0 || _this.position1 == 0) {
                    _this.cont++;
                    _this.position1 = document.getElementById('position1').getBoundingClientRect().top - 155;
                }
                if (document.getElementById('broker') &&
                    document.getElementById('position3') &&
                    document.getElementById('broker').getBoundingClientRect().top >
                        document.getElementById('position3').getBoundingClientRect().top) {
                    event.srcElement.scrollTop = 0;
                    _this.position1 = 0;
                    _this.sticky = false;
                    return;
                }
                if (event.srcElement.scrollTop > _this.position1) {
                    if (event.srcElement.scrollTop > _this.position1 &&
                        document.getElementById('position2').getBoundingClientRect().top >
                            document.getElementById('position3').getBoundingClientRect().height + 190) {
                        _this.sticky = true;
                        _this.maxSticky = false;
                    }
                    else {
                        if (document.getElementById('position2').getBoundingClientRect().top <=
                            document.getElementById('position3').getBoundingClientRect().height + 190) {
                            _this.sticky = false;
                            _this.maxSticky = true;
                        }
                    }
                }
                else {
                    _this.sticky = false;
                    _this.maxSticky = false;
                }
            }
            else {
                _this.count = 1;
            }
        };
        this.betweenFilter = function (value, minValue, maxValue) {
            return value >= minValue && value <= maxValue;
        };
        this.greaterOrEqualFilter = function (value, maxValue) {
            return value >= maxValue;
        };
        this.betweenDatesFilter = function (value, minValue, maxValue) {
            if (minValue == null) {
                return value <= maxValue;
            }
            return value >= minValue && value <= maxValue;
        };
        this.betweenPerAreaFilter = function (price, area, minValue, maxValue) {
            return area && area > 0 && price / area >= minValue && price / area <= maxValue;
        };
        this.equalsFilter = function (fieldValue, expectedValue) {
            return expectedValue && fieldValue ? fieldValue == expectedValue : true;
        };
        this.booleanFilter = function (fieldValue, expectedValue) { return (expectedValue ? fieldValue == expectedValue : true); };
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
    }
    BuildingsDetailComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sharedIcon = faShareAlt;
                        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
                        this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
                        this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
                        if (this.isDesktop.length) {
                            this.currentDevice = this.isDesktop;
                        }
                        else if (this.isMobilePhone.length) {
                            this.currentDevice = this.isMobilePhone;
                        }
                        else if (this.isTablet.length) {
                            this.currentDevice = this.isTablet;
                        }
                        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) {
                            _this.isLoggedIn = val;
                            if (_this.clickSet && _this.isLoggedIn) {
                                _this.clickReport = false;
                                _this.clickSet = false;
                                _this.openSetSpaces();
                            }
                            if (_this.clickReport && _this.isLoggedIn) {
                                _this.clickReport = false;
                                _this.clickSet = false;
                                _this.openReportSpaces();
                            }
                        });
                        this.mapOptions = {
                            singleMarker: false,
                            ignoreZoom: false
                        };
                        return [4 /*yield*/, this.getParamsUrl()];
                    case 1:
                        _a.sent();
                        this.getSpacesFilter();
                        window.addEventListener('scroll', this.scrollEvent, true);
                        this.isAdmin = this.checkSpotAdmin();
                        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuildingsDetailComponent.prototype.getSpacesFilter = function () {
        var navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras) {
            if (navigation.extras.state) {
                var localStorageKey = navigation.extras.state.localStorageKey;
                var state = navigation.extras.state;
            }
        }
        else {
            this.spacesFilter = this.spacesService.getSpacesFilter();
            this.spacesService.saveSpacesFilter(undefined);
        }
        this.currentCoworkingTypeFilter = this.spacesFilter ? this.spacesFilter.coworkingType : null;
    };
    BuildingsDetailComponent.prototype.generateNearByMarker = function () {
        var currentListingMarker = this.nearbyListingSvc.createBasicMarker(this.buildingDetail.building, this.ORANGE_PIN);
        this.markers = [currentListingMarker];
    };
    BuildingsDetailComponent.prototype.cssSticky = function () {
        return {
            position: 'fixed',
            top: '195px',
            overflow: 'hidden',
            'z-index': '10'
        };
    };
    BuildingsDetailComponent.prototype.cssMaxSticky = function () {
        return {
            position: 'absolute',
            bottom: '-10px'
        };
    };
    BuildingsDetailComponent.prototype.cssImgServiceMobile = function () {
        return {
            width: window.screen.width - 80 + 'px',
            height: ((window.screen.width - 80) / 4) * 3 + 'px'
        };
    };
    BuildingsDetailComponent.prototype.getParamsUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.buildingDetail = this.spotBuildingInput;
                    if (this.buildingDetail.building.status != 'Active') {
                        // redirect to SPOT home page
                        window.location.href = '/en';
                    }
                    this.registerUserActivity(UserActivityType.PAGEVIEW_LISTING, null, this.buildingDetail.id);
                    this.buildingTypeDTO = this.buildingDetail.building.buildingType;
                    this.isForIndustrial = this.buildingTypeDTO.id == 1001;
                    this.isForOffice = this.buildingTypeDTO.id == 2001;
                    this.spotBuildingsService.goToSpotDetailsURL(this.buildingDetail.id);
                    if (window.screen.width < 1000) {
                        this.isMobile = true;
                    }
                    this.sbSliderFilters = this.getSpotBuildingSliderFilters(this.spotBuildingInput);
                    this.sbSliderFilters.notSpotIds = [this.buildingDetail.id];
                    if (this.buildingDetail.comments) {
                        this.description = this.i18NService.getTranslation(this.buildingDetail.comments);
                    }
                    this.buildingDetail.propertyName = this.i18NService.getTranslation(this.buildingDetail.propertyName);
                    this.buildingDetail.building.address = this.i18NService.getTranslation(this.buildingDetail.building.address);
                    this.buildingDetail.building.buildingType.name =
                        this.buildingDetail.building.buildingType.id == 1001
                            ? this.propertyHelperService.getPropertyTypeTranslationName(1001)
                            : this.i18NService.getTranslation(this.buildingDetail.building.buildingType.name);
                    this.buildingDetail.address = this.i18NService.getTranslation(this.buildingDetail.address);
                    if (this.buildingDetail.building.buildingSubType) {
                        this.buildingDetail.building.buildingSubType.name = this.i18NService.getTranslation(this.buildingDetail.building.buildingSubType.name);
                    }
                    if (this.buildingDetail.industrialStatus) {
                        this.buildingDetail.industrialStatus.name = this.i18NService.getTranslation(this.buildingDetail.industrialStatus.name);
                    }
                    this.generateNearByMarker();
                    this.countFeatures();
                    if (this.buildingDetail && this.buildingDetail.entireSale) {
                        if (this.buildingDetail.investmentOpportunity) {
                            this.investmentOpportunity = this.i18NService.getTranslation(this.buildingDetail.investmentOpportunity);
                        }
                        this.capRate = this.buildingDetail.capRate
                            ? this.commonService.formatNumberTo(this.buildingDetail.capRate, 2)
                            : '0.00';
                        this.percentageLeased = this.buildingDetail.percentageLeased
                            ? this.commonService.formatNumberTo(this.buildingDetail.percentageLeased)
                            : '0.00';
                        if (this.buildingDetail.majorTenants && this.buildingDetail.majorTenants.length > 0) {
                            this.majorTenants = this.buildingDetail.majorTenants.split(',');
                            if (this.majorTenants.length > 3) {
                                this.majorTenants = this.majorTenants.slice(0, 3);
                            }
                        }
                    }
                    if (this.buildingDetail.buildingListingPhotos) {
                        this.SpotPhotos = this.buildingDetail.buildingListingPhotos.map(function (blp) {
                            var photo = {
                                id: blp.id,
                                croppedImageId: blp.croppedImage ? blp.croppedImage.id : null,
                                imageId: blp.image ? blp.image.id : null,
                                orderId: blp.orderId,
                                originalImageId: blp.originalImage ? blp.originalImage.id : null
                            };
                            return photo;
                        });
                    }
                    //this.metaService.setSpotBuildingMetaTags(this.buildingDetail, this.SpotPhotos);
                    //this.setSpotBuildingMetaTags();
                    /* this.spotBuildingsService.getServiceProvider().then((response: ServiceProvider[]) => {
                              this.services = response;
              
                              this.services.forEach((item: ServiceProvider) => {
                                item.serviceCategory = this.i18NService.getTranslation(item.serviceCategory);
                                item.services = this.removeHTMLCode(this.i18NService.getTranslation(item.services));
                              });
                            }); */
                    this.createSpotBuildingsService
                        .getSpaceConditions(this.buildingDetail.id)
                        .then(function (response) {
                        _this.spacesParameters = response;
                        /*  this.spacesParameters.forEach(value => {
                                   value.select = true;
                                 }); */
                        if (!_this.spacesParameters || _this.spacesParameters.length == 0) {
                            return;
                        }
                        else {
                            if (_this.spacesParameters.length > 3) {
                                _this.hiddeMoreSpaces = false;
                            }
                            else {
                                _this.hiddeMoreSpaces = true;
                                _this.showShortSpace = false;
                            }
                        }
                        _this.originalSpaces = tslib_1.__spread(response);
                        var selected = [];
                        var filters = {
                            totalAreaFilter: function (space) {
                                return _this.betweenFilter(space.area, _this.spacesFilter.totalAreaIni, _this.spacesFilter.totalAreaEnd);
                            },
                            totalAskingPriceFilter: function (space) {
                                return _this.betweenFilter(space.askingPrice, _this.spacesFilter.totalAskingPriceIni, _this.spacesFilter.totalAskingPriceEnd);
                            },
                            askingPricePerAreaFilter: function (space) {
                                return _this.betweenPerAreaFilter(space.askingPrice, space.area, _this.spacesFilter.askingPricePerAreaMin, _this.spacesFilter.askingPricePerAreaMax);
                            },
                            totalmonthlyRentFilter: function (space) {
                                return _this.betweenFilter(_this.spacesService.getTotalMonthlyRent(space), _this.spacesFilter.totalMonthlyRentIni, _this.spacesFilter.totalMonthlyRentEnd);
                            },
                            monthlyRentPerAreaFilter: function (space) {
                                return _this.betweenPerAreaFilter(space.monthlyAskingRent, space.area, _this.spacesFilter.monthlyRentPerAreaMin, _this.spacesFilter.monthlyRentPerAreaMax);
                            },
                            includeColdStorageFilter: function (space) {
                                return _this.booleanFilter(space.coldStorage, _this.spacesFilter.includeColdStorage);
                            },
                            skyLightsSpaceFilter: function (space) {
                                return _this.booleanFilter(space.skyLights, _this.spacesFilter.skyLightsSpace);
                            },
                            subLeaseFilter: function (space) {
                                return _this.equalsFilter(space.industrialLeaseType, _this.spacesFilter.subLease ? IndustrialLeaseType.SUBLEASE : null);
                            },
                            dataCenterConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.DATA_CENTER);
                            },
                            forRetailUseConditionIndustrialFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FOR_RETAIL_USE_I);
                            },
                            flexConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FLEX);
                            },
                            manufacturingConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.MANUFACTURING);
                            },
                            logisticsConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.LOGISTICS);
                            },
                            preBuildConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PREBUILD);
                            },
                            plugAndPlayConditionOfficeFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PLUG_AND_PLAY_O);
                            },
                            fullFloorConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FULL_FLOOR);
                            },
                            forRetailUseConditionOfficeFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FOR_RETAIL_USE_O);
                            },
                            coreAndShellConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.CORE_AND_SHEEL);
                            },
                            furnishedConditionFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FURNISHED);
                            },
                            plugAndPlayConditionCoworkingFilter: function (space) {
                                return _this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PLUG_AND_PLAY_C);
                            },
                            positionsFilter: function (space) {
                                return _this.greaterOrEqualFilter(space.positions, _this.spacesFilter.positions);
                            },
                            availabilityDateFilter: function (space) {
                                return _this.betweenDatesFilter(space.availabilityDate, null, _this.spacesFilter.availabilityDate);
                            },
                            parkingAvailableFilter: function (space) {
                                return _this.booleanFilter(space.parkingAvailable, _this.spacesFilter.parkingAvailable);
                            },
                            privateOutdoorFilter: function (space) {
                                return _this.booleanFilter(space.privateOutdoor, _this.spacesFilter.privateOutdoorSpace);
                            },
                            conferenceRoomFilter: function (space) {
                                return _this.booleanFilter(space.conferenceRoomAccess, _this.spacesFilter.conferenceRoom);
                            },
                            monthlyCostPerPersonConditionFilter: function (space) {
                                return _this.betweenFilter(space.monthlyCost, _this.spacesFilter.monthlyCostPerPersonMin, _this.spacesFilter.monthlyCostPerPersonMax);
                            },
                            coworkingTypeFilter: function (space) {
                                return _this.equalsFilter(space.coworkingType, _this.currentCoworkingTypeFilter);
                            }
                        };
                        if (_this.spacesFilter) {
                            if (_this.spacesFilter.totalAreaIni && _this.spacesFilter.totalAreaEnd) {
                                selected.push(filters.totalAreaFilter);
                            }
                            if (_this.spacesFilter.totalAskingPriceIni && _this.spacesFilter.totalAskingPriceEnd) {
                                selected.push(filters.totalAskingPriceFilter);
                            }
                            if (_this.spacesFilter.totalMonthlyRentIni && _this.spacesFilter.totalMonthlyRentEnd) {
                                selected.push(filters.totalmonthlyRentFilter);
                            }
                            if (_this.spacesFilter.askingPricePerAreaMin && _this.spacesFilter.askingPricePerAreaMax) {
                                selected.push(filters.askingPricePerAreaFilter);
                            }
                            if (_this.spacesFilter.monthlyRentPerAreaMin && _this.spacesFilter.monthlyRentPerAreaMax) {
                                selected.push(filters.monthlyRentPerAreaFilter);
                            }
                            // Coworking
                            if (_this.spacesFilter.positions) {
                                selected.push(filters.positionsFilter);
                            }
                            if (_this.spacesFilter.availabilityDate) {
                                selected.push(filters.availabilityDateFilter);
                            }
                            if (_this.spacesFilter.conferenceRoom) {
                                selected.push(filters.conferenceRoomFilter);
                            }
                            if (_this.spacesFilter.parkingAvailable) {
                                selected.push(filters.parkingAvailableFilter);
                            }
                            if (_this.spacesFilter.privateOutdoorSpace) {
                                selected.push(filters.privateOutdoorFilter);
                            }
                            if (_this.spacesFilter.monthlyCostPerPersonMin != null && _this.spacesFilter.monthlyCostPerPersonMin > 0) {
                                if (_this.spacesFilter.monthlyCostPerPersonMax != null && _this.spacesFilter.monthlyCostPerPersonMax > 0) {
                                    selected.push(filters.monthlyCostPerPersonConditionFilter);
                                }
                            }
                            // Flags
                            if (_this.spacesFilter.includeColdStorage) {
                                selected.push(filters.includeColdStorageFilter);
                            }
                            if (_this.spacesFilter.skyLightsSpace) {
                                selected.push(filters.skyLightsSpaceFilter);
                            }
                            if (_this.spacesFilter.subLease) {
                                selected.push(filters.subLeaseFilter);
                            }
                            // Conditions
                            if (_this.spacesFilter.datacenterCondition) {
                                selected.push(filters.dataCenterConditionFilter);
                            }
                            if (_this.spacesFilter.forRetailUseConditionIndustrial) {
                                selected.push(filters.forRetailUseConditionIndustrialFilter);
                            }
                            if (_this.spacesFilter.flexCondition) {
                                selected.push(filters.flexConditionFilter);
                            }
                            if (_this.spacesFilter.manufacturingCondition) {
                                selected.push(filters.manufacturingConditionFilter);
                            }
                            if (_this.spacesFilter.logisticsCondition) {
                                selected.push(filters.logisticsConditionFilter);
                            }
                            if (_this.spacesFilter.preBuildCondition) {
                                selected.push(filters.preBuildConditionFilter);
                            }
                            if (_this.spacesFilter.plugAndPlayConditionOffice) {
                                selected.push(filters.plugAndPlayConditionOfficeFilter);
                            }
                            if (_this.spacesFilter.fullFloorCondition) {
                                selected.push(filters.fullFloorConditionFilter);
                            }
                            if (_this.spacesFilter.forRetailUseConditionOffice) {
                                selected.push(filters.forRetailUseConditionOfficeFilter);
                            }
                            if (_this.spacesFilter.coreAndShellCondition) {
                                selected.push(filters.coreAndShellConditionFilter);
                            }
                            if (_this.spacesFilter.furnishedConditionCoworking) {
                                selected.push(filters.furnishedConditionFilter);
                            }
                            if (_this.spacesFilter.plugAndPlayConditionCoworking) {
                                selected.push(filters.plugAndPlayConditionCoworkingFilter);
                            }
                            if (_this.currentCoworkingTypeFilter) {
                                selected.push(filters.coworkingTypeFilter);
                            }
                        }
                        if (selected && selected.length > 0) {
                            if (_this.isForCoworking()) {
                                _this.spacesParameters = _this.spacesParameters.filter(function (space) { return selected.every(function (f) { return f(space); }); });
                            }
                            else {
                                _this.spacesParameters = _this.spacesParameters.filter(function (space) { return selected.some(function (f) { return f(space); }); });
                            }
                        }
                        if (_this.buildingDetail.type == 'C') {
                            var filterItem = _this.findIndexFromCurrentCoworkingSpaceTypeFilter();
                            _this.coworkingFilter(filterItem, true);
                        }
                        _this.selectAll();
                    });
                }
                catch (e) {
                    console.error('Error : ', e);
                }
                return [2 /*return*/];
            });
        });
    };
    /* private setSpotBuildingMetaTags() {
      const locale = this.i18NService.getCurrentLocale();
      const detailsMetaTags = this.metaService.buildSpotBuildingMetatag(this.buildingDetail, locale);
      const fullTitleText = detailsMetaTags.metaTagTitle;
      const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
      const subject = detailsMetaTags.metaTagSubject;
      const descText = this.buildingDetail.comments
        ? this.removeHTMLCode(this.i18NService.getTranslation(this.buildingDetail.comments))
        : '';
  
      const listingTitle = this.i18NService.getTranslation(this.buildingDetail.title);
      const fullDetailsURL = window.location.href;
  
      const tags = [
        //change to be same as title
        { name: 'name', content: encodeURIComponent(fullTitleText) },
        //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
        { name: 'og:locale', content: [locale] },
        { name: 'keywords', content: keywordsMetaTag },
        { name: 'title', content: [fullTitleText] },
        { name: 'subject', content: subject },
        {
          name: 'mobile-web-app-title',
          content: encodeURIComponent(fullTitleText)
        },
        { name: 'description', content: [descText] },
        { name: 'author', content: 'SiiLA' },
  
        { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
        { name: 'og:site_name', content: 'SiiLA SPOT' },
        { name: 'og:email', content: 'spot@siila.com.br' },
        { name: 'og:type', content: 'article' },
        { name: 'og:title', content: [fullTitleText] },
        { name: 'og:description', property: 'og:description', content: descText },
        { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
        {
          name: 'og:image',
          property: 'og:image',
          content:
            this.SpotPhotos && this.SpotPhotos.length
              ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
              : null
        },
        {
          name: 'image',
          content:
            this.SpotPhotos && this.SpotPhotos.length
              ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
              : null
        },
        { name: 'twitter:card', content: `summary_large_image` },
        { name: 'twitter:title', content: [fullTitleText] },
        {
          name: 'twitter:image',
          content:
            this.SpotPhotos && this.SpotPhotos.length
              ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
              : null
        },
        { name: 'twitter:description', content: descText }
      ];
  
      this.metaService.setDynamicTags(tags, fullTitleText);
    } */
    BuildingsDetailComponent.prototype.findIndexFromCurrentCoworkingSpaceTypeFilter = function () {
        var coworkingSpaceTypeFilter = this.currentCoworkingTypeFilter;
        var filterItem = 0;
        if (coworkingSpaceTypeFilter) {
            switch (coworkingSpaceTypeFilter) {
                case CoworkingSpaceType.PRIVATE_OFFICE:
                    filterItem = 1;
                    break;
                case CoworkingSpaceType.DEDICATED_WORKSPACE:
                    filterItem = 2;
                    break;
                case CoworkingSpaceType.OPEN_WORKSPACE:
                    filterItem = 3;
                    break;
                case CoworkingSpaceType.FULL_FLOOR_OFFICES:
                    filterItem = 4;
                    break;
            }
        }
        return filterItem;
    };
    BuildingsDetailComponent.prototype.outSideSearch = function () {
        this.spacesParameters = this.originalSpaces;
        if (this.isForCoworking()) {
            var coworkingTypeFilterId = this.findIndexFromCurrentCoworkingSpaceTypeFilter();
            this.coworkingFilter(coworkingTypeFilterId, true);
        }
    };
    BuildingsDetailComponent.prototype.getAvailableAreaText = function () {
        return this.commonService.getAvailableAreaText(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getAskingRent = function () {
        return this.commonService.getAskingRent(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getAskingRentPerArea = function () {
        return this.commonService.getAskingRentPerArea(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getAskingPrice = function () {
        return this.commonService.getAskingPrice(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getAskingPricePerArea = function () {
        return this.commonService.getAskingPricePerArea(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getMonthlyRentPerPerson = function () {
        return this.commonService.getMonthlyRentPerPerson(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.getPositions = function () {
        return this.commonService.getPositions(this.buildingDetail.spaceRangesDTO);
    };
    BuildingsDetailComponent.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    BuildingsDetailComponent.prototype.openCard2 = function () {
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.buildingDetail,
            panelClass: 'custom-modalbox'
        });
    };
    BuildingsDetailComponent.prototype.openGalleryImg = function (event) {
        this.popOverCtrlHelper.openGalleryImageComponent(event, this.SpotPhotos, this.buildingDetail.propertyName, this.buildingDetail.listingType, this.buildingDetail.address, this.topImage);
    };
    BuildingsDetailComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    BuildingsDetailComponent.prototype.getCroppedOrOriginalImage = function (buildinListingPhoto) {
        if (buildinListingPhoto) {
            if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
                return buildinListingPhoto.croppedImage.id;
            }
            return buildinListingPhoto.image.id;
        }
    };
    BuildingsDetailComponent.prototype.getPreviousPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.photoSlider.getActiveIndex()];
                    case 1:
                        index = _a.sent();
                        this.photoSlider.slidePrev();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuildingsDetailComponent.prototype.getNextPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.photoSlider.getActiveIndex()];
                    case 1:
                        index = _a.sent();
                        this.photoSlider.slideNext();
                        return [2 /*return*/];
                }
            });
        });
    };
    BuildingsDetailComponent.prototype.goBack = function () {
        this.routingHelperSrv.navigateToSearchPage(true);
    };
    BuildingsDetailComponent.prototype.seeMoreAction = function (event, space) {
        this.popOverCtrlHelper.openSpacesDetailPopover(event, space, this.buildingDetail);
    };
    BuildingsDetailComponent.prototype.alterDescriptionText = function () {
        this.showShortDesciption = !this.showShortDesciption;
    };
    BuildingsDetailComponent.prototype.getDateFormatFrom = function (date) {
        return this.datepipe.transform(date, 'yyyy-MM-dd');
    };
    BuildingsDetailComponent.prototype.alterSpaceText = function () {
        this.showShortSpace = !this.showShortSpace;
    };
    BuildingsDetailComponent.prototype.openBuilding = function () {
        var url = environment.serverUrl + "/property/" + this.buildingDetail.building.id;
        window.open(url);
    };
    BuildingsDetailComponent.prototype.openCompanyUrl = function (url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url);
    };
    BuildingsDetailComponent.prototype.getSpotAgentTitle = function (space) {
        var val = this.moduleSuiteValue(space);
        if (val == '' || val == null) {
            return this.warehouseFloorTitle() + " " + this.warehouseFloorValue(space) + " - " + this.buildingDetail.propertyName;
        }
        else {
            return this.warehouseFloorTitle() + " " + this.warehouseFloorValue(space) + ", " + this.moduleSuiteTitle(space) + " " + this.moduleSuiteValue(space) + " - " + this.buildingDetail.propertyName;
        }
    };
    BuildingsDetailComponent.prototype.moduleSuiteValue = function (space) {
        if (this.isForIndustrial) {
            return space.module ? space.module : '';
        }
        if (this.isForOffice) {
            return space.suite ? space.suite : '';
        }
        if (this.isForCoworking) {
            if (space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE ||
                space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                return space.suite ? space.suite : '';
            }
        }
        return '';
    };
    BuildingsDetailComponent.prototype.warehouseFloorTitle = function () {
        if (this.isForIndustrial) {
            return this.i18NService.get('buildinsDetail.wareHouse') + ':';
        }
        if (this.isForOffice || this.isForCoworking()) {
            return this.i18NService.get('buildinsDetail.floor') + ':';
        }
    };
    BuildingsDetailComponent.prototype.warehouseFloorValue = function (space) {
        if (this.isForIndustrial) {
            return space.warehouse ? space.warehouse : '';
        }
        if (this.isForOffice || this.isForCoworking) {
            return space.floor ? space.floor : '';
        }
    };
    BuildingsDetailComponent.prototype.moduleSuiteTitle = function (space) {
        if (this.isForIndustrial) {
            return this.i18NService.get('buildinsDetail.module') + ':';
        }
        if (this.isForOffice) {
            return this.i18NService.get('buildinsDetail.suite') + ':';
        }
        if (this.isForCoworking) {
            if (space.coworkingType) {
                if (space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                    return this.i18NService.get('buildinsDetail.suite') + ':';
                }
                if (space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE) {
                    return this.i18NService.get('spaceDetail.desk') + ':';
                }
            }
        }
        return '';
    };
    BuildingsDetailComponent.prototype.openRequestInfoFromAgent = function (pEvent, space) {
        var requestInfoInput = {
            requestTitle: this.getSpotAgentTitle(space),
            companyLogoImageId: this.buildingDetail.companyLogoImageId,
            agents: [space.agentDTO],
            spotId: this.buildingDetail.id,
            requestType: RequestInformationType.REQ_INFO
        };
        this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
    };
    BuildingsDetailComponent.prototype.openRequestInfo = function (pEvent) {
        var brokers = [];
        var sentEmailTo = [];
        if (this.buildingDetail.brokers) {
            this.buildingDetail.brokers.forEach(function (broker) {
                var agentBroker = {
                    firstNameAndLastName: broker.firstNameAndLastName,
                    phoneNumber: broker.phoneNumber,
                    photoId: broker.photoId,
                    userEmail: broker.userEmail
                };
                brokers.push(agentBroker);
                sentEmailTo.push(broker.userEmail);
            });
        }
        var requestInfoInput = {
            requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
            companyLogoImageId: this.buildingDetail.companyLogoImageId,
            agents: brokers,
            sentEmailTo: sentEmailTo,
            spotId: this.buildingDetail.id,
            requestType: RequestInformationType.REQ_INFO
        };
        this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
    };
    BuildingsDetailComponent.prototype.requestTourAction = function (pEvent) {
        var brokers = [];
        var sentEmailTo = [];
        if (this.buildingDetail.brokers) {
            this.buildingDetail.brokers.forEach(function (broker) {
                var agentBroker = {
                    firstNameAndLastName: broker.firstNameAndLastName,
                    phoneNumber: broker.phoneNumber,
                    photoId: broker.photoId,
                    userEmail: broker.userEmail
                };
                brokers.push(agentBroker);
                sentEmailTo.push(broker.userEmail);
            });
        }
        var requestInfoInput = {
            requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
            companyLogoImageId: this.buildingDetail.companyLogoImageId,
            agents: brokers,
            sentEmailTo: sentEmailTo,
            spotId: this.buildingDetail.id,
            requestType: RequestInformationType.REQ_TOUR
        };
        this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
    };
    BuildingsDetailComponent.prototype.openVideoTour = function (pEvent) {
        var _this = this;
        if (this.buildingDetail.vrTourUrl) {
            this.userActivityService.trackSpotBuildingVirtualTour(this.buildingDetail.id);
            this.popOverCtrlHelper.openVideoTourPopup(pEvent, this.buildingDetail.vrTourUrl, this.buildingDetail.propertyName, this.isMobile, function (error) {
                console.error('Callback from video tour: ', error);
                _this.spotBuildingsService.logError(error);
            });
        }
    };
    BuildingsDetailComponent.prototype.countFeatures = function () {
        if (this.buildingDetail.twentyFourHourSecurity) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.bicycleRack) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.fiberOptic) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.blockAndSteelConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.blockConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.centralAirConditioning) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.crossDocking) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.electricGenerator) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.heliport) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.multiTenant) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.petFriendly) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.singleTenant) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.sluiceGate) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.sprinklers) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.steelConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.leedStatus) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.truckYard) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.mailPackageHandling) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.enhancedCleaning) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.barista) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.cafeteria) {
            this.features = this.features + 1;
        }
        if (!this.isMobile && this.features <= 6) {
            this.hiddeMoreFeatures = true;
        }
        else {
            if (this.isMobile && this.features <= 3) {
                this.hiddeMoreFeatures = true;
            }
            else {
                this.hiddeMoreFeatures = false;
            }
        }
    };
    BuildingsDetailComponent.prototype.getTermsTxt = function (term) {
        return term
            ? term > 1
                ? term + ' ' + this.i18NService.get('Years')
                : term + ' ' + this.i18NService.get('Year')
            : '';
    };
    BuildingsDetailComponent.prototype.validateDecimal = function (value, type) {
        if (value == 0 || value == null || isNaN(value)) {
            return this.i18NService.get('global.list-your-property.negotiable');
        }
        if (type) {
            return type == 1 ? 'R$ ' + this.transform(value) : ' R$ ' + this.transform(value) + '/m²';
        }
        else {
            return this.transform(value);
        }
    };
    BuildingsDetailComponent.prototype.transform = function (value) {
        if (value % 1 == 0) {
            return this._decimalPipe.transform(value, "1.0-0");
        }
        else {
            return this._decimalPipe.transform(value, "1.2-2");
        }
    };
    BuildingsDetailComponent.prototype.editBuilding = function () {
        window.open("/spot-buildings/create/" + (this.buildingDetail.type == 'L' ? 'lease' : this.buildingDetail.type == 'C' ? 'coworking' : 'sale') + "/" + this.buildingDetail.building.id, '_self');
    };
    BuildingsDetailComponent.prototype.checkSpotAdmin = function () {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
            return true;
        }
        else {
            return false;
        }
    };
    BuildingsDetailComponent.prototype.getMonthlyCostValue = function (space) {
        return this.commonService.getMonthlyCost(space);
    };
    BuildingsDetailComponent.prototype.getMonthlyCostWithPositions = function (space) {
        return this.commonService.getMonthlyCostWithPositions(space);
    };
    BuildingsDetailComponent.prototype.openBrokerDetails = function (brokerId) {
        this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL, brokerId, this.buildingDetail.id);
        this.routingHelperSrv.navigateToBrokerDetailsPage(brokerId);
    };
    BuildingsDetailComponent.prototype.openCompanyDetails = function (event) {
        if (this.buildingDetail.offeredByCompany.spotProfile) {
            this.registerCompanyDetailAction(UserActivityType.COMPANY_SHOWDETAIL, this.buildingDetail.id, this.buildingDetail.offeredByCompany.id);
            this.routingHelperSrv.navigateToCompanyDetailsPage(this.buildingDetail.offeredByCompany.id);
        }
    };
    /*  @HostListener('window:scroll')
     scrollUp() {
       window.scrollTo(0, 0);
     } */
    BuildingsDetailComponent.prototype.showContactEmail = function (notSubscribe, broker) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (broker === void 0) { broker = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(2);
                return;
            }
        }
        var userBrokerId = this.buildingDetail.brokers[0].userId;
        if (broker) {
            userBrokerId = this.buildingDetail.brokers[1].userId;
            this.contactEmail2 = true;
        }
        else {
            this.contactEmail = true;
        }
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL, userBrokerId, this.buildingDetail.id);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    };
    BuildingsDetailComponent.prototype.registerUserActivity = function (userActivityType, brokerId, listingId) {
        var userActivity = {
            module: userActivityType,
            brokerId: brokerId,
            buildingListingId: listingId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    BuildingsDetailComponent.prototype.registerCompanyDetailAction = function (userActivityType, listingId, companyId) {
        var userActivity = {
            module: userActivityType,
            buildingListingId: listingId,
            companyId: companyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    BuildingsDetailComponent.prototype.showBrokerWhatsapp = function (notSubscribe, broker) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (broker === void 0) { broker = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(1);
                return;
            }
        }
        var userBrokerId = this.buildingDetail.brokers[0].userId;
        if (broker) {
            userBrokerId = this.buildingDetail.brokers[1].userId;
        }
        if (broker) {
            this.whatsappPhone2 = true;
        }
        else {
            this.whatsappPhone = true;
        }
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP, userBrokerId, this.buildingDetail.id);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    };
    BuildingsDetailComponent.prototype.openShowMoreLogInModal = function (id) {
        var _this = this;
        var subscribeDialog = this.dialog.open(SubscribeModalComponent, {
            width: '400px',
            panelClass: 'my-custom-dialog-class',
            data: id
        });
        subscribeDialog.afterClosed().subscribe(function (val) {
            if (val != undefined) {
                switch (val) {
                    case 1:
                        _this.showBrokerWhatsapp(val);
                        break;
                    case 2:
                        _this.showContactEmail(val);
                        break;
                    default:
                        return null;
                }
            }
        });
    };
    BuildingsDetailComponent.prototype.isForCoworking = function () {
        return this.buildingDetail.type == 'C';
    };
    BuildingsDetailComponent.prototype.coworkingFilter = function (filter, validateFilters) {
        var _this = this;
        if (validateFilters === void 0) { validateFilters = false; }
        var spaces = JSON.parse(JSON.stringify(this.spacesParameters));
        this.seeSuit = true;
        this.seePositions = true;
        if (validateFilters) {
            this.filter1 =
                this.spacesParameters.filter(function (val) {
                    return val.coworkingType == _this.coworkingTypes.privateOffice;
                }).length > 0
                    ? true
                    : false;
            this.filter2 =
                this.spacesParameters.filter(function (val) {
                    return val.coworkingType == _this.coworkingTypes.dedicatedWorkspace;
                }).length > 0
                    ? true
                    : false;
            this.filter3 =
                this.spacesParameters.filter(function (val) {
                    return val.coworkingType == _this.coworkingTypes.openWorkspace;
                }).length > 0
                    ? true
                    : false;
            this.filter4 =
                this.spacesParameters.filter(function (val) {
                    return val.coworkingType == _this.coworkingTypes.fullFloorOffices;
                }).length > 0
                    ? true
                    : false;
        }
        if (filter == 3 || (filter == 0 && this.filter3)) {
            this.spacesCoworking = spaces.filter(function (val) {
                return val.coworkingType == _this.coworkingTypes.openWorkspace;
            });
            this.spacesCoworking = this.spacesCoworking.filter(function (val) {
                return (val.coworkingType = '{"pt-br": "buildinsDetail.openWorkspace", "en":"buildinsDetail.openWorkspace"}');
            });
            this.filter = 3;
            this.seeSuit = false;
            return;
        }
        if (filter == 1 || (filter == 0 && this.filter1)) {
            this.spacesCoworking = spaces.filter(function (val) {
                return val.coworkingType == _this.coworkingTypes.privateOffice;
            });
            this.spacesCoworking = this.spacesCoworking.filter(function (val) {
                return (val.coworkingType = '{"pt-br": "buildinsDetail.privateOffice", "en":"buildinsDetail.privateOffice"}');
            });
            this.filter = 1;
            return;
        }
        if (filter == 2 || (filter == 0 && this.filter2)) {
            this.spacesCoworking = spaces.filter(function (val) {
                return val.coworkingType == _this.coworkingTypes.dedicatedWorkspace;
            });
            this.spacesCoworking = this.spacesCoworking.filter(function (val) {
                return (val.coworkingType =
                    '{"pt-br": "buildinsDetail.dedicatedWorkspace", "en":"buildinsDetail.dedicatedWorkspace"}');
            });
            this.filter = 2;
            return;
        }
        if (filter == 4 || (filter == 0 && this.filter4)) {
            this.spacesCoworking = spaces.filter(function (val) {
                return val.coworkingType == _this.coworkingTypes.fullFloorOffices;
            });
            this.spacesCoworking = this.spacesCoworking.filter(function (val) {
                return (val.coworkingType =
                    '{"pt-br": "buildinsDetail.fullFloorOffices", "en":"buildinsDetail.fullFloorOffices"}');
            });
            this.filter = 4;
            this.seeSuit = false;
            this.seePositions = false;
            return;
        }
        if (this.spacesCoworking.length > 3) {
            this.hiddeMoreSpaces = false;
            this.showShortSpace = true;
        }
        else {
            this.hiddeMoreSpaces = true;
            this.showShortSpace = false;
        }
    };
    BuildingsDetailComponent.prototype.showPortfolioSection = function () {
        return this.portfolioCount && this.portfolioCount != 0;
    };
    BuildingsDetailComponent.prototype.getSpotBuildingSliderFilters = function (spotBuilding) {
        var filters = {
            listingClasses: ['FEATURED', 'PREMIUM']
        };
        if (!spotBuilding || !spotBuilding.offeredByCompany)
            return filters;
        filters.companyId = this.buildingDetail.offeredByCompany.id || null;
        return filters;
    };
    BuildingsDetailComponent.prototype.goToSiilaSolutions = function (link) {
        if (link == 1) {
            window.open(this.homepageAppSrv.getMarketAnalyticsURL(), '_blank');
        }
        if (link == 2) {
            window.open(this.homepageAppSrv.getIndicesURL(), '_blank');
        }
        if (link == 3) {
            window.open(this.homepageAppSrv.getFiiDataProURL(), '_blank');
        }
    };
    BuildingsDetailComponent.prototype.portfolioCountEvent = function (total) {
        this.portfolioCount = total;
    };
    BuildingsDetailComponent.prototype.cssToolbar = function () {
        if (this.isMobile) {
            return {
                position: 'unset'
            };
        }
        else {
            return {
                position: 'fixed'
            };
        }
    };
    BuildingsDetailComponent.prototype.shareCompany = function (event) {
        var url = window.location.href;
        var sharedTitle = this.buildingDetail.propertyName;
        this.userActivityService.trackSpotBuildingShare(this.buildingDetail.id);
        var modalTitle = this.i18NService.get('buildinsDetail.shareProperty');
        var input = { url: url, sharedTitle: sharedTitle, modalTitle: modalTitle };
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    };
    BuildingsDetailComponent.prototype.openSetSpaces = function () {
        var _this = this;
        if (this.isLoggedIn) {
            var dialogRef = this.dialog.open(SetSpacesComponent, {
                width: this.isMobile ? '100%' : '600px',
                maxWidth: this.isMobile ? '100%' : '',
                height: this.isMobile ? '100%' : '',
                data: {
                    data: this.spacesCoworking.length > 0
                        ? this.spacesCoworking.filter(function (x) { return x.select == true; })
                        : this.spacesParameters.filter(function (x) { return x.select == true; }),
                    buildingListingId: this.spacesParameters.map(function (y) { return y.buildingListingId; })[0]
                }
            });
            dialogRef.afterClosed().subscribe(function (val) {
                if (val) {
                    _this.presentToast(val);
                }
            });
        }
        else {
            this.clickSet = true;
            this.validateLogin();
        }
    };
    BuildingsDetailComponent.prototype.countSpaces = function (space) {
        if (space.select) {
            this.countSapces++;
        }
        else {
            this.countSapces--;
        }
    };
    BuildingsDetailComponent.prototype.openReportSpaces = function () {
        if (this.isLoggedIn) {
            var dialogRef = this.dialog.open(ReportSpacesComponent, {
                width: this.isMobile ? '100%' : '60%',
                maxWidth: this.isMobile ? '100%' : '',
                height: this.isMobile ? '100%' : '',
                data: {
                    data: this.buildingDetail.type == 'C'
                        ? this.spacesCoworking.filter(function (x) { return x.select == true; })
                        : this.spacesParameters.filter(function (x) { return x.select == true; }),
                    buildingDetail: this.buildingDetail
                }
            });
        }
        else {
            this.clickReport = true;
            this.validateLogin();
        }
    };
    BuildingsDetailComponent.prototype.validateLogin = function () {
        if (!this.isLoggedIn) {
            this.openLoginDialog()
                .afterClosed(function () { })
                .subscribe(function (val) { });
        }
    };
    BuildingsDetailComponent.prototype.openLoginDialog = function () {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute
        });
    };
    BuildingsDetailComponent.prototype.selectAll = function () {
        this.selectallSpaces = !this.selectallSpaces;
        if (this.buildingDetail.type == 'C') {
            if (this.selectallSpaces) {
                this.countSapces = this.spacesCoworking.length;
                this.spacesCoworking.map(function (val) { return (val.select = true); });
            }
            else {
                this.spacesCoworking.map(function (val) { return (val.select = false); });
                this.countSapces = 0;
            }
        }
        else {
            if (this.selectallSpaces) {
                this.countSapces = this.spacesParameters.length;
                this.spacesParameters.map(function (val) { return (val.select = true); });
            }
            else {
                this.spacesParameters.map(function (val) { return (val.select = false); });
                this.countSapces = 0;
            }
        }
    };
    BuildingsDetailComponent.prototype.onCardClick = function (cardType) {
        var url;
        switch (cardType) {
            case 'bgma':
                url = 'https://siila.com.br/marketanalytics/lang/';
                break;
            case 'bga':
                url = 'https://siila.com.br/academy/lang/';
                break;
            case 'bgi':
                url = 'https://siila.com.br/products/102/%C3%ADndices/lang/';
                break;
        }
        var langPath = this.i18NService.getCurrentLanguage() == 'en' ? 'en' : 'pt-br';
        var urlToOpen = "" + url + langPath;
        if (urlToOpen)
            window.open(urlToOpen, '_blank');
    };
    BuildingsDetailComponent.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert-detail'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BuildingsDetailComponent.prototype.isMezzanine = function (floor) {
        return this.isEqualsTo(floor, ['Mezzanine', 'Mezanino']);
    };
    BuildingsDetailComponent.prototype.isGroundFloor = function (floor) {
        return this.isEqualsTo(floor, ['Ground floor', 'Térreo', 'Terreo']);
    };
    BuildingsDetailComponent.prototype.isRooftop = function (floor) {
        return this.isEqualsTo(floor, ['Terraço', 'Rooftop']);
    };
    BuildingsDetailComponent.prototype.isEqualsTo = function (floor, customFloor) {
        return floor && customFloor.includes(floor);
    };
    BuildingsDetailComponent.prototype.findFloorTranslation = function (floorValue) {
        if (!floorValue) {
            return '';
        }
        var translationKey = null;
        if (this.isMezzanine(floorValue)) {
            translationKey = 'buildinsDetail.mezzanine';
        }
        else if (this.isGroundFloor(floorValue)) {
            translationKey = 'buildinsDetail.groundfloor';
        }
        else if (this.isRooftop(floorValue)) {
            translationKey = 'buildinsDetail.rooftop';
        }
        if (translationKey != null) {
            return this.i18NService.get(translationKey);
        }
        return floorValue;
    };
    return BuildingsDetailComponent;
}());
export { BuildingsDetailComponent };
