import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as MyListingModel from '@app/models/my-listing.model';
import { RegisterService } from '@app/core/register.service';
import { StatusHelperService } from '@app/core/helpers/status-helper.service';
import { ClassHelperService } from '@app/core/helpers/classes-helper.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
var ListingFilterModalComponent = /** @class */ (function () {
    function ListingFilterModalComponent(breakpointObserver, classHelperSvc, popoverCtrl, registerSvc, statusHelperSvc) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.classHelperSvc = classHelperSvc;
        this.popoverCtrl = popoverCtrl;
        this.registerSvc = registerSvc;
        this.statusHelperSvc = statusHelperSvc;
        this.isMobile = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    ListingFilterModalComponent.prototype.ngOnInit = function () {
        this.statusHelperSvc.getStatuses();
        this.initMyListingFilter();
    };
    ListingFilterModalComponent.prototype.getUserByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerSvc.getUsersByCompany(companyId).toPromise()];
            });
        });
    };
    ListingFilterModalComponent.prototype.initMyListingFilter = function () {
        this.selectStatus = this.statusHelperSvc.selectStatus;
        this.selectClass = this.classHelperSvc.selectClass;
    };
    ListingFilterModalComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    ListingFilterModalComponent.prototype.changeCompany = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getUserByCompany($event.value.id)];
                    case 1:
                        _a.agents = _b.sent();
                        this.agentComponent.clear();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListingFilterModalComponent.prototype.changeStatus = function ($event) {
        this.selectStatus = $event.value;
    };
    ListingFilterModalComponent.prototype.changeClass = function ($event) {
        this.selectClass = $event.value;
    };
    ListingFilterModalComponent.prototype.updateMyListingFilter = function () {
        this.popoverCtrl.dismiss({
            agents: this.agents,
            selectAgent: this.selectAgent,
            selectCompany: this.selectCompany,
            selectStatus: this.selectStatus,
            selectClass: this.selectClass
        });
    };
    ListingFilterModalComponent.prototype.setModalContainerWidth = function () {
        return this.isMobile ? 'filter-selection-mobile' : 'filter-selection';
    };
    ListingFilterModalComponent.prototype.setFieldWidth = function () {
        return this.isMobile ? 'field-width-mobile' : '';
    };
    ListingFilterModalComponent.prototype.setLabelColSize = function () {
        return this.isMobile ? 4 : 3;
    };
    ListingFilterModalComponent.prototype.setFilterColSize = function () {
        return this.isMobile ? 8 : 9;
    };
    return ListingFilterModalComponent;
}());
export { ListingFilterModalComponent };
