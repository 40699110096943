import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGenericService } from '@app/core/common.service';
import * as Fuse from 'fuse.js';
import { PurchaseCreditsService, I18nService, LanguageService } from '@app/core';
import { ListingService, EventCheckerService } from '@app/core/listings';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { BuildingLocation } from '@app/shared/maps/map/map.component';
var CreateDetailsComponent = /** @class */ (function () {
    function CreateDetailsComponent(registerService, _ts, commonService, purchaseService, listingService, _messageService, i18nService, langService) {
        this.registerService = registerService;
        this._ts = _ts;
        this.commonService = commonService;
        this.purchaseService = purchaseService;
        this.listingService = listingService;
        this._messageService = _messageService;
        this.i18nService = i18nService;
        this.langService = langService;
        this.selectedTitleLanguage = [];
        this.selectedDescLanguage = [];
        this.languages = this.commonService.getLanguageOption();
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.bluePin = "/assets/maps/pin-blue.png";
    }
    CreateDetailsComponent.prototype.ngOnInit = function () {
        this.initValues();
        this.multiLangInit();
        this.setValidators();
        this.listing ? this.setProperty(this.detailsForm.value.actualProperty, 0) : null;
        this.initialBuilding = this.listing && this.listing.building ? tslib_1.__assign({}, this.listing.building) : null;
        this.detectBuilding();
    };
    CreateDetailsComponent.prototype.detectBuilding = function () {
        var _this = this;
        this._messageService.typeBuildings$.subscribe(function (buildings) {
            if (buildings === 'changed') {
                _this.checkBuildingType();
            }
        });
    };
    CreateDetailsComponent.prototype.fuzzySearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            return tslib_1.__generator(this, function (_a) {
                options = {
                    keys: ['name'],
                    shouldSort: true,
                    caseSensitive: false,
                    threshold: 0.5,
                    findAllMatches: true
                };
                this.fuse = new Fuse(this.buildings, options);
                return [2 /*return*/];
            });
        });
    };
    CreateDetailsComponent.prototype.getSpaceTypes = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getBuildingSubTypes(id).toPromise()];
            });
        });
    };
    CreateDetailsComponent.prototype.getSpaceTranslations = function (subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(subTypes).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        subTypes[key].name = this.getI18nValue(subTypes[key].name);
                        subTypes[key].selected = false;
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, subTypes];
            });
        });
    };
    CreateDetailsComponent.prototype.setValidators = function () {
        if (this.detailsForm.controls.listed.value) {
            this.clearInput();
            this.fuzzySearch();
            this.listedValidators();
        }
        else {
            this.notListedValidators();
        }
    };
    CreateDetailsComponent.prototype.checkBuildingType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, options;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001)) return [3 /*break*/, 4];
                        if (!!this.industrialBuildings) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(1001)];
                    case 1:
                        _a.industrialBuildings = _c.sent();
                        this.buildingNames = this.industrialBuildings;
                        this.buildings = this.industrialBuildings.values;
                        return [3 /*break*/, 3];
                    case 2:
                        this.buildingNames = this.industrialBuildings;
                        this.buildings = this.industrialBuildings.values;
                        _c.label = 3;
                    case 3: return [3 /*break*/, 7];
                    case 4:
                        if (!!this.officeBuildings) return [3 /*break*/, 6];
                        _b = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(2001)];
                    case 5:
                        _b.officeBuildings = _c.sent();
                        this.buildingNames = this.officeBuildings;
                        this.buildings = this.officeBuildings.values;
                        return [3 /*break*/, 7];
                    case 6:
                        this.buildingNames = this.officeBuildings;
                        this.buildings = this.officeBuildings.values;
                        _c.label = 7;
                    case 7:
                        options = {
                            keys: ['name'],
                            shouldSort: true,
                            caseSensitive: false,
                            threshold: 0.5,
                            findAllMatches: true
                        };
                        this.fuse = new Fuse(this.buildings, options);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.listedValidators = function () {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls['propertyName'].setValue(null);
        this.detailsForm.controls['propertyName'].clearValidators();
        this.detailsForm.controls['property'].setValidators([Validators.required]);
        this.detailsForm.controls['propertyName'].updateValueAndValidity();
        this.detailsForm.controls['property'].updateValueAndValidity();
    };
    CreateDetailsComponent.prototype.notListedValidators = function () {
        this.detailsForm.controls['property'].setValue(null);
        this.detailsForm.controls['propertyName'].setValue(null);
        this.detailsForm.controls['property'].clearValidators();
        this.detailsForm.controls['propertyName'].setValidators([Validators.required]);
        this.detailsForm.controls['property'].updateValueAndValidity();
        this.detailsForm.controls['propertyName'].updateValueAndValidity();
    };
    CreateDetailsComponent.prototype.clearInput = function () {
        this.detailsForm.controls['propertySet'].setValue(false);
        this.detailsForm.controls.actualProperty.setValue(null);
        this.detailsForm.controls.property.setValue('');
        this.detailsForm.controls.property.enable();
    };
    CreateDetailsComponent.prototype.suggest_property = function (term) {
        if (term) {
            var q = term.toLowerCase().trim();
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map(function (element) {
                return element;
            });
        }
    };
    CreateDetailsComponent.prototype.changeFuzzySearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.detailsForm.controls.property.enabled) {
                    this.searchTerm = this.detailsForm.value.property;
                    this.buildingNames = this.suggest_property(this.detailsForm.value.property);
                }
                return [2 /*return*/];
            });
        });
    };
    CreateDetailsComponent.prototype.initValues = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(1001)];
                    case 1:
                        _a.industrialBuildings = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(2001)];
                    case 2:
                        _b.officeBuildings = _c.sent();
                        if (parseInt(this.listingTypeForm.controls['commericalType'].value) === 1001) {
                            this.buildingNames = this.industrialBuildings;
                            this.buildings = this.industrialBuildings.values;
                        }
                        else {
                            this.buildingNames = this.officeBuildings;
                            this.buildings = this.officeBuildings.values;
                        }
                        this.fuzzySearch();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.setProperty = function (building, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, buildingTypeId;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.detailsForm.controls.property.setValue(building.name);
                        this.detailsForm.controls.property.disable();
                        if (value == 0 && this.listing.propertyName) {
                            this.propertyName = JSON.parse(this.listing.propertyName);
                        }
                        else {
                            this.propertyName = "{\"en\":\"" + building.name + "\",\"pt-br\":\"" + building.name + "\"}";
                        }
                        this.buildingNames = null;
                        _a = this;
                        return [4 /*yield*/, this.listingService.getBuilding(building.id)];
                    case 1:
                        _a.actualBuilding = _b.sent();
                        this.marker = { lat: this.actualBuilding.latitude, lng: this.actualBuilding.longitude };
                        this.detailsForm.controls.actualProperty.setValue(building);
                        this.detailsForm.controls['propertySet'].setValue(true);
                        buildingTypeId = this.actualBuilding.buildingType.id;
                        if (building && this.listing && this.listing.building && this.listing.building.id) {
                            this.changedBuilding = this.listing.building.id != building.id;
                            if (this.changedBuilding) {
                                if (this.initialBuilding) {
                                    if (this.initialBuilding.id == building.id) {
                                        this.setExistingFeatures(this.listing, buildingTypeId);
                                    }
                                    else {
                                        this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
                                        this.setExistingFeatures(this.actualBuilding, buildingTypeId);
                                    }
                                }
                                else {
                                    this.openInfoModal('', 'global.list-your-property.buildingHasChanged');
                                    this.setExistingFeatures(this.actualBuilding, buildingTypeId);
                                }
                            }
                        }
                        else if (!this.listing) {
                            this.setExistingFeatures(this.actualBuilding, buildingTypeId);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.setExistingFeatures = function (pListing, buildingTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, res, _c, _d, res;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!(buildingTypeId === 1001)) return [3 /*break*/, 5];
                        _a = this;
                        return [4 /*yield*/, this.registerService.getAmenities('industrial')];
                    case 1:
                        _a.industrialFilters = _e.sent();
                        if (!(Object.keys(pListing).length > 0)) return [3 /*break*/, 3];
                        _b = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeatures(pListing, null, this.industrialFilters)];
                    case 2:
                        _b.industrialFilters = _e.sent();
                        _e.label = 3;
                    case 3:
                        this.listingFeaturesForm.controls['tags'].setValue(this.industrialFilters);
                        return [4 /*yield*/, this.updateListingFeatures('industrial', pListing)];
                    case 4:
                        res = _e.sent();
                        this._messageService.updateIndustrialFilters$.next(this.industrialFilters);
                        return [3 /*break*/, 10];
                    case 5:
                        _c = this;
                        return [4 /*yield*/, this.registerService.getAmenities('office')];
                    case 6:
                        _c.officeFilters = _e.sent();
                        if (!(Object.keys(pListing).length > 0)) return [3 /*break*/, 8];
                        _d = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeatures(pListing, this.officeFilters, null)];
                    case 7:
                        _d.officeFilters = _e.sent();
                        _e.label = 8;
                    case 8:
                        this.listingFeaturesForm.controls['tags'].setValue(this.officeFilters);
                        return [4 /*yield*/, this.updateListingFeatures('office', pListing)];
                    case 9:
                        res = _e.sent();
                        this._messageService.updateOfficeFilters$.next(this.officeFilters);
                        _e.label = 10;
                    case 10:
                        this.listingFeaturesForm.updateValueAndValidity();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.updateListingFeatures = function (type, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                //Office
                this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
                this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
                //Industrial
                this.listingFeaturesForm.controls['baySize'].setValue(undefined);
                this.listingFeaturesForm.controls['dockRatio'].setValue(undefined);
                this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(undefined);
                this.listingFeaturesForm.controls['loadCapacity'].setValue(undefined);
                this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
                this.listingFeaturesForm.controls['carParkingSpaces'].setValue(undefined);
                Object.keys(building).forEach(function (key) {
                    if (type === 'industrial') {
                        if (key === 'baySize') {
                            _this.listingFeaturesForm.controls['baySize'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'dockRatio') {
                            _this.listingFeaturesForm.controls['dockRatio'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'loadCapacity') {
                            _this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'ceilingHeight') {
                            _this.listingFeaturesForm.controls['ceilingHeight'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key].toFixed(2));
                        }
                    }
                    else if (type === 'office') {
                        _this.listingFeaturesForm.controls['parkingSpaces'].setValue(undefined);
                        _this.listingFeaturesForm.controls['ceilingHeight'].setValue(undefined);
                        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'ceilingHeight') {
                            _this.listingFeaturesForm.controls['ceilingHeight'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CreateDetailsComponent.prototype.setPropertyFeatures = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.actualBuilding.buildingType.id === 1001)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeaturesFromBuilding(this.actualBuilding, null, this.industrialFilters)];
                    case 1:
                        _a.industrialFilters = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeaturesFromBuilding(this.actualBuilding, this.officeFilters, null)];
                    case 3:
                        _b.officeFilters = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.setFormFeatures = function (type, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(building).forEach(function (key) {
                    if (type === 'industrial') {
                        if (key === 'baySize') {
                            _this.listingFeaturesForm.controls['baySize'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'dockRatio') {
                            _this.listingFeaturesForm.controls['dockRatio'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key]);
                        }
                        if (key === 'loadCapacity') {
                            _this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key]);
                        }
                        if (key === 'ceilingHeight') {
                            _this.listingFeaturesForm.controls['ceilingHeight'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key]);
                        }
                    }
                    else if (type === 'office') {
                        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key]);
                        }
                        /*if (key === 'restrooms') {
                          this.listingFeaturesForm.controls['restrooms'].setValue(building[key]);
                        }*/
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CreateDetailsComponent.prototype.getBuildingTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, options;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(1001)];
                    case 1:
                        _a.industrialBuildings = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(2001)];
                    case 2:
                        _b.officeBuildings = _c.sent();
                        this.buildingNames = this.industrialBuildings;
                        this.buildings = this.buildingNames.values;
                        options = {
                            keys: ['name'],
                            shouldSort: true,
                            caseSensitive: false,
                            threshold: 0.5,
                            findAllMatches: true
                        };
                        this.fuse = new Fuse(this.buildings, options);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    CreateDetailsComponent.prototype.nameChange = function (value) {
        var _this = this;
        if (value.name.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.name).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.name[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1
                ? this.detailsForm.controls['property'].setValue(null)
                : this.detailsForm.controls['property'].setValue(JSON.stringify(mergedObject_1));
            nullValues_1
                ? this.detailsForm.controls['propertyName'].setValue(null)
                : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject_1));
        }
        else {
            this.addressForm.controls['description'].setValue(null);
        }
    };
    CreateDetailsComponent.prototype.propertyNameChange = function (value) {
        var _this = this;
        if (value.property.length > 0) {
            var mergedObject_2 = {};
            var nullValues_2 = false;
            Object.keys(value.property).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.property[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_2 = true;
                    }
                    else {
                        mergedObject_2 = Object.assign(mergedObject_2, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_2
                ? this.detailsForm.controls['propertyName'].setValue(null)
                : this.detailsForm.controls['propertyName'].setValue(JSON.stringify(mergedObject_2));
        }
        else {
            this.addressForm.controls['description'].setValue(null);
        }
    };
    CreateDetailsComponent.prototype.multiLangInit = function () {
        this.nameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyName',
            element: { input: true },
            formArrayName: 'name',
            required: true
        };
        this.propertyNameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyNameDisplay',
            element: { input: true },
            formArrayName: 'property',
            required: true
        };
    };
    CreateDetailsComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) { });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    CreateDetailsComponent.prototype.setBuildingGeoLocation = function (buildingLocation) {
        if (!this.addressForm.controls['buildingLocation'] ||
            this.addressForm.controls['buildingLocation'].value != buildingLocation) {
            this._messageService.updateBuildingLocation$.next(buildingLocation);
        }
    };
    return CreateDetailsComponent;
}());
export { CreateDetailsComponent };
