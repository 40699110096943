import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService, PurchaseCreditsService } from '@app/core';
import { ListingService } from '@app/core/listings';
import { BuildingDTO } from '@app/models/building.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { ListingOfferType, ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import * as Fuse from 'fuse.js';
import { DeviceDetectorService } from 'ngx-device-detector';
var SpotBuildingsDetailComponent = /** @class */ (function () {
    function SpotBuildingsDetailComponent(purchaseService, listingService, i18NService, createSpotBuildingsService, alertCtrl, popoverCtrl, deviceDetector, router, navCtrl) {
        this.purchaseService = purchaseService;
        this.listingService = listingService;
        this.i18NService = i18NService;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.alertCtrl = alertCtrl;
        this.popoverCtrl = popoverCtrl;
        this.deviceDetector = deviceDetector;
        this.router = router;
        this.navCtrl = navCtrl;
        this.building = new BuildingDTO();
        this.inputBuilding = '';
        this.stopFuzzy = false;
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.changePropertyType = false;
        this.count = 0;
        this.isUpdate = false;
    }
    SpotBuildingsDetailComponent.prototype.ngOnInit = function () {
        this.checkBuildingType(2001);
        this.multiLangInit();
        this.checkBuildingOffer(1, 1);
        this.checkListingType(1);
        this.checkBuildingTypeSale(false);
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    };
    SpotBuildingsDetailComponent.prototype.ngOnChanges = function (changes) {
        if (this.buildingDetail) {
            this.isUpdate = true;
            this.checkBuildingType(this.buildingDetail.building.buildingType.id);
            this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
            this.checkListingType(this.buildingDetail.listingType == ListingType.Featured
                ? 1
                : this.buildingDetail.listingType == ListingType.Premium
                    ? 2
                    : this.buildingDetail.listingType == ListingType.Promoted
                        ? 3
                        : 4);
            this.setProperty(this.buildingDetail.building, true);
            this.checkBuildingTypeSale(this.buildingDetail.entireSale);
            if (this.buildingDetail.offMarket) {
                this.spotBuildings.offMarket = this.buildingDetail.offMarket;
            }
            if (this.buildingDetail.featured) {
                this.spotBuildings.featured = this.buildingDetail.featured;
            }
            if (this.buildingDetail.enabled) {
                this.spotBuildings.enabled = this.buildingDetail.enabled;
            }
        }
    };
    SpotBuildingsDetailComponent.prototype.clearInput = function () {
        this.inputBuilding = '';
        this.building.id = null;
        this.spotBuildings.building = this.building;
        this.spotBuildings.propertyName = '';
        this.stopFuzzy = true;
    };
    SpotBuildingsDetailComponent.prototype.initBuildingType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            return tslib_1.__generator(this, function (_a) {
                options = {
                    keys: ['name'],
                    shouldSort: true,
                    caseSensitive: false,
                    threshold: 0.5,
                    findAllMatches: true
                };
                this.fuse = new Fuse(this.buildings, options);
                return [2 /*return*/];
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.checkBuildingOffer = function (typeOffer, init) {
        if (init === void 0) { init = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isValid, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spotBuildings.type = '';
                        if (!(init != 0)) return [3 /*break*/, 1];
                        _a = true;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.isValidProperty(this.spotBuildings.building ? this.spotBuildings.building : null, typeOffer == 1
                            ? ListingOfferType.Lease
                            : typeOffer == 2
                                ? ListingOfferType.Coworking
                                : ListingOfferType.Sale, 1)];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        isValid = _a;
                        if (isValid) {
                            if (typeOffer == 1) {
                                this.spotBuildings.type = ListingOfferType.Lease;
                                this.spotBuildings.entireSale = false;
                            }
                            if (typeOffer == 2) {
                                this.spotBuildings.type = ListingOfferType.Coworking;
                                this.spotBuildings.entireSale = false;
                                if (this.propertyType[0] != 2001) {
                                    this.checkBuildingType(2001);
                                }
                            }
                            if (typeOffer == 3) {
                                this.spotBuildings.type = ListingOfferType.Sale;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.checkListingType = function (listingType) {
        if (listingType == 1) {
            this.spotBuildings.listingType = ListingType.Featured;
            return;
        }
        if (listingType == 2) {
            this.spotBuildings.listingType = ListingType.Premium;
            return;
        }
        if (listingType == 3) {
            this.spotBuildings.listingType = ListingType.Promoted;
            return;
        }
        if (listingType == 4) {
            this.spotBuildings.listingType = ListingType.Basic;
            return;
        }
    };
    SpotBuildingsDetailComponent.prototype.checkBuildingType = function (buildingType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.clearInput();
                        if (!(buildingType === 1001)) return [3 /*break*/, 4];
                        if (!!this.industrialBuildings) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(1001)];
                    case 1:
                        _a.industrialBuildings = _d.sent();
                        this.buildingNames = this.industrialBuildings;
                        this.buildings = this.industrialBuildings.values;
                        return [3 /*break*/, 3];
                    case 2:
                        this.buildingNames = this.industrialBuildings;
                        this.buildings = this.industrialBuildings.values;
                        _d.label = 3;
                    case 3:
                        this.propertyType[0] = 1001;
                        this.stopFuzzy = false;
                        _d.label = 4;
                    case 4:
                        if (!(buildingType === 2001)) return [3 /*break*/, 8];
                        if (!!this.officeBuildings) return [3 /*break*/, 6];
                        _b = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(2001)];
                    case 5:
                        _b.officeBuildings = _d.sent();
                        this.buildingNames = this.officeBuildings;
                        this.buildings = this.officeBuildings.values;
                        return [3 /*break*/, 7];
                    case 6:
                        this.buildingNames = this.officeBuildings;
                        this.buildings = this.officeBuildings.values;
                        _d.label = 7;
                    case 7:
                        this.propertyType[0] = 2001;
                        this.stopFuzzy = false;
                        _d.label = 8;
                    case 8:
                        if (!(buildingType === 3001)) return [3 /*break*/, 12];
                        if (!!this.retailBuildings) return [3 /*break*/, 10];
                        _c = this;
                        return [4 /*yield*/, this.purchaseService.getBuildingNamesByPropertyType(3001)];
                    case 9:
                        _c.retailBuildings = _d.sent();
                        this.buildingNames = this.retailBuildings;
                        this.buildings = this.retailBuildings.values;
                        return [3 /*break*/, 11];
                    case 10:
                        this.buildingNames = this.retailBuildings;
                        this.buildings = this.retailBuildings.values;
                        _d.label = 11;
                    case 11:
                        this.propertyType[0] = 3001;
                        this.stopFuzzy = false;
                        _d.label = 12;
                    case 12:
                        this.initBuildingType();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.checkBuildingTypeSale = function (isTypeOfSale) {
        this.spotBuildings.entireSale = isTypeOfSale;
    };
    SpotBuildingsDetailComponent.prototype.suggest_property = function (term) {
        if (term) {
            var q = term.toLowerCase().trim();
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map(function (element) {
                return element;
            });
        }
    };
    SpotBuildingsDetailComponent.prototype.changeFuzzySearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.changePropertyType) {
                    this.stopFuzzy = this.inputBuilding == '' ? false : true;
                    this.buildingNames = this.suggest_property(this.inputBuilding);
                }
                else {
                    this.changePropertyType = false;
                    this.stopFuzzy = false;
                }
                return [2 /*return*/];
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.setProperty = function (building, update) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isValid;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isValidProperty(building, this.spotBuildings.type, 2, update)];
                    case 1:
                        isValid = _a.sent();
                        if (isValid) {
                            this.stopFuzzy = true;
                            this.inputBuilding = update ? this.i18NService.getTranslation(this.buildingDetail.title) : building.name;
                            this.building.id = building.id;
                            this.spotBuildings.building = this.building;
                            this.propertyName = update
                                ? this.buildingDetail.title
                                : "{\"en\":\"" + building.name.replace(/"/g, '\\"') + "\",\"pt-br\":\"" + building.name.replace(/"/g, '\\"') + "\"}";
                            this.buildingNames = null;
                            this.listingService.getBuilding(building.id).then(function (resp) {
                                _this.actualBuilding = resp;
                                _this.marker = { lat: _this.actualBuilding.latitude, lng: _this.actualBuilding.longitude };
                            });
                            this.changePropertyType = true;
                            if (!update) {
                                this.listingService.getBuilding(building.id).then(function (resp) {
                                    _this.checkFeaturesRequest(resp);
                                });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.isValidProperty = function (building, type, opt, update) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (update) {
                            return [2 /*return*/, true];
                        }
                        if (!(building.id && type)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createSpotBuildingsService
                                .validateProperty(building.id, type, this.i18NService.getCurrentLanguage())
                                .then(function (resp) {
                                if (resp == null) {
                                    return true;
                                }
                                else {
                                    _this.alertCtrl
                                        .create({
                                        header: _this.i18NService.get('global.form.sendMsgToAgent.notification'),
                                        message: "<span>" + _this.i18NService.get('buildinsDetail.validateProperty') + " \n                <p class=\"color-p\">" + resp.detailsURL + "</p></span>",
                                        buttons: [
                                            {
                                                text: _this.i18NService.get('global.form.sendMsgToAgent.ok'),
                                                handler: function () {
                                                    /* this.popoverCtrl.dismiss(); */
                                                    _this.initBuilding(opt);
                                                }
                                            },
                                            {
                                                text: 'Go to Building',
                                                handler: function () {
                                                    _this.initBuilding(opt);
                                                    _this.openUrlBuilding(resp.detailsURL);
                                                }
                                            }
                                        ]
                                    })
                                        .then(function (alert) {
                                        alert.present();
                                    });
                                    return false;
                                }
                            })];
                    case 1:
                        resp = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        resp = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/, resp];
                }
            });
        });
    };
    SpotBuildingsDetailComponent.prototype.openUrlBuilding = function (url) {
        if (this.isDesktop.length) {
            window.open(url, '_blank');
        }
        else {
            this.router.navigateByUrl(url);
        }
    };
    SpotBuildingsDetailComponent.prototype.initBuilding = function (opt) {
        if (this.buildingDetail) {
            if (opt == 1) {
                this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
            }
            else {
                this.setProperty(this.buildingDetail.building, true);
            }
        }
        else {
            this.clearInput();
            this.checkBuildingOffer(1, 1);
        }
    };
    SpotBuildingsDetailComponent.prototype.checkFeaturesRequest = function (features) {
        this.spotBuildings.twentyFourHourSecurity = features.twentyFourHourSecurity;
        this.spotBuildings.electricGenerator = features.electricGenerator;
        this.spotBuildings.heliport = features.heliport;
        this.spotBuildings.sprinklers = features.sprinklers;
        this.spotBuildings.leedStatus = features.leedStatus;
        this.spotBuildings.singleTenant = features.singleTenant;
        this.spotBuildings.multiTenant = features.multiTenant;
        this.spotBuildings.bicycleRack = features.bicycleRack;
        this.spotBuildings.fiberOptic = features.fiberOptic;
        this.spotBuildings.centralAirConditioning = features.centralAirConditioning;
        this.spotBuildings.petFriendly = features.petFriendly;
        this.spotBuildings.truckYard = features.truckYard;
        this.spotBuildings.crossDocking = features.crossDocking;
        this.spotBuildings.sluiceGate = features.sluiceGate;
        this.spotBuildings.blockAndSteelConstruction = features.blockAndSteelConstruction;
        this.spotBuildings.blockConstruction = features.blockConstruction;
        this.spotBuildings.steelConstruction = features.steelConstruction;
        this.spotBuildings.rentableArea = features.rentableArea;
        this.spotBuildings.ceilingHeight = features.ceilingHeight;
        this.spotBuildings.parkingSpaces = features.parkingSpaces;
        this.spotBuildings.industrialStatus = features.industrialStatus;
        this.spotBuildings.yearBuilt = features.yearBuilt;
        this.spotBuildings.carParkingSpaces = features.carParkingSpaces;
        this.spotBuildings.truckParkingSpaces = features.industrialParkingSpaces;
        this.spotBuildings.landArea = features.landArea;
        this.spotBuildings.numberStories = features.numberStories;
        this.spotBuildings.averageFloorPrivateArea = features.averageFloorPrivateArea;
        this.spotBuildings.address = this.validateAddress(features.address);
    };
    SpotBuildingsDetailComponent.prototype.validateAddress = function (BuildingAddress) {
        var address = JSON.parse(BuildingAddress);
        return address['pt-br'] && address['en']
            ? BuildingAddress
            : address['pt-br']
                ? "{\"en\":\"" + address['pt-br'] + "\",\"pt-br\":\"" + address['pt-br'] + "\"}"
                : "{\"en\":\"" + address['en'] + "\",\"pt-br\":\"" + address['en'] + "\"}";
    };
    SpotBuildingsDetailComponent.prototype.multiLangInit = function () {
        this.propertyNameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyNameDisplay',
            element: { input: true },
            formArrayName: 'property',
            required: true
        };
    };
    SpotBuildingsDetailComponent.prototype.propertyNameChange = function (value) {
        var _this = this;
        if (value.property.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.property).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.property[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1
                ? (this.spotBuildings.propertyName = '')
                : (this.spotBuildings.propertyName = JSON.stringify(mergedObject_1));
        }
        else {
            this.spotBuildings.propertyName = '';
        }
    };
    return SpotBuildingsDetailComponent;
}());
export { SpotBuildingsDetailComponent };
