import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { LanguageService } from '@app/core';
import { ListingPriceService, EventCheckerService } from '@app/core/listings';
var ListingFeaturesComponent = /** @class */ (function () {
    function ListingFeaturesComponent(registerService, langService, listingPriceService, _messageService) {
        this.registerService = registerService;
        this.langService = langService;
        this.listingPriceService = listingPriceService;
        this._messageService = _messageService;
    }
    ListingFeaturesComponent.prototype.ngOnInit = function () {
        this.listing ? this.setExistingFeatures() : null;
        this.detectFeatures();
    };
    ListingFeaturesComponent.prototype.detectFeatures = function () {
        var _this = this;
        this._messageService.updateOfficeFilters$.subscribe(function (pOfficeFilters) {
            if (pOfficeFilters) {
                _this.officeFilters = pOfficeFilters;
            }
        });
        this._messageService.updateIndustrialFilters$.subscribe(function (pFilters) {
            if (pFilters) {
                _this.industrialFilters = pFilters;
            }
        });
    };
    ListingFeaturesComponent.prototype.setExistingFeatures = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, res, _b, res;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.listing.building.buildingType.id === 1001)) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeatures(this.listing, null, this.industrialFilters)];
                    case 1:
                        _a.industrialFilters = _c.sent();
                        return [4 /*yield*/, this.setFormFeatures('industrial', this.listing)];
                    case 2:
                        res = _c.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        _b = this;
                        return [4 /*yield*/, this.registerService.selectExistingFeatures(this.listing, this.officeFilters, null)];
                    case 4:
                        _b.officeFilters = _c.sent();
                        return [4 /*yield*/, this.setFormFeatures('office', this.listing)];
                    case 5:
                        res = _c.sent();
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ListingFeaturesComponent.prototype.setOfficeTypes = function (index) {
        if (this.officeFilters[index].selected) {
            this.officeFilters[index].selected = false;
        }
        else {
            this.officeFilters[index].selected = true;
        }
        this.listingFeaturesForm.controls['tags'].setValue(this.officeFilters);
    };
    ListingFeaturesComponent.prototype.setIndustrialTypes = function (index) {
        if (this.industrialFilters[index].selected) {
            this.industrialFilters[index].selected = false;
        }
        else {
            this.industrialFilters[index].selected = true;
        }
        this.listingFeaturesForm.controls['tags'].setValue(this.industrialFilters);
    };
    ListingFeaturesComponent.prototype.formatHeight = function () {
        var height = this.listingFeaturesForm.value.ceilingHeight;
        height = this.listingPriceService.convertForNumber(height);
        if (height > 0) {
            height = this.listingPriceService.convertForDisplay(height);
            this.listingFeaturesForm.controls['ceilingHeight'].setValue(height);
        }
    };
    ListingFeaturesComponent.prototype.formatBaySize = function () {
        var baySize = this.listingFeaturesForm.value.baySize;
        baySize = this.listingPriceService.convertForNumber(baySize);
        if (baySize > 0) {
            baySize = this.listingPriceService.convertForDisplay(baySize);
            this.listingFeaturesForm.controls['baySize'].setValue(baySize);
        }
    };
    ListingFeaturesComponent.prototype.formatDockRatio = function () {
        var dockRatio = this.listingFeaturesForm.value.dockRatio;
        dockRatio = this.listingPriceService.convertForNumber(dockRatio);
        if (dockRatio > 0) {
            dockRatio = this.listingPriceService.convertForDisplay(dockRatio);
            this.listingFeaturesForm.controls['dockRatio'].setValue(dockRatio);
        }
    };
    ListingFeaturesComponent.prototype.checkCharacters = function (event, dec) {
        var num = event.detail.value;
        var regexp;
        if (num) {
            var lastChar = num.substr(num.length - 1);
            if (dec) {
                if (this.langService.locale === 'en-US') {
                    regexp = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
                }
                else {
                    regexp = /^-?(?:\d+|\d{1,3}(?:.\d{3})+)(?:(\.|,)\d+)?$/;
                }
            }
            else {
                regexp = /^[0-9]*$/;
            }
            var tempValue = num.substring(0, num.length - 1);
            if ((!regexp.test(num) && lastChar !== '.' && lastChar !== ',') ||
                num.substr(0) === '.' ||
                num.substr(0) === ',' ||
                (!dec && !regexp.test(num))) {
                event.srcElement.value = tempValue;
            }
            // Validating maximum value for integer
            if (!dec && tempValue > 2147483647) {
                event.srcElement.value = 2147483647;
            }
        }
    };
    ListingFeaturesComponent.prototype.setFormFeatures = function (type, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(building).forEach(function (key) {
                    if (type === 'industrial') {
                        if (key === 'baySize') {
                            _this.listingFeaturesForm.controls['baySize'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'dockRatio') {
                            _this.listingFeaturesForm.controls['dockRatio'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'truckParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['truckParkingSpaces'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'loadCapacity') {
                            _this.listingFeaturesForm.controls['loadCapacity'].setValue(building[key].toFixed(2));
                        }
                        if (key === 'ceilingHeight') {
                            _this.listingFeaturesForm.controls['ceilingHeight'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                        if (key === 'carParkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['carParkingSpaces'].setValue(building[key].toFixed(2));
                        }
                    }
                    else if (type === 'office') {
                        if (key === 'parkingSpaces' && typeof building[key] !== 'boolean') {
                            _this.listingFeaturesForm.controls['parkingSpaces'].setValue(building[key].toFixed(2));
                        }
                        /*if (key === 'restrooms') {
                          this.listingFeaturesForm.controls['restrooms'].setValue(building[key]);
                        }*/
                        if (key === 'ceilingHeight') {
                            _this.listingFeaturesForm.controls['ceilingHeight'].setValue(_this.langService.locale === 'en-US'
                                ? building[key].toFixed(2)
                                : building[key].toFixed(2).replace(/\./g, ','));
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return ListingFeaturesComponent;
}());
export { ListingFeaturesComponent };
