import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { UnitOfMeasure } from '../../../../models/spot-buildings/spot-available-spaces';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '../../../../core/i18n.service';
import { PlatformHelperService } from '@app/core';
import { CommonGenericService } from '../../../../core/common.service';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
import { CoworkingSpaceType } from '../../../../models/spot-buildings/spot-available-spaces';
import { RequestInformationType } from '../../../../models/request-information.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { VideoComponent } from '@app/shared/video/video.component';
var SpaceDetailsComponent = /** @class */ (function () {
    function SpaceDetailsComponent(navParams, i18n, popoverCtrl, platformConfigHelper, spacesService, spotBuildingsService, streamingMedia, commonService) {
        this.navParams = navParams;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.platformConfigHelper = platformConfigHelper;
        this.spacesService = spacesService;
        this.spotBuildingsService = spotBuildingsService;
        this.streamingMedia = streamingMedia;
        this.commonService = commonService;
        this.emptyFieldValue = '--';
        this.isMobile = false;
    }
    SpaceDetailsComponent.prototype.ngOnInit = function () {
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.spot = this.navParams.data.spot;
        this.space = this.navParams.data.space;
        this.closeIcon = faTimesCircle;
        this.buildingTypeDTO = this.spot.building.buildingType;
        this.isForIndustrial = this.buildingTypeDTO.id == 1001;
        this.isForOffice = this.buildingTypeDTO.id == 2001;
        this.isForCoworking = this.spot.type == 'C';
        if (this.isForCoworking) {
            this.isForOffice = false;
        }
        this.showSpaceImgs = true;
        this.spaceDescription = this.i18n.getTranslation(this.space.description);
        this.investmentOpportunity = this.space.investmentOpportunity
            ? this.i18n.getTranslation(this.space.investmentOpportunity)
            : undefined;
        this.spotTitle = this.getSpotTitle();
        this.spacePhotos = this.space.spacePhotos;
        this.fillUpDetailsTableRows();
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
    };
    SpaceDetailsComponent.prototype.fillUpDetailsTableRows = function () {
        this.detailsTableRows = [];
        if (this.isForOffice) {
            if (this.spot.forLease) {
                var firstRow = {
                    col1: this.getMaxContiguousTableField(),
                    col2: this.getMonthlyRentTableField()
                };
                var secondRow = {
                    col1: this.getCeilingHeightOfficeTableField(),
                    col2: this.getCondoTableField()
                };
                var thirdRow = {
                    col1: this.getParkingSpacesTableField(),
                    col2: this.getIptuTableField()
                };
                var fourthRow = {
                    col1: this.getDirectSubleaseTableField(),
                    col2: this.getTotalTableField()
                };
                this.detailsTableRows.push(firstRow);
                this.detailsTableRows.push(secondRow);
                this.detailsTableRows.push(thirdRow);
                this.detailsTableRows.push(fourthRow);
            }
            if (this.spot.forSale) {
                var firstRow = {
                    col1: this.getCeilingHeightOfficeTableField(),
                    col2: this.getParkingSpacesTableField()
                };
                this.detailsTableRows.push(firstRow);
            }
        }
        if (this.isForIndustrial) {
            if (this.spot.forLease) {
                var row1 = {
                    col1: this.getDocksTableField(),
                    col2: this.getIncludesRetailTableField()
                };
                this.detailsTableRows.push(row1);
                var row2 = {
                    col1: this.getTruckParkingSpacesTableField(),
                    col2: this.getFullBuildOutTableField()
                };
                this.detailsTableRows.push(row2);
                var row3 = {
                    col1: this.getLoadCapacityTableField(),
                    col2: this.getDirectSubleaseTableField()
                };
                this.detailsTableRows.push(row3);
                var row4 = {
                    col1: this.getSkyLightsTableField(),
                    col2: this.getMonthlyRentTableField()
                };
                this.detailsTableRows.push(row4);
                var row5 = {
                    col1: this.getCeilingHeightForIndustrial(),
                    col2: this.getCondoTableField()
                };
                this.detailsTableRows.push(row5);
                var row6 = {
                    col1: this.getColdStorageTableField(),
                    col2: this.getIptuTableField()
                };
                this.detailsTableRows.push(row6);
                var row7 = {
                    col1: this.getIncludesOfficeTableField(),
                    col2: this.getTotalTableField()
                };
                this.detailsTableRows.push(row7);
            }
            if (this.spot.forSale) {
                var row1 = {
                    col1: this.getDocksTableField(),
                    col2: this.getColdStorageTableField()
                };
                this.detailsTableRows.push(row1);
                var row2 = {
                    col1: this.getTruckParkingSpacesTableField(),
                    col2: this.getIncludesOfficeTableField()
                };
                this.detailsTableRows.push(row2);
                var row3 = {
                    col1: this.getLoadCapacityTableField(),
                    col2: this.getIncludesRetailTableField()
                };
                this.detailsTableRows.push(row3);
                var row4 = {
                    col1: this.getSkyLightsTableField(),
                    col2: this.getFullBuildOutTableField()
                };
                this.detailsTableRows.push(row4);
                var row5 = {
                    col1: this.getCeilingHeightForIndustrial(),
                    col2: undefined
                };
                this.detailsTableRows.push(row5);
            }
        }
        if (this.isForCoworking) {
            var firstRow = {
                col1: this.getconferenceTimeHours(),
                col2: this.getparkingCost()
            };
            var secondRow = {
                col1: this.getprivateOutdoor(),
                col2: this.getprintCopies()
            };
            var thirdRow = {
                col1: this.getsharedOutdoor(),
                col2: this.getMainHandling()
            };
            var fourthRow = {
                col1: this.getparkingAvailable(),
                col2: null
            };
            this.detailsTableRows.push(firstRow);
            this.detailsTableRows.push(secondRow);
            this.detailsTableRows.push(thirdRow);
            this.detailsTableRows.push(fourthRow);
        }
    };
    SpaceDetailsComponent.prototype.closeModal = function () {
        this.popoverCtrl.dismiss();
    };
    SpaceDetailsComponent.prototype.requestInfoAction = function (event) {
        this.popoverCtrl.dismiss({
            event: event,
            requestInfo: true,
            spot: this.spot,
            space: this.space,
            spotTitle: this.spotTitle,
            requestType: RequestInformationType.REQ_INFO
        });
    };
    SpaceDetailsComponent.prototype.requestTourAction = function (event) {
        this.popoverCtrl.dismiss({
            event: event,
            requestInfo: true,
            spot: this.spot,
            space: this.space,
            spotTitle: this.spotTitle,
            requestType: RequestInformationType.REQ_TOUR
        });
    };
    SpaceDetailsComponent.prototype.openVideoTour = function (pEvent) {
        var _this = this;
        if (this.space.vrTourUrl) {
            this.openVideoTourPopup(pEvent, this.space.vrTourUrl, this.spot.propertyName, this.isMobile, function (error) {
                console.error('Callback from video tour: ', error);
                _this.spotBuildingsService.logError(error);
            });
        }
    };
    SpaceDetailsComponent.prototype.openVideoTourPopup = function (ev, videoURL, spotTitle, isMobile, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options, popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isMobile) {
                            if (videoURL) {
                                options = {
                                    successCallback: function () { },
                                    errorCallback: function (e) {
                                        console.error('Error streaming', +e);
                                        callback(e);
                                    },
                                    orientation: 'landscape',
                                    shouldAutoClose: false,
                                    controls: true
                                };
                                try {
                                    this.streamingMedia.playVideo(videoURL, options);
                                }
                                catch (err) {
                                    console.error(err);
                                    if (callback) {
                                        callback(err);
                                    }
                                }
                            }
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.popoverCtrl.create({
                                component: VideoComponent,
                                event: ev,
                                cssClass: 'video-player-popup',
                                componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
                                backdropDismiss: true
                            })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpaceDetailsComponent.prototype.getSpotTitle = function () {
        var val = this.moduleSuiteValue();
        if (val == '' || val == null) {
            return this.warehouseFloorTitle() + " " + this.warehouseFloorValue() + " - " + this.spot.propertyName;
        }
        else {
            return this.warehouseFloorTitle() + " " + this.warehouseFloorValue() + ", " + this.moduleSuiteTitle() + " " + this.moduleSuiteValue() + " - " + this.spot.propertyName;
        }
    };
    SpaceDetailsComponent.prototype.warehouseFloorTitle = function () {
        if (this.isForIndustrial) {
            return this.i18n.get('buildinsDetail.wareHouse') + ':';
        }
        if (this.isForOffice || this.isForCoworking) {
            return this.i18n.get('buildinsDetail.floor') + ':';
        }
    };
    SpaceDetailsComponent.prototype.warehouseFloorValue = function () {
        if (this.isForIndustrial) {
            return this.space.warehouse ? this.space.warehouse : '';
        }
        if (this.isForOffice || this.isForCoworking) {
            return this.space.floor ? this.findFloorTranslation(this.space.floor) : '';
        }
    };
    SpaceDetailsComponent.prototype.moduleSuiteTitle = function () {
        if (this.isForIndustrial) {
            return this.i18n.get('buildinsDetail.module') + ':';
        }
        if (this.isForOffice) {
            return this.i18n.get('buildinsDetail.suite') + ':';
        }
        if (this.isForCoworking) {
            if (this.space.coworkingType) {
                if (this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                    return this.i18n.get('buildinsDetail.suite') + ':';
                }
                if (this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE) {
                    return this.i18n.get('spaceDetail.desk') + ':';
                }
            }
        }
        return '';
    };
    SpaceDetailsComponent.prototype.moduleSuiteValue = function () {
        if (this.isForIndustrial) {
            return this.space.module ? this.space.module : '';
        }
        if (this.isForOffice) {
            return this.space.suite ? this.space.suite : '';
        }
        if (this.isForCoworking) {
            if (this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE ||
                this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
                return this.space.suite ? this.space.suite : '';
            }
        }
        return '';
    };
    SpaceDetailsComponent.prototype.getAvailableSpaceTitle = function () {
        return this.i18n.get('spaceDetail.availableSpace') + ':';
    };
    SpaceDetailsComponent.prototype.getAvailableSpaceValue = function () {
        var area = this.commonService.formatNumberTo(this.space.area, 0) ? this.space.area : 0;
        return this.formatValueBasedOnMeasure(UnitOfMeasure.SQUARE_METERS, area);
    };
    SpaceDetailsComponent.prototype.getCoworkingPositionsTitle = function () {
        if (this.isForCoworking) {
            return this.i18n.get('spaceDetail.availablePositions') + ':';
        }
    };
    SpaceDetailsComponent.prototype.getPriceTitle = function () {
        if (this.spot.forLease) {
            return this.i18n.get('spaceDetail.monthlyRent').concat(':');
        }
        else {
            return this.i18n.get('spaceDetail.askingPrice') + ':';
        }
    };
    SpaceDetailsComponent.prototype.getCoworkingPositions = function () {
        if (this.isForCoworking) {
            return this.space.positions;
        }
    };
    SpaceDetailsComponent.prototype.getPriceValue = function () {
        var value = undefined;
        if (this.spot.forSale) {
            value = this.space.askingPrice;
        }
        if (this.spot.forLease) {
            value = this.spacesService.getTotalMonthlyRent(this.space);
        }
        if (value) {
            value = this.commonService.formatNumberTo(value, 0);
            value = this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE, value);
        }
        return value != 0 && value != null ? value : this.i18n.get('global.list-your-property.negotiable');
    };
    SpaceDetailsComponent.prototype.getPricePerAreaTitle = function () {
        if (this.spot.forLease) {
            return this.i18n.get('spaceDetail.monthlyRentRm').concat(':');
        }
        else {
            return this.i18n.get('spaceDetail.askingPriceRm') + ':';
        }
    };
    SpaceDetailsComponent.prototype.getPricePerAreaValue = function () {
        var value = undefined;
        if (this.spot.forLease) {
            if (!this.space.area) {
                value = '0';
            }
            else {
                value = this.commonService.formatNumberTo(this.spacesService.getTotalMonthlyRent(this.space) / this.space.area);
            }
        }
        else {
            if (!this.space.area) {
                value = '0';
            }
            else {
                value = this.commonService.formatNumberTo(this.space.askingPrice / this.space.area);
            }
        }
        if (value != '0') {
            return this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE_SQUARE_METERS, value);
        }
        else {
            return this.i18n.get('global.list-your-property.negotiable');
        }
    };
    SpaceDetailsComponent.prototype.getMonthlyCostTitle = function () {
        return this.i18n.get('buildinsDetail.MonthlyCostCoworking') + ':';
    };
    SpaceDetailsComponent.prototype.getMonthlyCostValue = function () {
        return this.commonService.getMonthlyCostWithPositions(this.space);
        /* const value = this.space.monthlyCost;
        if (value) {
          return this.formatValueBasedOnMeasure(UnitOfMeasure.PRICE, value);
        } */
    };
    SpaceDetailsComponent.prototype.getFieldValue = function (val, unitMeasure, decimals) {
        if (!unitMeasure && !decimals)
            return val ? val : this.emptyFieldValue;
        var fieldValue = decimals && val ? this.commonService.formatNumberTo(val, decimals) : val;
        fieldValue = this.formatValueBasedOnMeasure(unitMeasure, fieldValue);
        if (!fieldValue) {
            return this.emptyFieldValue;
        }
        return fieldValue;
    };
    SpaceDetailsComponent.prototype.formatValueBasedOnMeasure = function (unitMeasure, fieldValue) {
        if (unitMeasure && fieldValue) {
            if (unitMeasure == UnitOfMeasure.PRICE) {
                fieldValue = unitMeasure + " " + fieldValue;
            }
            else {
                fieldValue = fieldValue + " " + unitMeasure;
            }
        }
        return fieldValue;
    };
    SpaceDetailsComponent.prototype.getBooleanFieldValue = function (val) {
        return val ? this.i18n.get('global.revisionChanges.yes') : this.i18n.get('global.revisionChanges.no');
    };
    SpaceDetailsComponent.prototype.getMaxContiguousTableField = function () {
        var field = {
            name: 'maxContiguous',
            translate: 'spaceDetail.maxContiguous',
            value: this.getPropertyValue('maxContiguous', UnitOfMeasure.SQUARE_METERS)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getMonthlyRentTableField = function () {
        var field = {
            name: 'R',
            translate: 'spaceDetail.R',
            priceClass: true,
            value: this.getPropertyValue('R', UnitOfMeasure.PRICE, 2)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getCeilingHeightOfficeTableField = function () {
        var fieldName = 'ceilingHeightOffice';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.METERS)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getCondoTableField = function () {
        var fieldName = 'condo';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getParkingSpacesTableField = function () {
        var fieldName = 'parkingSpaces';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            value: this.getPropertyValue(fieldName)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getIptuTableField = function () {
        var fieldName = 'iptu';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getDirectSubleaseTableField = function () {
        var fieldName = 'directSublease';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            value: this.getPropertyValue(fieldName)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getTotalTableField = function () {
        var fieldName = 'total';
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            priceClass: true,
            value: this.getPropertyValue(fieldName, UnitOfMeasure.PRICE, 2)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getDocksTableField = function () {
        return this.createTableField('docks');
    };
    SpaceDetailsComponent.prototype.getIncludesRetailTableField = function () {
        return this.createTableField('includesRetail');
    };
    SpaceDetailsComponent.prototype.getTruckParkingSpacesTableField = function () {
        return this.createTableField('truckParkingSpaces');
    };
    SpaceDetailsComponent.prototype.getFullBuildOutTableField = function () {
        return this.createTableField('fullBuildOut');
    };
    SpaceDetailsComponent.prototype.getLoadCapacityTableField = function () {
        return this.createTableField('loadCapacity', UnitOfMeasure.SQUARE_TON_METERS, 0);
    };
    SpaceDetailsComponent.prototype.getSkyLightsTableField = function () {
        return this.createTableField('skyLights');
    };
    SpaceDetailsComponent.prototype.getCeilingHeightForIndustrial = function () {
        return this.createTableField('ceilingHeight', UnitOfMeasure.METERS, 0);
    };
    SpaceDetailsComponent.prototype.getColdStorageTableField = function () {
        return this.createTableField('coldStorage');
    };
    SpaceDetailsComponent.prototype.getconferenceTimeHours = function () {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('conferenceCredits')
            : this.createTableField('conferenceTimeHours');
    };
    SpaceDetailsComponent.prototype.getparkingCost = function () {
        return this.createTableField('parkingCost', UnitOfMeasure.PRICE, 0);
    };
    SpaceDetailsComponent.prototype.getprivateOutdoor = function () {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('privateOutdoorSpace')
            : this.createTableField('privateOutdoor');
    };
    SpaceDetailsComponent.prototype.getprintCopies = function () {
        return this.spot.offeredByCompany.name == 'WeWork'
            ? this.createTableField('printsCopiesMonth')
            : this.createTableField('printCopies', '', 0);
    };
    SpaceDetailsComponent.prototype.getsharedOutdoor = function () {
        return this.createTableField('sharedOutdoor');
    };
    SpaceDetailsComponent.prototype.getMainHandling = function () {
        return this.createTableField('mainHandling');
    };
    SpaceDetailsComponent.prototype.getparkingAvailable = function () {
        return this.createTableField('parkingAvailable');
    };
    SpaceDetailsComponent.prototype.getIncludesOfficeTableField = function () {
        return this.createTableField('includesOffice');
    };
    SpaceDetailsComponent.prototype.createTableField = function (fieldName, unit, decimals) {
        var field = {
            name: fieldName,
            translate: "spaceDetail." + fieldName,
            value: this.getPropertyValue(fieldName, unit, decimals)
        };
        return field;
    };
    SpaceDetailsComponent.prototype.getPropertyValue = function (propertyName, unitOfMeasure, decimals) {
        switch (propertyName) {
            case 'docks':
                return this.getFieldValue(this.space.docks, unitOfMeasure, decimals);
            case 'includesRetail':
                return this.getBooleanFieldValue(this.space.includesRetail);
            case 'cardParkingSpaces':
            case 'parkingSpaces':
                return this.getFieldValue(this.space.parkingSpaces, unitOfMeasure, decimals);
            case 'fullBuildOut':
                return this.getBooleanFieldValue(this.space.fullBuildOut);
            case 'truckParkingSpaces':
                return this.getFieldValue(this.space.truckParkingSpaces, unitOfMeasure, decimals);
            case 'directSublease':
                return this.commonService.getLeaseTypeTxt(this.space.industrialLeaseType);
            case 'loadCapacity':
                return this.getFieldValue(this.space.loadCapacity, unitOfMeasure, decimals);
            case 'R':
            case 'rentPerm2':
                return this.getFieldValue(this.space.monthlyAskingRent, unitOfMeasure, decimals);
            case 'skyLights':
                return this.getBooleanFieldValue(this.space.skyLights);
            case 'condo':
            case 'condoPerm2':
                return this.getFieldValue(this.space.monthlyCondo, unitOfMeasure, decimals);
            case 'ceilingHeight':
            case 'ceilingHeightOffice':
                return this.getFieldValue(this.space.ceilingHeight, unitOfMeasure, decimals);
            case 'iptu':
            case 'iptuPerm2':
                return this.getFieldValue(this.space.monthlyIptu, unitOfMeasure, decimals);
            case 'includesOffice':
                return this.getBooleanFieldValue(this.space.includesOffice);
            case 'coldStorage':
                return this.getBooleanFieldValue(this.space.coldStorage);
            case 'maxContiguous':
                return this.getFieldValue(this.space.maxContiguous, unitOfMeasure, decimals);
            case 'conferenceTimeHours':
                return this.getFieldValue(this.space.conferenceTimeHours, unitOfMeasure, decimals);
            case 'parkingCost':
                return this.getFieldValue(this.space.parkingCost, unitOfMeasure, decimals);
            case 'privateOutdoor':
                return this.getBooleanFieldValue(this.space.privateOutdoor);
            case 'printCopies':
                return this.getFieldValue(this.space.printCopies, unitOfMeasure, decimals);
            case 'sharedOutdoor':
                return this.getBooleanFieldValue(this.space.sharedOutdoor);
            case 'mainHandling':
                return this.getBooleanFieldValue(this.space.mailHandling);
            case 'parkingAvailable':
                return this.getBooleanFieldValue(this.space.parkingAvailable);
            case 'total':
                return this.getFieldValue(this.spacesService.getTotalMonthlyRent(this.space, true), unitOfMeasure, decimals);
            case 'conferenceCredits':
                return this.getFieldValue(this.space.conferenceTimeHours, unitOfMeasure, decimals);
            case 'privateOutdoorSpace':
                return this.getFieldValue(this.space.privateOutdoorCredits, unitOfMeasure, decimals);
            case 'printsCopiesMonth':
                return this.getFieldValue(this.space.printCopies, unitOfMeasure, decimals);
            default:
                return this.emptyFieldValue;
        }
    };
    SpaceDetailsComponent.prototype.toogleAction = function (action) {
        switch (action) {
            case 'moreInfo':
                this.showMoreInfo = this.showMoreInfo ? this.showMoreInfo : !this.showMoreInfo;
                this.showSpaceImgs = false;
                this.showMediaKit = false;
                break;
            case 'spaceImgs':
                this.showMoreInfo = false;
                this.showSpaceImgs = this.showSpaceImgs ? this.showSpaceImgs : !this.showSpaceImgs;
                this.showMediaKit = false;
                break;
            case 'mediaKit':
                this.showMoreInfo = false;
                this.showSpaceImgs = false;
                this.showMediaKit = this.showMediaKit ? this.showMediaKit : !this.showMediaKit;
                break;
        }
    };
    SpaceDetailsComponent.prototype.findFloorTranslation = function (floorValue) {
        if (!floorValue) {
            return '';
        }
        var translationKey = null;
        if (this.isMezzanine(floorValue)) {
            translationKey = 'buildinsDetail.mezzanine';
        }
        else if (this.isGroundFloor(floorValue)) {
            translationKey = 'buildinsDetail.groundfloor';
        }
        else if (this.isRooftop(floorValue)) {
            translationKey = 'buildinsDetail.rooftop';
        }
        if (translationKey != null) {
            return this.i18n.get(translationKey);
        }
        return floorValue;
    };
    SpaceDetailsComponent.prototype.isMezzanine = function (floor) {
        return this.isEqualsTo(floor, ['Mezzanine', 'Mezanino']);
    };
    SpaceDetailsComponent.prototype.isGroundFloor = function (floor) {
        return this.isEqualsTo(floor, ['Ground floor', 'Térreo', 'Terreo']);
    };
    SpaceDetailsComponent.prototype.isRooftop = function (floor) {
        return this.isEqualsTo(floor, ['Terraço', 'Rooftop']);
    };
    SpaceDetailsComponent.prototype.isEqualsTo = function (floor, customFloor) {
        return floor && customFloor.includes(floor);
    };
    return SpaceDetailsComponent;
}());
export { SpaceDetailsComponent };
