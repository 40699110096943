import * as tslib_1 from "tslib";
import { ListingTypePopoverComponent } from '@app/search-navigation/components/listing-type-popover';
import { PopoverController } from '@ionic/angular';
import { ListingProptypePopoverComponent } from '@app/search-navigation/components/listing-proptype-popover';
import { CoworkingPopoverComponent } from '@app/search-navigation/components/coworking-popover';
import { ListingLocationPopoverComponent } from '@app/search-navigation/components/listing-location-popover';
import { PropertyTypePopoverComponent } from '@app/search-navigation/components/property-type-popover';
import { RenewExtendModalComponent } from '@app/user-home/my-listing/renew-extend-modal/renew-extend-modal.component';
import { MoreFiltersSpotbuildingsComponent } from '@app/spot-buildings/components/search/more-filters/more-filters-spotbuildings.component';
import { SpaceDetailsComponent } from '../../spot-buildings/components/detail/space-details/space-details.component';
import { SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { RequestInformationComponent } from '../../spot-buildings/components/request-information/request-information.component';
import { imageUploadSpacesBuilding } from '@app/spot-buildings/components/create/spaces-buildings/image-upload-spaces-building/image-upload-spaces-building';
import { VideoComponent } from '../../shared/video/video.component';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { ImageGalleryComponent } from '@app/shared/image-gallery/image-gallery.component';
import { SocialMediaComponent } from '../../shared/social-media/social-media.component';
var PopoverCtrlHelper = /** @class */ (function () {
    function PopoverCtrlHelper(popoverCtrl, streamingMedia) {
        this.popoverCtrl = popoverCtrl;
        this.streamingMedia = streamingMedia;
    }
    PopoverCtrlHelper.prototype.createNewListingTypePopover = function (ev, searchModel) {
        var listingType = searchModel.listingType;
        var popover = this.popoverCtrl.create({
            component: ListingTypePopoverComponent,
            event: ev,
            cssClass: 'listing-type-popover',
            componentProps: { listType: listingType, searchDto: searchModel },
            backdropDismiss: true
        });
        return popover;
    };
    PopoverCtrlHelper.prototype.openNewListingTypePopover = function (ev, searchModel, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createNewListingTypePopover(ev, searchModel)];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // More Popover
    PopoverCtrlHelper.prototype.createMorePopover = function (ev, checkBoxValues, searchModel, listingSearchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.popoverCtrl.create({
                        component: ListingProptypePopoverComponent,
                        event: ev,
                        cssClass: 'popover-type-search',
                        componentProps: {
                            propType: searchModel.propertyType,
                            searchDto: listingSearchDto,
                            checkboxValues: checkBoxValues
                        },
                        backdropDismiss: true
                    })];
            });
        });
    };
    PopoverCtrlHelper.prototype.createMorePopoverBuildingListing = function (ev, checkBoxValues, searchModel, searchRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.popoverCtrl.create({
                        component: MoreFiltersSpotbuildingsComponent,
                        event: ev,
                        cssClass: 'popover-type-search-filters',
                        componentProps: {
                            propType: searchModel.propertyType,
                            searchDto: searchRequest,
                            checkboxValues: checkBoxValues
                        },
                        backdropDismiss: true
                    })];
            });
        });
    };
    PopoverCtrlHelper.prototype.openMorePopover = function (ev, checkBoxValues, searchModel, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createMorePopover(ev, checkBoxValues, searchModel, listingSearchDto)];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openMorePopoverForBuildingListing = function (ev, checkBoxValues, searchModel, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createMorePopoverBuildingListing(ev, checkBoxValues, searchModel, listingSearchDto)];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openCoworkingPopover = function (ev, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: CoworkingPopoverComponent,
                            event: ev,
                            cssClass: 'coworking-popover',
                            componentProps: { searchDto: listingSearchDto },
                            backdropDismiss: false
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                listingSearchDto = popoverData.data;
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openLocationPopover = function (ev, locationFilters, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: ListingLocationPopoverComponent,
                            event: ev,
                            cssClass: 'popover-location-search',
                            componentProps: locationFilters,
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData && popoverData.data && callback) {
                                callback(popoverData.data);
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openNewPropertyTypePopover = function (ev, listingSearchDto, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var propertyType, popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        propertyType = listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.length > 0
                            ? listingSearchDto.buildingTypes[0]
                            : null;
                        return [4 /*yield*/, this.popoverCtrl.create({
                                component: PropertyTypePopoverComponent,
                                event: ev,
                                cssClass: listingSearchDto.buildingTypes
                                    ? 'popover-property-search'
                                    : !propertyType
                                        ? 'property-type-popover'
                                        : 'popover-type-search',
                                componentProps: { propType: propertyType, searchDto: listingSearchDto },
                                backdropDismiss: true
                            })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                listingSearchDto = popoverData.data;
                                if (callback) {
                                    callback(listingSearchDto);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openRenewExtendListingsModal = function (ev, listingIds, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: RenewExtendModalComponent,
                            event: ev,
                            cssClass: 'popover-renew-extend',
                            componentProps: { listingIds: listingIds },
                            backdropDismiss: false
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                callback(popoverData.data);
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openSpacesDetailPopover = function (ev, space, spotBuilding, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: SpaceDetailsComponent,
                            event: ev,
                            cssClass: 'space-detail-popover',
                            componentProps: { space: space, spot: spotBuilding },
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                                if (popoverData.data.requestInfo) {
                                    var space_1 = popoverData.data.space;
                                    var spot = popoverData.data.spot;
                                    var spotTitle = popoverData.data.spotTitle;
                                    var requestType = popoverData.data.requestType;
                                    var agents = [];
                                    if (space_1.agentDTO) {
                                        agents.push(space_1.agentDTO);
                                        if (space_1.secondAgentDTO) {
                                            agents.push(space_1.secondAgentDTO);
                                        }
                                    }
                                    var requestInfoInput = {
                                        requestTitle: spotTitle,
                                        companyLogoImageId: spot.companyLogoImageId,
                                        agents: agents,
                                        spotId: spot.id,
                                        requestType: requestType
                                    };
                                    _this.openRequestInformationPopup(popoverData.data.event, requestInfoInput);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openRequestInformationPopup = function (ev, reqInfoInput, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: RequestInformationComponent,
                            event: ev,
                            cssClass: 'request-info-popover',
                            componentProps: { reqInfoInput: reqInfoInput },
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openGalleryImageComponent = function (ev, photos, spotTitle, listingType, description, topImage, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: ImageGalleryComponent,
                            event: ev,
                            cssClass: 'gallery-img-popover',
                            componentProps: {
                                photos: photos,
                                fromPopup: true,
                                spotTitle: spotTitle,
                                listingType: listingType,
                                description: description,
                                topImage: topImage
                            },
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openSocialMediaPopup = function (ev, socialMediaInput, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: SocialMediaComponent,
                            event: ev,
                            cssClass: 'social-media-popover',
                            componentProps: { socialMediaInput: socialMediaInput },
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openVideoTourPopup = function (ev, videoURL, spotTitle, isMobile, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: VideoComponent,
                            event: ev,
                            cssClass: 'video-player-popup',
                            componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
                            backdropDismiss: true
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                                if (callback) {
                                    callback(popoverData.data);
                                }
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopoverCtrlHelper.prototype.openImageUploadSpaceBuilding = function (spaceId, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverCtrl.create({
                            component: imageUploadSpacesBuilding,
                            cssClass: 'popover-type-Space',
                            componentProps: { spaceId: spaceId },
                            backdropDismiss: false
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            callback(true);
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return PopoverCtrlHelper;
}());
export { PopoverCtrlHelper };
