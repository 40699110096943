import * as tslib_1 from "tslib";
import { state } from '@angular/animations';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FavoriteSpotService } from '../../../../../../shared/services/favorite-spot.service';
var SpotMapMobileCardComponent = /** @class */ (function () {
    function SpotMapMobileCardComponent(spotBuildingsService, imageService, router, commonService, i18n, platformConfigHelper, favSpotService) {
        this.spotBuildingsService = spotBuildingsService;
        this.imageService = imageService;
        this.router = router;
        this.commonService = commonService;
        this.i18n = i18n;
        this.platformConfigHelper = platformConfigHelper;
        this.favSpotService = favSpotService;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    }
    SpotMapMobileCardComponent.prototype.ngOnInit = function () {
        this.faHeart = faHeart;
    };
    SpotMapMobileCardComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    SpotMapMobileCardComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    SpotMapMobileCardComponent.prototype.openCard = function (id, $event, externalLink) {
        if (externalLink === void 0) { externalLink = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopImmediatePropagation();
                        return [4 /*yield*/, this.spotBuildingsService.goToSpotDetailsURL(id).then(function (spotURL) {
                                _this.router.navigateByUrl(spotURL, { state: state });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotMapMobileCardComponent.prototype.getAvailableAreaLabel = function (spot) {
        return this.commonService.getAvailableAreaLabel(spot);
    };
    SpotMapMobileCardComponent.prototype.getAvailableAreaText = function (spot) {
        return this.commonService.getAvailableAreaText(spot.spaceRangesDTO, spot.type);
    };
    SpotMapMobileCardComponent.prototype.getAvgAskingRent = function (spot) {
        return this.commonService.getAvgAskingRent(spot.spaceRangesDTO, spot.type);
    };
    SpotMapMobileCardComponent.prototype.buildFavBtnClass = function (listing) {
        var favClass = this.getFavClass(listing);
        var customClasses = "spot-info-window__title__favorite-button " + favClass + " ";
        return customClasses;
    };
    SpotMapMobileCardComponent.prototype.getFavClass = function (listing) {
        return listing.favorite ? 'is-favorite' : 'no-favorite';
    };
    SpotMapMobileCardComponent.prototype.getCityState = function (listing) {
        return listing.id + " - " + this.i18n.getTranslation(listing.cityName) + " - " + this.i18n.getTranslation(listing.stateName) + ", Brasil";
    };
    SpotMapMobileCardComponent.prototype.getSpotImg = function (listing) {
        var photo = this.spot && this.spot.buildingListingPhotos ? this.spot.buildingListingPhotos[0] : null;
        var photoURL = photo && photo.image ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : this.defaultImage;
        return photoURL;
    };
    SpotMapMobileCardComponent.prototype.favoriteListener = function (event, spot) {
        var _this = this;
        event.stopImmediatePropagation();
        var favBtnElement = document.getElementById('favButton');
        this.favSpotService.setFavoriteSpot(event, spot).then(function (resp) {
            var currentFavClass = favBtnElement.className;
            currentFavClass = currentFavClass.replace('is-favorite', '');
            currentFavClass = currentFavClass.replace('no-favorite', '');
            favBtnElement.className = currentFavClass + " " + _this.getFavClass(spot);
        });
    };
    return SpotMapMobileCardComponent;
}());
export { SpotMapMobileCardComponent };
