import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/core/auth';
import { MatDialog } from '@angular/material';
import { ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { MyMessageService } from './my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
var UserHomeComponent = /** @class */ (function () {
    function UserHomeComponent(accountService, commonService, imageService, router, dialog, myMessageService, socketIO, userActivityService) {
        this.accountService = accountService;
        this.commonService = commonService;
        this.imageService = imageService;
        this.router = router;
        this.dialog = dialog;
        this.myMessageService = myMessageService;
        this.socketIO = socketIO;
        this.userActivityService = userActivityService;
        this.imagePlaceholder = 'assets/images/default-profile-photo.png';
        this.userAccountInfo = {};
        this.breadcrumbOptions = {
            navigateHome: true,
            usePlatformLocation: true
        };
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    }
    UserHomeComponent.prototype.getUserProfile = function (userAccount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userPhotos, image;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountService.getUserPhotoList().toPromise()];
                    case 1:
                        userPhotos = _a.sent();
                        image = this.accountService.getUploadedImageId(userPhotos);
                        this.userAccountInfo = {
                            profile: userAccount,
                            profileImage: this.getMediumImg(image.profileImageId),
                            originalImage: this.getMediumImg(image.originalImageId)
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    UserHomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_USER_HOME_PAGE);
        this.userAccountSubscription = this.accountService.currentUser$.subscribe(function (userAccount) {
            _this.getUserProfile(userAccount);
        });
    };
    UserHomeComponent.prototype.openListingMenu = function (data) {
        this.dialogRef = this.dialog.open(MyListingMenuComponent, {
            height: 'auto',
            width: '550px',
            panelClass: 'padding-unset'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    UserHomeComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    UserHomeComponent.prototype.ngOnDestroy = function () {
        if (this.userAccountSubscription) {
            this.userAccountSubscription.unsubscribe();
        }
    };
    UserHomeComponent.prototype.goToUserPage = function (pageRoute) {
        this.router.navigateByUrl("" + pageRoute);
    };
    UserHomeComponent.prototype.getUnreadMessages = function () {
        //TODO: Replace with something from chat module
        return this.myMessageService.getUnreadMessages();
    };
    UserHomeComponent.prototype.navigateToPurchase = function () {
        this.router.navigateByUrl('/list-property');
    };
    UserHomeComponent.prototype.navigateToRegister = function () {
        this.router.navigateByUrl('/user-home/current-plans');
    };
    UserHomeComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    return UserHomeComponent;
}());
export { UserHomeComponent };
