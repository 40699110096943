import * as tslib_1 from "tslib";
import { OnInit, AfterViewChecked, QueryList } from '@angular/core';
import { ImageService, I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
var OurPartnersComponent = /** @class */ (function () {
    function OurPartnersComponent(imageService, _ts, userActivityService, i18NService) {
        this.imageService = imageService;
        this._ts = _ts;
        this.userActivityService = userActivityService;
        this.i18NService = i18NService;
        this.showCardBody = [];
        this.currentPhotoIndex = [];
        this.advertisements = [];
    }
    OurPartnersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        setTimeout(function () {
            _this.initValues();
        }, 2000);
    };
    OurPartnersComponent.prototype.ngAfterViewChecked = function () { };
    OurPartnersComponent.prototype.showDetails = function (index) {
        this.showCardBody[index] = !this.showCardBody[index];
    };
    OurPartnersComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    OurPartnersComponent.prototype.getPreviousPhoto = function (event, currentAd, index) {
        event.stopPropagation();
        if (this.currentPhotoIndex[index] === 0) {
            this.photoSlider.toArray()[index].slideTo(currentAd.photos.length - 1);
        }
        else {
            this.photoSlider.toArray()[index].slidePrev();
        }
        this.updatePhotoIndex(index);
    };
    OurPartnersComponent.prototype.getNextPhoto = function (event, currentAd, index) {
        event.stopPropagation();
        if (this.currentPhotoIndex[index] === currentAd.photos.length - 1) {
            this.photoSlider.toArray()[index].slideTo(0);
        }
        else {
            this.photoSlider.toArray()[index].slideNext();
        }
        this.updatePhotoIndex(index);
    };
    OurPartnersComponent.prototype.openWebsite = function (advertisement) {
        var website = advertisement.website;
        window.open(website, '_system');
        this.registerUserActivity(advertisement.id, UserActivityType.SERVICE_PROVIDER_DETAIL);
    };
    OurPartnersComponent.prototype.registerUserActivity = function (adCompanyId, module) {
        var userActivity = {
            module: module,
            brokerId: null,
            listingId: this.listingId,
            adCompanyId: adCompanyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    OurPartnersComponent.prototype.initValues = function () {
        var e_1, _a;
        if (this.advertisements) {
            try {
                for (var _b = tslib_1.__values(this.advertisements), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var ad = _c.value;
                    var i = this.advertisements.indexOf(ad);
                    this.showCardBody[i] = true;
                    this.currentPhotoIndex[i] = 0;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    OurPartnersComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18NService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    OurPartnersComponent.prototype.updatePhotoIndex = function (index) {
        var _this = this;
        this.photoSlider
            .toArray()[index].getActiveIndex()
            .then(function (ind) {
            _this.currentPhotoIndex[index] = ind;
        });
    };
    return OurPartnersComponent;
}());
export { OurPartnersComponent };
